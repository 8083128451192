import React from "react";
import { Link } from "react-router-dom";
import { getStatusRowStyle, isLink, isStatus } from "../../../../ui/helpers";

export function renderTagsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/crop-tags/${item.id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}
