export const newTestActions = {
  SET_DATA: 'NEW_TEST/SET_DATA',
  POPULATE_DATA: 'NEW_TEST/POPULATE_DATA',
  RESET: "NEW_TEST/RESET_DATA"
}

export function setData(data) {
  return {
    type: newTestActions.SET_DATA,
    payload: data
  }
}

export function populateData(data) {
  return {
    type: newTestActions.POPULATE_DATA,
    payload: data
  }
}

export const resetState = () => {
  return {
    type: newTestActions.RESET
  };
};
