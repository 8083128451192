import React, { useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { certificationTypes, statusOptions } from "./constants";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { useFetch } from "../../../hooks/fetch.hook";
import { addSite, modifySite } from "./_api";
import { SiteCardSchema } from "../../../helpers/schemas";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { stateOptions } from "../../modules/Organisations/constants";

export const SiteCard = ({ data = {}, id, handleCancel, organisation_id }) => {
  const { request } = useFetch();
  const history = useHistory();

  const { certifications } = data;

  const isLinkedSite = history.location.pathname === `/organisations-list/${organisation_id}`;

  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: data.name || "",
    phone: data.phone || "",
    site_email: data.site_email || "",
    status: data.status || "",
    invoicing_email: data.invoicing_email || "",
    notes: data.notes || "",
    physical_address: data.physical_address || "",
    physical_address2: data.physical_address2 || "",
    physical_city: data.physical_city || "",
    physical_state: data.physical_state || "",
    physical_postcode: data.physical_postcode || "",
    certification_type_1: (certifications && certifications[0].split(" ")[0]) || "",
    certification_type_2: (certifications && certifications[1].split(" ")[0]) || "",
    certification_type_3: (certifications && certifications[2].split(" ")[0]) || "",
    certification_number_1: (certifications && certifications[0].split(" ")[1]) || "",
    certification_number_2: (certifications && certifications[1].split(" ")[1]) || "",
    certification_number_3: (certifications && certifications[2].split(" ")[1]) || "",
  };

  const modifyCertificate = (type, value) => {
    if (type && value) {
      return [type, value].join(" ");
    } else return type;
  };

  const createSite = (values, setSubmitting) => {
    request(
      addSite,
      {
        ...values,
        certifications: [
          modifyCertificate(values.certification_type_1, values.certification_number_1),
          modifyCertificate(values.certification_type_2, values.certification_number_2),
          modifyCertificate(values.certification_type_3, values.certification_number_3),
        ],
        organisation_id,
      },
      organisation_id,
      id
    )
      .then((data) => {
        if (!data) return;
        isLinkedSite ? handleCancel() : history.push("/sites");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const updateSite = (values, setSubmitting) => {
    request(
      id ? modifySite : addSite,
      {
        ...values,
        certifications: [
          modifyCertificate(values.certification_type_1, values.certification_number_1),
          modifyCertificate(values.certification_type_2, values.certification_number_2),
          modifyCertificate(values.certification_type_3, values.certification_number_3),
        ],
        organisation_id,
      },
      organisation_id,
      id
    )
      .then((data) => data && info("Details have been updated!"))
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    id ? updateSite(values, setSubmitting) : createSite(values, setSubmitting);
  };

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && <Loader title="Loading..." isOpen={loading} />}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={SiteCardSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-5">
              <strong>{data.name}</strong>
            </h3>
            <div className="row">
              <div className="col-8">
                <MappedInput label="Site Name" name="name" />
              </div>
              <div className="col-4">
                <MappedSelect label="Status" name="status" options={statusOptions} />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <MappedInput label="Site Email" name="site_email" />
              </div>
              <div className="col-6">
                <MappedInput label="Invoice Email" name="invoicing_email" />
              </div>
            </div>

            <MappedInput label="Site Phone" name="phone" />
            <MappedInput label="Site Notes" name="notes" multiline rows={6} />

            <div className="row justify-content-between mt-10">
              <div className="col-6">
                <h5 className="mb-2">
                  <strong>Physical Address</strong>
                </h5>
                <MappedInput label="Address 1" name="physical_address" />
                <MappedInput label="Address 2" name="physical_address2" />
                <MappedInput label="City" name="physical_city" />
                <div className="row">
                  <div className="col-6">
                    <MappedSelect
                      label="State"
                      name="physical_state"
                      data-testid="physical_state"
                      options={stateOptions}
                    />
                  </div>
                  <div className="col-6">
                    <MappedInput label="Postal Code" name="physical_postcode" />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <h5 className="mb-2">
                  <strong>Certification Details</strong>
                </h5>
                <div className="row">
                  <div className="col-6">
                    <MappedSelect
                      label="Certification 1 Type"
                      name="certification_type_1"
                      options={certificationTypes}
                    />
                  </div>
                  <div className="col-6">
                    <MappedInput label="Certification 1 Number" name="certification_number_1" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <MappedSelect
                      label="Certification 2 Type"
                      name="certification_type_2"
                      options={certificationTypes}
                    />
                  </div>
                  <div className="col-6">
                    <MappedInput label="Certification 2 Number" name="certification_number_2" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <MappedSelect
                      label="Certification 3 Type"
                      name="certification_type_3"
                      options={certificationTypes}
                    />
                  </div>
                  <div className="col-6">
                    <MappedInput label="Certification 3 Number" name="certification_number_3" />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10">
              <ButtonGroup
                handleClose={handleCancel}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
