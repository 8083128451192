export const laboratoryTypes = {
  SET_DATA: "LABORATORIES/SET_DATA",
  REMOVE_LABORATORY: "LABORATORIES/REMOVE_LABORATORY",
  UNARCHIVE_DATA: "LABORATORIES/UNARCHIVE_DATA",
};

export function setLaboratoriesList(data) {
  return {
    type: laboratoryTypes.SET_DATA,
    payload: data,
  };
}

export function removeLaboratory(data) {
  return {
    type: laboratoryTypes.REMOVE_LABORATORY,
    payload: data,
  };
}

export function unarchiveLaboratory(data) {
  return {
    type: laboratoryTypes.UNARCHIVE_DATA,
    payload: data,
  };
}
