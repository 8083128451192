import { siteTypes } from "./actions";

const initialState = {
  sitesList: [],
};

export function sitesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case siteTypes.SET_DATA:
      return {
        ...state,
        sitesList: payload,
      };

    case siteTypes.REMOVE_SITE:
      return {
        ...state,
        sitesList: [...state.sitesList.filter(({ id }) => id !== payload)],
      };

    case siteTypes.UPDATE_DATA:
      const index = state.sitesList.findIndex(
        (item) => (item || {}).id === (payload || {}).id
      );
      return {
        ...state,
        sitesList: [
          ...state.sitesList.slice(0, index),
          payload,
          ...state.sitesList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
