import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { organisationsReducer } from "../app/modules/Organisations/_redux/reducer";
import { testsReducer } from "../app/modules/Tests/_redux/reducer";
import { tenantsReducer } from "../app/modules/Tenants/_redux/reducer";
import { usersReducer } from "../app/modules/Users/_redux/reducer";
import { laboratoriesReducer } from "../app/modules/Settings/Laboratories/_redux/reducer";
import { tagsReducer } from "../app/modules/Settings/Tags/_redux/reducer";
import { analytesReducer } from "../app/modules/Settings/Analytes/_redux/reducer";
import { settingsTestsReducer } from "../app/modules/Settings/Tests/_redux/reducer";
import { cropTagsReducer } from "../app/modules/Crops/Tags/_redux/reducer";
import { cropsReducer } from "../app/modules/Crops/CropsManagement/_redux/reducer";
import { resultsReducer } from "../app/modules/Results/_redux/reducer";
import { addNewTestReducer } from "../app/modules/AddNewTest/_redux/reducer";
import { samplesReducer } from "../app/modules/Samples/_redux/reducer";
import { contactsReducer } from "../app/modules/Contacts/_redux/reducer";
import { invoiceReconciliationReducer } from "../app/modules/InvoiceReconciliation/_redux/reducer";
import { pricesReducer } from "../app/modules/Settings/Prices/_redux/reducer";
import { invoicesReducer } from "../app/modules/TestInvoices/_redux/redcuer";
import { sitesReducer } from "../app/modules/Sites/_redux/reducer";
import { PlatformResultsReviewReducer } from "../app/modules/PlatformResultsReview/_redux/reducer";
import { resultsReviewReducer } from "../app/modules/ResultsReview//_redux/reducer"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  organisations: organisationsReducer,
  tests: testsReducer,
  tenants: tenantsReducer,
  users: usersReducer,
  laboratories: laboratoriesReducer,
  tags: tagsReducer,
  analytes: analytesReducer,
  settingsTests: settingsTestsReducer,
  cropTags: cropTagsReducer,
  crops: cropsReducer,
  results: resultsReducer,
  newTest: addNewTestReducer,
  samples: samplesReducer,
  contacts: contactsReducer,
  invoiceReconciliation: invoiceReconciliationReducer,
  prices: pricesReducer,
  testInvoices: invoicesReducer,
  sites: sitesReducer,
  resultsReview: resultsReviewReducer,
  platformResultsReview: PlatformResultsReviewReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
