import { get } from "lodash";
import { useSelector } from "react-redux";
import { AVAILABLE_ROLES } from "../../../app/constants";
import { getUserRole } from "../../helpers";

export const AccessControl = ({
  desiredRoll,
  children,
  elseContent,
  tenantId,
}) => {
  const user = useSelector(({ auth: { user } }) => user) || {};
  const tenants = get(user, "permissions.tenant", {});

  const isNationalAdmin = get(user, "permissions.is_national_admin");
  const isTenantAdmin = getUserRole("is_tenant_admin", tenants);
  const isEndUser = get(user, "permissions.is_end_user");
  const isTenantUser = get(user, "permissions.is_tenant_user") !== false;
  const isGuest = get(user, "permissions.is_guest");
  const isSpecificTenantAdmin =
    tenantId && (tenants[tenantId] || {}).is_tenant_admin;

  if (isNationalAdmin && desiredRoll === AVAILABLE_ROLES.NATIONAL_ADMIN) {
    return children;
  }
  if (
    !isNationalAdmin &&
    isTenantAdmin &&
    desiredRoll === AVAILABLE_ROLES.TENANT_ADMIN
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    isSpecificTenantAdmin &&
    desiredRoll === AVAILABLE_ROLES.TENANT_ADMIN
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isTenantAdmin &&
    isTenantUser &&
    desiredRoll === AVAILABLE_ROLES.TENANT_USER
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isTenantAdmin &&
    isEndUser &&
    desiredRoll === AVAILABLE_ROLES.END_USER
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isTenantAdmin &&
    !isEndUser &&
    isGuest &&
    desiredRoll === AVAILABLE_ROLES.TENANT_GUEST
  ) {
    return children;
  }

  return elseContent ? elseContent : null;
};
