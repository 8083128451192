export function isLink(key) {
  return ["availability", "cost"].includes(key);
}

export const modifyLaboratories = (data, tenants) =>
  data.map((laboratory) => modifyLaboratoryData(laboratory, tenants));

export const modifyLaboratoryData = (laboratory, tenants = []) => {
  return {
    ...laboratory,
    availability: [
      ...new Set(
        laboratory["laboratory_prices"].map(
          ({ tenant_id }) =>
            tenants.find((o) => o.id === tenant_id)["name"] || ""
        )
      ),
    ].join(", "),
  };
};

export const findLatestImportDate = (data) => {
  const latesImportTime = data
    .map((laboratory) =>
      laboratory["laboratory_prices"].map(({ created_at }) => {
        return created_at;
      })
    )
    .flat();
  return latesImportTime.length
    ? latesImportTime.reduce(function(pre, cur) {
        return Date.parse(pre) > Date.parse(cur) ? cur : pre;
      })
    : "";
};
