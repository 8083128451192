const type = "crop-tags";

export const setTagsList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      tagsList: payload,
    };
  },
  type,
});

export const removeTag = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      tagsList: state.tagsList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const updateTagsList = (payload) => ({
  execute: (state) => {
    const index = state.tagsList.findIndex((item) => item.id === payload.id);
    const myState = [...state.tagsList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      tagsList: [...myState],
    };
  },
  type,
});
