export const HEADINGS = [
  ["code", "Crop ID"],
  ["name", "Common Name"],
  ["botanical_name", "Botanical Name"],
  ["status", "Status"],
];

export const searchMap = {
  "Crop ID": "code",
  "Common Name": "name",
  "Botanical Name": "botanical_name",
};
