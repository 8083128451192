import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  CheckCircle as CheckCircleIcon,
  MoreHoriz as MoreHorizIcon,
} from "@material-ui/icons";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { CircularProgress } from "@material-ui/core";

import { downloadFile } from "../modules/AWS-S3";
import { useFetch } from "../../hooks/fetch.hook";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { ListButton } from "../../ui/components/ListButton";
import { Modal } from "../../ui/components/Modal";
import { TRDetails } from "../../ui/structures/TRDetails";

import { getSubmissionReceipt } from "../modules/AddNewTest/_api";
import { getTestRequests } from "../modules/Tests/_api";
import { renderTestsRow, modifyTestsArray } from "../modules/Tests/helpers";
import { HEADINGS } from "../modules/Tests/constants";

export const StripeSuccessPage = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const invoice_id = params.get("invoice_id");
  const handleJumpToInvoices = () => history.push("/invoices/test-invoices");
  if (!invoice_id) handleJumpToInvoices();
  const user = useSelector(({ auth: { user } }) => user) || {};
  const tenants = user.tenants || [];

  const { request } = useFetch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [data, setData] = useState([]);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [reviewedComponentID, setReviewedComponentID] = useState("");

  useEffect(() => {
    setLoading(true);
    request(getTestRequests, [], 0, 100, `invoice_id=${invoice_id}`)
      .then((data) => data && setData(modifyTestsArray(data, tenants)))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_id]);

  const handleDownload = (id) => {
    setLoadingFile(true);
    downloadFile("", getSubmissionReceipt, setLoadingFile, null, invoice_id);
  };

  const handleDetailsModalClose = () => setDetailsModalOpen(false);

  const handleExpand = (id) => () => {
    setDetailsModalOpen(true);
    setReviewedComponentID(id);
  };
  return (
    <div className="bg-white rounded px-10 pb-20">
      {detailsModalOpen && (
        <Modal
          maxWidth="lg"
          isOpen={detailsModalOpen}
          onClose={handleDetailsModalClose}
          modalContent={
            <TRDetails
              data={data.find(({ id }) => id === reviewedComponentID)}
              tenants={tenants}
            />
          }
        />
      )}
      <div className="row justify-content-between align-items-center pt-10">
        <h3>
          <CheckCircleIcon
            style={{ color: "#71BF44", margin: "0 20px 0 10px" }}
            fontSize="large"
          />
          Successful payment!
        </h3>

        <div className="containter h-100">
          <div className="p-5 bg-white">
            <span>
              <img
                className="img-fluid"
                src={toAbsoluteUrl("/assets/icons/Logo.svg")}
                alt="Logo"
              />
            </span>
          </div>
        </div>
      </div>

      <hr />

      <Datagrid
        data={data}
        headings={HEADINGS}
        renderRow={renderTestsRow}
        loading={loading}
        expandable
        handleExpand={handleExpand}
        expandHeader="Details"
        expandIcon={<MoreHorizIcon />}
      />
      <div className="d-flex mt-5 justify-content-between text-center">
        <ListButton
          label="Back to invoices"
          variant="outlined"
          onClick={handleJumpToInvoices}
          size="large"
          text="#71BF44"
        />
        {invoice_id && (
          <ListButton
            label={
              loadingFile ? (
                <div className="d-flex">
                  <div className="mr-8">
                    <CircularProgress size="1.2rem" />
                  </div>{" "}
                  Downloading PDF...
                </div>
              ) : (
                "Download Submission Receipt"
              )
            }
            onClick={handleDownload}
            disabled={loadingFile}
            size="large"
          />
        )}
      </div>
    </div>
  );
};
