export const HEADINGS = [
  ["code", "Invoice Number"],
  ["organisation_name", "Bill to Org"],
  ["amount", "Amount"],
  ["test_code", "Test Code"],
  ["invoice_date", "Date"],
  ["payment_status", "Status"],
  ["download_pdf", "Download"],
];

export const filterDataHelper = {
  "Invoice Number": "code",
  "Bill to Org": "organisation_id",
};

export const SEARCH_HELPER = [
  ["code", "Invoice Number"],
  ["organisation_name", "Bill to Org"],
];

export const searchMap = {
  "Invoice Number": "code",
  "Bill to Org": "organisation_name",
};
