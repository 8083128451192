import config from "react-global-configuration";
import { modifyAbnSearchValue } from "../helpers";

export const addOrganisation = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getOrganisations = (
  count_only = "",
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) => {
  return fetch(
    `${config.get("ApiUrl").Rest}/organisations?${count_only && "count_only=y"}${search &&
      "&" + modifyAbnSearchValue(search)}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}&laboratory=false`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getDashboardClients = () => {
  return fetch(`${config.get("ApiUrl").Rest}/organisations?count_only=y&laboratory=false`, {
    mode: "cors",
    credentials: "include",
  });
};

export const getTenantDashBoardClients = (tenant_id) => {
  return fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations?count_only=y&laboratory=false`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getTestsReports = (query) =>
  fetch(
    `${config.get("ApiUrl").Rest}/test_requests/reports?${query ? `report_type=${query}` : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTenantTestsReports = (query, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/test_requests/reports?${
      query ? `report_type=${query}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getSimpleOrganisations = (query = "") =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/simple?${query || ""}`, {
    mode: "cors",
    credentials: "include",
  });

export const getOrganisationById = (organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyOrganisation = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archiveOrganisation = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const mergeOrganisation = (id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getOrganisationContacts = (organisation_id, archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${organisation_id}/contacts?${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationSites = (organisation_id, archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${organisation_id}/sites?${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
export const getOrganisationUsers = (organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/users`, {
    mode: "cors",
    credentials: "include",
  });

export const getOrganisationTests = (organisation_id, page = 0, page_size = 10) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/test_requests?&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTenantOrganisations = (
  tenant_id,
  count_only = "",
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations?${count_only &&
      "count_only=y"}${search && "&" + modifyAbnSearchValue(search)}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction && "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const linkUsers = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/users`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const unlinkUsers = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/users`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const abnLookup = (abn) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/abn-lookup?search_only=true&abn=${abn}`, {
    mode: "cors",
    credentials: "include",
  });

export const getOrganisationsListAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived,
  tenant_id
) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/organisations?&page_size=${page_size}${(archived && "&status=all") ||
      ""}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        modifyAbnSearchValue(autocompleteText) +
        ")_") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction && "&order_dir=" + direction) || ""}${
      search ? "&" + modifyAbnSearchValue(search) : ""
    }&laboratory=false`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const lintContactsToOrganisations = (org_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${org_id}/contacts`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const linkOrganisationToTenants = (org_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${org_id}/tenants`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getOrganisationsAmount = (tenant_id, search = "", archived = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/organisations?count_only=y&laboratory=false${
      search ? "&" + modifyAbnSearchValue(search) : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getOrganisationTestsAmount = (organisation_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${organisation_id}/test_requests?count_only=y`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationPaymentMethods = (organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/payment-method`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyOrganisationPaymentMethods = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/payment-method`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const addOrganisationPaymentMethod = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/payment-method`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editOrganisationTags = (organisation_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteOrganisationTags = (organisation_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/tags`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getOrganisationTags = (organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/tags`, {
    mode: "cors",
    credentials: "include",
  });
