import React, { useState, useEffect, useMemo } from "react";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { Button, LinearProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { getLaboratoryTestsPrices } from "../_api";
import { getPriceLists } from "../../Prices/_api";
import { LaboratoryTestPrices } from "./LaboratoryTestPrice";

export const LaboratoryTestPricesModal = ({
  test_data = {},
  laboratory_id,
  updateTestsList,
  tenants,
}) => {
  const { request } = useFetch();

  const [pricesLists, setPricesList] = useState([]);
  const [priceListLoading, setPriceListLoading] = useState(false);
  const [priceListFirstLoading, setPriceListFirstLoading] = useState(true);
  const [usedStateList, setUsedStateList] = useState([]);
  const [priceLists, setPriceLists] = useState([
    { id: 0, name: "Select Price List" },
  ]);

  useEffect(() => {
    fetchPrices();
    fetchPriceLists();
    // eslint-disable-next-line
  }, []);

  const fetchPrices = () => {
    setPriceListLoading(true);
    request(getLaboratoryTestsPrices, laboratory_id, test_data.id).then(
      (data) => {
        if (!data) return;
        setPricesList(data);
        setPriceListLoading(false);
        setUsedStateList(data.map(({ tenant_id }) => String(tenant_id)));
        setPriceListFirstLoading(false);
      }
    );
  };

  const fetchPriceLists = () => {
    setPriceListLoading(true);

    request(getPriceLists).then((data) => {
      if (!data) return;
      setPriceLists([...data]);
      setPriceListLoading(false);
    });
  };

  const stateOptions = useMemo(
    () =>
      tenants.map(({ id, name }) => ({
        value: String(id),
        label: name,
      })),
    [tenants]
  );

  let priceCodeOptions = useMemo(
    () =>
      priceLists.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [priceLists]
  );

  const handleAddNewItem = () => {
    setPricesList((prevData) => [...prevData, []]);
  };

  return (
    <>
      <h4 className="mb-10 text-center">
        <strong>Laboratory Price List</strong>
      </h4>
      <div className="justify-content mt-15 mb-5 mx-15">
        {priceListFirstLoading ? (
          <LinearProgress />
        ) : pricesLists.length ? (
          pricesLists.map((item, id) => (
            <LaboratoryTestPrices
              data={item}
              key={id}
              usedStateList={usedStateList}
              stateOptions={stateOptions}
              priceCodeOptions={priceCodeOptions}
              laboratory_id={laboratory_id}
              test_id={test_data.id}
              fetchPrices={fetchPrices}
              updateTestsList={updateTestsList}
              priceListLoading={priceListLoading}
              setPriceListLoading={setPriceListLoading}
            />
          ))
        ) : (
          <h4 className="w-100 mb-10 text-center">
            No laboratory price is available {test_data.laboratoryTestPrice_id}
          </h4>
        )}
      </div>

      <Button
        color="secondary"
        size="large"
        className="px-9 mr-auto w-100"
        tabIndex="-1"
        startIcon={<AddIcon />}
        onClick={handleAddNewItem}
      >
        Add New Item
      </Button>
    </>
  );
};
