export const HEADINGS = [
  ["code", "Price Code"],
  ["test_code", "Test Code"],
  ["test_name", "Test Name"],
  ["start_date", "Start Date"],
  ["end_date", "End Date"],
  ["states", "Applies to"],
  ["sales_price", "Sell Price (ex GST)"],
];

export const searchMap = {
  "Price Code": "code",
  "Test Code": "test.code",
  "Test Name": "test.name",
};
