import React from "react";
import { Link } from "react-router-dom";
import { isLink } from "../../../ui/helpers";

export function renderContactsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {isLink(key) ? (
        <Link
          className={item.status === "archived" ? "text-danger" : "text-dark"}
          to={`/contacts/${item.id}`}
        >
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}
