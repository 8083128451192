import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedMaskedInput } from "@bit/the-glue.frontendcomponents.masked-input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { OrganisationDetailsSchema } from "../../../../helpers/schemas";
import { stateOptions, statusOptions } from "../../Organisations/constants";
import { Modal } from "../../../../ui/components/Modal";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  getSimpleOrganisations,
  modifyOrganisation,
  abnLookup,
} from "../../Organisations/_api";
import { PostalAddress } from "../../Organisations/PostalAddress";
import { AccessControl } from "../../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../../constants";
import { Loader } from "../../../../ui/components/Loader";
import { ListButton } from "../../../../ui/components/ListButton";
import { TradingNameSelect } from "../../Organisations/TradingNameSelect";
import { uniq } from "lodash";
import { AbnOfflineWarning } from "../../Organisations/AbnOfflineWarning";
import { info } from "../../../../helpers/toasts";
import { addLaboratory } from "./_api";

export const LaboratoryDetails = ({ data = {}, id, name }) => {
  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const history = useHistory();
  const { request } = useFetch();

  const initialValues = {
    ABN: data.ABN || "",
    legal_name: data.legal_name || "",
    phone: data.phone || "",
    mobile: data.mobile || "",
    email: data.email || "",
    trading_name: data.trading_name || "",
    price_list_id: data.price_list_id || "",
    status: data.status || "new",
    notes: data.notes || "",
    physical_address: data.physical_address || "",
    physical_address2: data.physical_address2 || "",
    physical_city: data.physical_city || "",
    physical_state: data.physical_state || "",
    physical_postcode: data.physical_postcode || "",
    postal_address: data.postal_address || "",
    postal_address2: data.postal_address2 || "",
    postal_city: data.postal_city || "",
    postal_state: data.postal_state || "",
    postal_postcode: data.postal_postcode || "",
    code: data.code || "",
    as_physical: false,
    laboratory: true,
    laboratory_api_key_id: data.laboratory_api_key_id || null,
  };

  const [abnLookedUp, setAbnLookedUp] = useState(id ? true : false);
  const [organisationsList, setOrganisationsList] = useState([]);
  const [tradingNameSelect, setTradingNameSelect] = useState(false);
  const [tradingNamesList, setTradingNamesList] = useState([]);
  const [tradingNameValue, setTradingNameValue] = useState("");
  const [tradingNameisInput, setTradingNameisInput] = useState(true);
  const [abnWarningOpen, setAbnWarningOpen] = useState(false);
  const [abnLoading, setAbnloading] = useState(false);
  const [tradingNameLoading, setTradingNameLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNameSelectClose = () => setTradingNameSelect(false);
  const handleAbnWarningClose = () => setAbnWarningOpen(false);

  useEffect(() => {
    request(getSimpleOrganisations).then(
      (data) => data && setOrganisationsList(data)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data.ABN) {
      setTradingNameLoading(true);
      setTradingNameisInput(false);
      request(abnLookup, data.ABN)
        .then((data) => {
          if (!data) return;
          const filteredNames = uniq(data.trading_names);
          const tradingNamesOptions = [
            ...filteredNames.map((name) => ({
              value: name,
              label: name,
            })),
            { value: data.legal_name, label: data.legal_name },
          ];
          setTradingNamesList(tradingNamesOptions);
        })
        .finally(() => setTradingNameLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  const abnList = organisationsList.map(({ ABN }) => ABN);

  const findDuplicate = (value) => {
    return abnList.find((abn) => abn === value && abn !== data.ABN);
  };

  const createOrganisation = (values, setSubmitting) => {
    setLoading(true);
    request(addLaboratory, values)
      .then((data) => data && history.push("/settings/laboratories"))
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const updateOrganisation = (values, setSubmitting) => {
    setLoading(true);
    request(modifyOrganisation, values, id)
      .then((data) => data && info("Details have been updated!"))
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (!values.laboratory_api_key_id) values.laboratory_api_key_id = null;
    const tenantAdminPayload = { ...values, tenant_id: admins_tenant };

    id
      ? updateOrganisation(
          admins_tenant ? tenantAdminPayload : values,
          setSubmitting
        )
      : createOrganisation(
          admins_tenant ? tenantAdminPayload : values,
          setSubmitting
        );
  };

  const handleBlur = (values, setTouched, setFieldValue) => () => {
    if (!findDuplicate(values.ABN) && !values.ABN.includes("_")) {
      setAbnloading(true);
      request(abnLookup, values.ABN)
        .then((data) => {
          if (!data) {
            setAbnLookedUp(false);
          } else if (data === "Server error") {
            setTradingNameisInput(true);
            setAbnWarningOpen(true);
            setAbnLookedUp(true);
          } else {
            setTradingNameisInput(false);
            setAbnLookedUp(true);
            Object.keys(data).map((key) => setFieldValue(key, data[key]));
            const filteredNames = uniq(data.trading_names);
            const tradingNamesOptions = [
              ...filteredNames.map((name) => ({
                value: name,
                label: name,
              })),
              { value: data.legal_name, label: data.legal_name },
            ];
            setTradingNamesList(tradingNamesOptions);
            if (tradingNamesOptions.length === 1) {
              setFieldValue("trading_name", tradingNamesOptions[0].value);
            } else {
              setFieldValue("trading_name", "");
              setTradingNameSelect(true);
            }
          }
        })
        .finally(() => setAbnloading(false));
    } else {
      setAbnLookedUp(false);
    }
    setTouched({ ABN: true }, true);
  };

  const hanldeSelectTradingName = (setFieldValue) => () => {
    setFieldValue("trading_name", (tradingNameValue || {}).value);
    setTradingNameSelect(false);
  };

  const handleClose = () => history.goBack();

  return (
    <>
      {(abnLoading || loading) && (
        <Loader
          isOpen={abnLoading || loading}
          maxWidth="xs"
          title="Loading..."
        />
      )}
      {abnWarningOpen && (
        <Modal
          maxWidth="sm"
          isOpen={abnWarningOpen}
          submitable
          onClose={handleAbnWarningClose}
          modalContent={
            <AbnOfflineWarning onClose={handleAbnWarningClose} id={id} />
          }
        />
      )}
      <div className="border border-secondary bg-white p-10">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={OrganisationDetailsSchema}
        >
          {({
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setTouched,
            values,
          }) => (
            <>
              {tradingNameSelect && (
                <Modal
                  maxWidth="sm"
                  isOpen={tradingNameSelect}
                  submitable
                  onClose={handleNameSelectClose}
                  modalContent={
                    <TradingNameSelect
                      onClose={handleNameSelectClose}
                      handleSubmit={hanldeSelectTradingName(setFieldValue)}
                      setTradingNameValue={setTradingNameValue}
                      tradingNamesList={tradingNamesList}
                    />
                  }
                />
              )}
              <h3 className="mb-5 d-flex justify-content-between">
                <div>
                  <strong>{name || "Create Laboratory"}</strong>
                </div>
                <div>{id && `ABN Is ${data.abn_verified}`}</div>
              </h3>
              <div className="row justify-content-between">
                <div className="col-6">
                  <MappedMaskedInput
                    label="ABN"
                    name="ABN"
                    data-testid="abn"
                    mask="99 999 999 999"
                    onBlur={handleBlur(values, setTouched, setFieldValue)}
                  />
                  <MappedInput
                    label="Legal Name"
                    name="legal_name"
                    data-testid="legal_name"
                    disabled={!abnLookedUp}
                  />

                  <MappedInput
                    label="Email"
                    name="email"
                    data-testid="email"
                    disabled={!abnLookedUp}
                  />
                  {tradingNameLoading ? (
                    <div className="mb-3">
                      <Skeleton variant="rect" width={"100%"} height={50} />
                    </div>
                  ) : tradingNameisInput ? (
                    <MappedInput
                      label="Business Name"
                      name="trading_name"
                      data-testid="trading_name"
                      disabled={!abnLookedUp}
                    />
                  ) : (
                    <MappedSelect
                      label="Business Name"
                      options={tradingNamesList}
                      name="trading_name"
                      data-testid="trading_name"
                      disabled={!abnLookedUp}
                    />
                  )}

                  {id && (
                    <MappedInput
                      label="Code"
                      name="code"
                      data-testid="code"
                      disabled
                    />
                  )}
                </div>
                <div className="col-6">
                  <MappedInput
                    label="Phone"
                    name="phone"
                    data-testid="phone"
                    disabled={!abnLookedUp}
                  />
                  <MappedInput
                    label="Mobile"
                    name="mobile"
                    data-testid="mobile"
                    disabled={!abnLookedUp}
                  />
                  <div>
                    <AccessControl
                      desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
                      elseContent={
                        <MappedSelect
                          label="Status"
                          options={statusOptions}
                          name="status"
                          data-testid="status"
                          disabled
                        />
                      }
                    >
                      <MappedSelect
                        label="Status"
                        options={statusOptions}
                        name="status"
                        data-testid="status"
                        disabled={!abnLookedUp}
                      />
                    </AccessControl>
                  </div>
                  <MappedInput
                    label="Laboratory API key ID"
                    name="laboratory_api_key_id"
                    data-testid="laboratory_api_key_id"
                    disabled={!abnLookedUp}
                  />
                </div>
              </div>
              <div className="row justify-content-between mt-10">
                <div className="col-6">
                  <h5 className="mb-4">
                    <strong>Physical Address</strong>
                  </h5>
                  <MappedInput
                    label="Address 1"
                    name="physical_address"
                    data-testid="physical_address"
                  />
                  <MappedInput
                    label="Address 2"
                    name="physical_address2"
                    data-testid="physical_address2"
                  />
                  <MappedInput
                    label="City"
                    name="physical_city"
                    data-testid="physical_city"
                  />
                  <div className="row">
                    <div className="col-6">
                      <MappedSelect
                        label="State"
                        name="physical_state"
                        data-testid="physical_state"
                        options={stateOptions}
                      />
                    </div>
                    <div className="col-6">
                      <MappedInput
                        label="Postal Code"
                        name="physical_postcode"
                        data-testid="physical_postcode"
                      />
                    </div>
                  </div>
                </div>
                <PostalAddress id={id} />
              </div>
              <div className="mt-10 d-flex justify-content-end mr-4">
                <div className="mr-3">
                  <ListButton
                    label="Cancel"
                    size="large"
                    onClick={handleClose}
                    data-testid="cancel"
                    variant="outlined"
                    boxShadow={false}
                    text="#407A28"
                  />
                </div>
                <div>
                  <ListButton
                    label="Save"
                    size="large"
                    onClick={handleSubmit}
                    boxShadow={false}
                    disabled={isSubmitting || !abnLookedUp}
                    data-testid="submit"
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
