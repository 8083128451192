/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { objectCheck } from "../../../ui/helpers";
import {
  filterDataHelper,
  HEADINGS,
  searchMap,
  SEARCH_HELPER,
} from "./constants";
import { setInvoicesList } from "./_redux/actions";
import {
  handleSearchInvoices,
  modifyInvoices,
  renderInvoicesRow,
} from "./helpers";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getInvoices,
  getInvoicesAmount,
  getInvoicesAutocomplete,
  getTenantInvoices,
} from "./_api";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import {
  getOrganisationsListAutocomplete,
  getSimpleOrganisations,
} from "../Organisations/_api";
import { getTestsAutocomplete } from "../Settings/Tests/_api";
import { get, uniq } from "lodash";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");
  const [simpleOrganisations, setSimpleOrganisations] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const data = useSelector(
    ({ testInvoices: { invoicesList } }) => invoicesList
  );
  const user = useSelector(({ auth: { user } }) => user) || {};
  const tenantUser_tenant = get(user, "permissions.is_tenant_user");
  const admins_tenant =
    tenantUser_tenant !== false ? tenantUser_tenant?.id : user.admins_tenant;

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (["organisation_name"].includes(autocompleteField)) {
      return request(
        getOrganisationsListAutocomplete,
        80,
        "trading_name",
        autocompleteValue,
        "",
        "",
        "",
        "",
        admins_tenant
      );
    } else if (autocompleteField === "test_code") {
      return request(getTestsAutocomplete, 80, "code", autocompleteValue);
    } else
      return request(
        getInvoicesAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        searchFields,
        admins_tenant
      );
  };

  function fetchTestsAutocomplete(
    autocompleteValue,
    autocompleteField,
    searchFields
  ) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then(
      (data) => {
        if (data && Array.isArray(data) && data.length && objectCheck(data)) {
          setAutocompleteData(uniq(data));
        } else {
          setAutocompleteData(["No option found"]);
        }
        setAutocompleteLoading(false);
      }
    );
  }

  const requestInvoices = () => {
    if (admins_tenant) {
      return request(
        getTenantInvoices,
        admins_tenant,
        page,
        pageSize,
        search,
        field,
        field && direction
      );
    } else
      return request(
        getInvoices,
        admins_tenant,
        page,
        pageSize,
        search,
        field,
        field && direction
      );
  };

  const fetchInvoices = () => {
    setLoading(true);
    requestInvoices()
      .then((data) => data && modifyInvoices(data))
      .then((data) => {
        if (!data) return;
        dispatch(setInvoicesList(data));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchInvoices();
  }, [search, page, pageSize, field, direction]);

  useEffect(() => {
    request(getInvoicesAmount, admins_tenant, search).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search]);

  useEffect(() => {
    request(getSimpleOrganisations).then(
      (data) => data && setSimpleOrganisations(data)
    );
  }, []);

  const resetSearch = () => {
    setPage(0);
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  const notSortable = (key) =>
    ["download_pdf", "test_code", "amount"].includes(key);

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      notSortable(key) ? (
        <th key={key} style={sortHeaderStyle} className="text-nowrap px-5">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="text-nowrap px-5"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        <div>
          <ServerSearchBar
            className="mb-5"
            onSearch={(data) =>
              handleSearchInvoices(
                data,
                filterDataHelper,
                setSearch,
                setPage,
                simpleOrganisations
              )
            }
            keyMap={SEARCH_HELPER}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchTestsAutocomplete}
            placeholder="Search Invoices..."
            clearSearch={resetSearch}
            focused={focused}
            setFocused={setFocused}
            searchMap={searchMap}
            searchFields={search}
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Test Invoices" handleFocus={handleFocus} />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderInvoicesRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            loading={loading}
            link="test-invoices"
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isServerPagination
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
};
