import { Skeleton } from "@material-ui/lab";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import * as auth from "../../app/modules/Auth/_redux/authRedux";
import { useFetch } from "../../hooks/fetch.hook";
import { findUsersTenant, getUserRole } from "../../ui/helpers";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { getUser } from "../modules/Auth/_api";
import { getIntegrations, getTenantIntegrations } from "../modules/Integrations/_api";

const GuestPage = (props) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector(({ auth: { user } }) => user) || {};

  const { request } = useFetch();
  const history = useHistory();

  const handleURLChange = (isEndUser) => {
    history.push(isEndUser ? "/tests-list" : "/dashboard");
  };

  useEffect(() => {
    setLoading(true);
    request(getUser)
      .then((userData) => {
        let admins_tenant = "";
        let integrationsRequest = null;
        if (userData) {
          const { id, tenants, roles, policies, permissions } = userData;

          const isTenantAdmin = getUserRole("is_tenant_admin", permissions.tenant);
          const isEndUser = get(user, "permissions.is_end_user");

          if (!permissions.is_national_admin && !isTenantAdmin && !isEndUser) return;

          if (permissions.is_national_admin) {
            integrationsRequest = getIntegrations;
          } else if (
            getUserRole("is_tenant_admin", permissions.tenant) &&
            !permissions.is_national_admin
          ) {
            integrationsRequest = getTenantIntegrations;
            admins_tenant = findUsersTenant(permissions.tenant);
          }

          const userDetails = {
            ...user,
            id: id,
            selectedTenant: tenants.lenght && tenants[0].id,
            tenants: tenants,
            roles: roles,
            permissions,
            policies,
            admins_tenant,
            sessionStart: new Date(),
          };

          if (integrationsRequest) {
            request(integrationsRequest, admins_tenant).then((integrations) => {
              props.fulfillUser({
                ...userDetails,
                integrations,
              });
            });
            handleURLChange(isEndUser);
          } else {
            props.fulfillUser(userDetails);
            handleURLChange(isEndUser);
          }
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="bg-white rounded px-10 py-40 d-flex flex-column align-items-center justify-content-center">
      <h4 className="mb-3">{`Hello ${user.fullname},`}</h4>
      <h4>
        Your user account permissions are currently being reviewed and established by the FreshTest
        National Administration Team. <br />If you are still seeing this message after 2 business days
        please contact the FreshTest National Office via email <span className="text-primary">freshtest@freshmarkets.com.au</span> or
        phone <span className="text-primary">(07) 3915 4240</span>.
      </h4>
      <div className="containter h-100 mt-10">
        <div className="p-5 bg-white">
          <span>
            <img className="img-fluid" src={toAbsoluteUrl("/assets/icons/Logo.svg")} alt="Logo" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default connect(null, auth.actions)(GuestPage);
