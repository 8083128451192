import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ListButton } from "../../../../ui/components/ListButton";

export const UpgradeTests = ({
  uprgadableTests = [],
  handleContinue,
  handleSubmit,
}) => {
  return (
    <div className="pb-5">
      <div className="text-center">
        <h3>
          Test you have selected may not meet your requirements, have you
          considered upgraded tests instead?
        </h3>
        <div>
          <div>Upgrade path:</div>
          <div>
            {uprgadableTests.map((upgradableTest) => (
              <div key={upgradableTest.code}>
                {upgradableTest.code} <ArrowForwardIcon />{" "}
                {upgradableTest?.upgrade_to_test?.code}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-10 d-flex justify-content-center mr-4">
        <div className="mr-3">
          <ListButton
            label="Continue with current tests"
            size="small"
            onClick={handleContinue}
            data-testid="cancel"
            variant="outlined"
            text="#407A28"
            boxShadow={false}
          />
        </div>
        <div>
          <ListButton
            label="Please upgrade my tests"
            size="small"
            onClick={handleSubmit}
            data-testid="submit"
          />
        </div>
      </div>
    </div>
  );
};
