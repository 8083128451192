import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { useSelector } from "react-redux";
import { TableSortLabel } from "@material-ui/core";
import { HEADINGS } from "./constants";
import { renderContactsRow } from "./helpers";
import { getSelectedId } from "../../../ui/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  archiveContact,
  getContactById,
  getContacts,
  getTenantContacts,
  modifyContact,
} from "../../modules/Contacts/_api";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { LinkContacts } from "./LinkContacts";
import { Modal } from "../../../ui/components/Modal";
import {
  getOrganisationContacts,
  lintContactsToOrganisations,
} from "../../modules/Organisations/_api";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { info } from "../../../helpers/toasts";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { Loader } from "../../../ui/components/Loader";

export const ContactsList = ({
  data = [],
  name,
  handleNew,
  handleExpand,
  organisationId,
  setContactsList,
}) => {
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [nonLinkedContacts, setNonLinkedContacts] = useState([]);

  const handleCOnfirmOpen = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const handleConfrimClose = () => setConfirmDeleteOpen(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const requestContacts = () => {
    if (admins_tenant) {
      return request(getTenantContacts, admins_tenant);
    } else return request(getContacts);
  };

  useEffect(() => {
    requestContacts().then((data) => data && setNonLinkedContacts(data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    request(getOrganisationContacts, organisationId, archived, field, direction)
      .then((data) => data && setContactsList(data))
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [archived, field, direction]);

  const updateCardData = (item = {}) => {
    const index = data.findIndex((contact) => (contact || {}).id === (item || {}).id);
    setContactsList((state) => [...state.slice(0, index), item, ...state.slice(index + 1)]);
  };

  const handleLinkContacts = (values, { setSubmitting }) => {
    const payload = values.contact_ids.map((id) => ({ id }));
    handleModalClose();
    setLoading(true);
    request(lintContactsToOrganisations, organisationId, payload).then((data) => {
      if (!data) {
        setSubmitting(false);
        setLoading(false);
        return;
      }
      request(getOrganisationContacts, organisationId)
        .then((data) => {
          if (!data) return;
          setContactsList(data);
          info("Contacts have been linked!");
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    });
  };

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const contactsOptions = useMemo(
    () =>
      nonLinkedContacts.map((contact) => ({
        value: contact.id,
        label: [contact.first_name, contact.last_name].join(" "),
      })),
    [nonLinkedContacts]
  );

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveContact, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getContactById, id).then((contact) => {
            if (!contact) return;
            updateCardData(contact);
          });
        } else {
          setContactsList((state) => state.filter((item) => id !== item.id));
        }
        info("Contact has been archived!");
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const contactID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === contactID) || {}).status;
    if (status !== "archived") return;
    setActionLoading(true);
    request(modifyContact, { status: "active" }, contactID)
      .then((data) => {
        if (!data) return;
        updateCardData(data);
        setSelected({});
        info("Contact has been unarchived!");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <AccessControl
      desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
      elseContent={
        <>
          <div>
            <ListButton label="New" onClick={handleNew} data-testid="new" className="mx-5" />
          </div>
          <div>
            <ListButton label="Link" onClick={handleModalOpen} data-testid="link" />
          </div>
        </>
      }
    >
      <>
        {archived && (
          <ListButton onClick={handleUnarchive} disabled={unArchiveDisabled} label="Unarchive" />
        )}
        <div>
          <ListButton label="New" onClick={handleNew} data-testid="new" className="mx-5" />
        </div>
        <div>
          <ListButton label="Link" onClick={handleModalOpen} data-testid="link" />
        </div>
      </>
    </AccessControl>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <>
      {actionLoading && (
        <Loader
          isOpen={actionLoading}
          title="Loading..."
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      {modalOpen && (
        <Modal
          maxWidth="sm"
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={
            <LinkContacts
              handleCancel={handleModalClose}
              linkedContacts={data}
              contactsOptions={contactsOptions}
              handleSubmit={handleLinkContacts}
            />
          }
        />
      )}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleConfrimClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleConfrimClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).first_name}
            />
          }
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title={name} renderButtons={renderButtons} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />

            <AccessControl
              desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
              elseContent={
                <Datagrid
                  data={data}
                  headings={HEADINGS}
                  renderRow={renderContactsRow}
                  selected={selected}
                  setSelected={setSelected}
                  expandable
                  handleExpand={handleExpand}
                  renderHeaderWithSorting={renderHeaderWithSorting}
                  selectable
                  loading={loading}
                />
              }
            >
              <Datagrid
                data={data}
                headings={HEADINGS}
                renderRow={renderContactsRow}
                selected={selected}
                setSelected={setSelected}
                expandable
                handleExpand={handleExpand}
                deletable
                handleDelete={handleCOnfirmOpen}
                renderHeaderWithSorting={renderHeaderWithSorting}
                selectable
                loading={loading}
              />
            </AccessControl>
          </div>
        </div>
      </div>
    </>
  );
};
