import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

export function Modal({
  onClose,
  isOpen,
  modalContent,
  buttonText = "OK",
  submitable = false,
  maxWidth = "lg",
  ...other
}) {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent className="p-0 pt-5 mx-5">
        {modalContent}{" "}
        {!submitable && (
          <div className="text-right mb-5 mr-10">
            <button onClick={onClose} className="btn btn-white">
              <strong>{buttonText}</strong>
            </button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
