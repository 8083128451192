import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { buttonClassName } from "../../modules/AddNewTest/constants";
import { useFetch } from "../../../hooks/fetch.hook";
import { linkUsers } from "../../modules/Organisations/_api";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { renderUsers } from "../../modules/Users/helpers";

const HEADINGS = [
  ["id", "User ID"],
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["phone_number", "Mobile"],
  ["email", "Email Address"],
  ["status", "Status"],
];
const headerStyle = {
  background: "#E8F5E1",
  color: "#71BF44",
};

export const UsersList = ({
  data,
  handleClose,
  organisationId,
  setUsersList,
  updateUsersList,
  setActionLoading,
}) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    setInitialData(data);
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    "First Name": data.map((item) => item.first_name),
    "Last Name": data.map((item) => item.last_name),
    Mobile: data.map((item) => item.phone_number),
    "Email Address": data.map((item) => item.email),
    Status: data.map((item) => item.status),
  };

  const handleSearch = (search, isRemoveKey) => {
    setUsersList(
      (isRemoveKey ? initialData : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          HEADINGS.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    );
  };

  const handleSubmit = () => {
    setActionLoading(true);
    const payload = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({
        id: item,
      }));
    handleClose();
    request(linkUsers, payload, organisationId).then((data) => {
      if (!data) return;
      updateUsersList();
    });
  };

  const resetSearch = () => setUsersList(initialData);

  const selectedCount = Object.values(selected).filter(Boolean).length;

  return (
    <>
      <div className="bg-white rounded p-10">
        <h4 className="mb-6">
          <strong>Select Users</strong>
        </h4>
        <div className="mb-10">
          <SearchBar
            onSearch={handleSearch}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter Users..."
            elevation={2}
          />
        </div>
        <div className="border 1px solid #E5E5E5">
          <div
            className="d-flex justify-content-between p-5 align-items-center"
            style={headerStyle}
          >
            <h6>{`${
              selectedCount > 1 ? `${selectedCount} items` : `${selectedCount} item`
            } selected`}</h6>
          </div>
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderUsers}
            selected={selected}
            setSelected={setSelected}
            selectable
          />
        </div>
        <div className="row mt-10">
          <div className="col-6 text-center">
            <button className={buttonClassName} onClick={handleClose}>
              Cancel
            </button>
          </div>
          <div className="col-6 text-center">
            <button className={buttonClassName} onClick={handleSubmit} disabled={!selectedCount}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
