import React, { useMemo } from "react";
import { AsideMenuList } from "./AsideMenuList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig} bg-white`}
        {...layoutProps.asideMenuAttr}
      >
        <div style={{paddingBottom: "80px", minHeight: "calc(100% - 80px)"}}>
          <AsideMenuList layoutProps={layoutProps} />
        </div>
        <div className="ml-5" style={{ height: "80px" }}>
          <div>
            <img src={toAbsoluteUrl("/assets/icons/Logo.svg")} alt="Logo" />
          </div>
          <div className="text-muted mt-5" style={{ fontSize: "10px" }}>
            Copyright 2021 &nbsp; | &nbsp;{" "}
            <a
              href="https://www.freshmarkets.com.au/policyposition/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      {/* end::Menu Container */}
    </>
  );
}
