import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { OrganisationDetails } from "./OrganisationDetails";
import { getOrganisation } from "./_redux/selectors";
import { Contacts } from "../../submodules/Contacts";
import { Sites } from "../../submodules/Sites";
import { OrganisationUsers } from "../../submodules/OrganisationUsers";
import { OrganisationTests } from "../../submodules/OrganisationTests";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getOrganisationById,
  getOrganisationPaymentMethods,
  getOrganisationTags,
  getSimpleOrganisations,
} from "./_api";
import { getTenants } from "../Tenants/_api";
import { PaymentSettings } from "./PaymentSettings";
import { modifyOrganisationData } from "./helpers";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const data = useSelector(getOrganisation(id));
  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const [cardData, setCardData] = useState({});
  const [tenants, setTenants] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [organisations, setOrganisations] = useState([]);

  const { trading_name } = cardData || "";

  useEffect(() => {
    if (!data) {
      Promise.all([request(getOrganisationById, id), request(getOrganisationTags, id)]).then(
        ([organisation, tags]) => {
          if (!organisation) return;
          setCardData({
            ...modifyOrganisationData(organisation),
            tags,
          });
        }
      );
    } else {
      request(getOrganisationTags, id).then((tags) => tags && setCardData({ ...data, tags }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Promise.all([request(getOrganisationPaymentMethods, id), request(getTenants)]).then(
      ([paymentMethods, tenants]) => {
        tenants && setTenants(tenants);
        paymentMethods && setPaymentMethods(paymentMethods);
      }
    );
    // eslint-disable-next-line
  }, []);

  const fetchPaymentMethod = () => {
    request(getOrganisationPaymentMethods, id).then((data) => data && setPaymentMethods(data));
  };

  useEffect(() => {
    request(getSimpleOrganisations).then((data) => data && setOrganisations(data));
    // eslint-disable-next-line
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const tabs = [
    {
      label: "Profile",
      tabPanel: (
        <OrganisationDetails
          name={trading_name}
          data={cardData}
          id={id}
          simpleOrganisations={organisations}
          setCardData={setCardData}
        />
      ),
    },
    {
      label: "Contacts",
      tabPanel: (
        <Contacts name={trading_name} organisationId={id} simpleOrganisations={organisations} />
      ),
    },
    {
      label: "Sites",
      tabPanel: <Sites organisation_id={id} />,
    },
    {
      label: "Tests",
      tabPanel: <OrganisationTests organisationId={id} tenants={tenants} />,
    },
    {
      label: "Linked Users",
      tabPanel: <OrganisationUsers organisationId={id} admins_tenant={admins_tenant} />,
    },
    {
      label: "Payment",
      tabPanel: (
        <PaymentSettings
          organisation_id={id}
          tenants={tenants.filter(({ name }) => name !== "DEFAULT")}
          paymentMethods={paymentMethods}
          fetchPaymentMethod={fetchPaymentMethod}
        />
      ),
    },
  ];

  return (
    <div className="mt-10">
      <Tabs defaultActive="0" tabs={tabs} align="right" />
    </div>
  );
};
