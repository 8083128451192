import React from "react";
import { ReactComponent as FilterIcon } from "../static/FilterList.svg";
import { IconButton, CircularProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const ListHeader = ({
  title,
  handleFocus,
  renderButtons,
  loading,
  description,
}) => {
  return (
    <div className="d-flex mb-3 justify-content-between align-items-center">
      {title && <h3 className="text-dark">{title}</h3>}
      {description ? (
        loading ? (
          <Skeleton variant="rect" width={280} height={"1rem"} />
        ) : (
          <p>{description}</p>
        )
      ) : (
        ""
      )}
      <div className="mb-5 d-flex justify-content-between align-items-center">
        {loading && (
          <div className="mr-5">
            <CircularProgress size="1.2rem" style={{ margin: 11 }} />
          </div>
        )}
        {renderButtons && renderButtons()}
        <div className="mr-5">
          {handleFocus && (
            <IconButton onClick={handleFocus}>
              <FilterIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};
