import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { HEADINGS } from "./constants";
import { TableSortLabel } from "@material-ui/core";
import { getSelectedId } from "../../../ui/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { archiveSite, getSiteById, modifySite } from "./_api";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { renderSitesRow } from "./helpers";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { getOrganisationSites } from "../../modules/Organisations/_api";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";

export const SitesList = ({ data = [], handleNew, handleExpand, organisation_id, setData }) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);

  const handleCOnfirmOpen = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const handleConfrimClose = () => setConfirmDeleteOpen(false);

  useEffect(() => {
    setLoading(true);
    request(getOrganisationSites, organisation_id, archived, field, direction)
      .then((data) => data && setData(data))
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [archived, field, direction]);

  const updateCardData = (item = {}) => {
    const index = data.findIndex((site) => (site || {}).id === (item || {}).id);
    setData((state) => [...state.slice(0, index), item, ...state.slice(index + 1)]);
  };

  const handleUnarchive = () => {
    const siteID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === siteID) || {}).status;
    if (status !== "archived") return;
    setActionLoading(true);
    request(modifySite, { status: "active" }, organisation_id, siteID)
      .then((data) => {
        if (!data) return;
        updateCardData(data);
        setSelected({});
        info("Site has been unarchived!");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveSite, organisation_id, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getSiteById, organisation_id, id).then((site) => {
            if (!site) return;
            updateCardData(site);
          });
        } else {
          setData((state) => state.filter((item) => id !== item.id));
        }
        info("Site has been archived!");
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <AccessControl
      desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
      elseContent={
        <>
          <div className="ml-5">
            <ListButton label="New" onClick={handleNew} data-testid="new" />
          </div>
        </>
      }
    >
      <>
        {archived && (
          <ListButton onClick={handleUnarchive} disabled={unArchiveDisabled} label="Unarchive" />
        )}
        <div className="ml-5">
          <ListButton label="New" onClick={handleNew} data-testid="new" />
        </div>
      </>
    </AccessControl>
  );

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <div className="row justify-content-center">
      {actionLoading && (
        <Loader
          isOpen={actionLoading}
          title="Loading..."
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleConfrimClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleConfrimClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).name}
            />
          }
        />
      )}
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Site List" renderButtons={renderButtons} />
          <ArchiveCheckbox
            archivedLoading={archivedLoading}
            archived={archived}
            showArchived={showArchived}
          />
          <AccessControl
            desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
            elseContent={
              <Datagrid
                data={data}
                headings={HEADINGS}
                renderRow={renderSitesRow}
                selected={selected}
                setSelected={setSelected}
                expandable
                handleExpand={handleExpand}
                selectable
                loading={loading}
                renderHeaderWithSorting={renderHeaderWithSorting}
              />
            }
          >
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderSitesRow}
              selected={selected}
              setSelected={setSelected}
              expandable
              handleExpand={handleExpand}
              selectable
              loading={loading}
              renderHeaderWithSorting={renderHeaderWithSorting}
              deletable
              handleDelete={handleCOnfirmOpen}
            />
          </AccessControl>
        </div>
      </div>
    </div>
  );
};
