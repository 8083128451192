import { priceTtypes } from "./actions";

const initialState = {
  pricesList: [],
  filter: "",
};

export function pricesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case priceTtypes.SET_DATA:
      return {
        ...state,
        pricesList: payload,
      };

    case priceTtypes.SET_FILTER:
      return {
        ...state,
        filter: payload,
      };

    case priceTtypes.REMOVE_PRICE:
      return {
        ...state,
        pricesList: [...state.pricesList.filter(({ id }) => id !== payload)],
      };

    default:
      return state;
  }
}
