import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { getSettingsTest } from "./_redux/selectors";
import { TestLaboratories } from "./LinkLaboratories/TestLaboratories";
import { TestDetails } from "./TestDetails";
import { TestAnalytes } from "./LinkedAnalytes/TestAnalytes";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getTestAnalytes, getTestById, getTestLaboratories } from "./_api";
import { modifyAnalytes } from "./helpers";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const data = useSelector(getSettingsTest(id));

  const [cardData, setCardData] = useState({});

  const { name, analytes, laboratories } = cardData || "";

  useEffect(() => {
    if (!data) {
      Promise.all([
        request(getTestById, id),
        request(getTestAnalytes, id),
        request(getTestLaboratories, id),
      ]).then(([test, analytes, laboratories]) => {
        if (!test) return;
        setCardData({
          ...test,
          analytes: modifyAnalytes(analytes),
          laboratories,
        });
      });
    } else {
      Promise.all([request(getTestAnalytes, id), request(getTestLaboratories, id)]).then(
        ([analytes, laboratories]) => {
          setCardData({
            ...data,
            analytes: modifyAnalytes(analytes),
            laboratories,
          });
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const tabs = [
    {
      label: "Profile",
      tabPanel: <TestDetails name={name} data={cardData} id={id} />,
    },
    {
      label: "Analytes",
      tabPanel: <TestAnalytes name={name} data={analytes} testId={id} setCardData={setCardData} />,
    },
    {
      label: "Linked Laboratories",
      tabPanel: (
        <TestLaboratories name={name} data={laboratories} testId={id} setCardData={setCardData} />
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActive="0" tabs={tabs} align="right" />
    </div>
  );
};
