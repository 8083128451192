import config from "react-global-configuration";

export const addCrop = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/crops`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getCrops = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/crops?${search && "&" + search}${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getCropById = (crop_id) =>
  fetch(`${config.get("ApiUrl").Rest}/crops/${crop_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyCrop = (data, crop_id) =>
  fetch(`${config.get("ApiUrl").Rest}/crops/${crop_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archiveCrop = (crop_id) =>
  fetch(`${config.get("ApiUrl").Rest}/crops/${crop_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getCropsAutocomplete = (
  page_size = 20,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/crops?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}&status=all`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const editCropTags = (crop_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/crops/${crop_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteCropTags = (crop_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/crops/${crop_id}/crops`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
