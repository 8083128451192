import React from "react";
import { FormControlLabel, CircularProgress, Checkbox } from "@material-ui/core";

export const ArchiveCheckbox = ({ archivedLoading, archived, showArchived }) => {
  return (
    <div className="d-flex mb-5 justify-content-end">
      <FormControlLabel
        control={
          archivedLoading ? (
            <CircularProgress size="1.2rem" style={{ margin: 11 }} />
          ) : (
            <Checkbox checked={archived} onChange={() => showArchived()} />
          )
        }
        label="Display Archived Records"
      />
    </div>
  );
};
