import React, { useState } from "react";
import { Formik } from "formik";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Button from "@bit/the-glue.frontendcomponents.button";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import {
  addUser,
  modifyUser,
  archiveUser,
  reactivateUser,
  resetAccount,
} from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { DetailsInitialValues } from "./constants";
import { userProfileSchema } from "../../../helpers/schemas";
import { info } from "../../../helpers/toasts";

export const UserDetails = ({ data = {}, id, name }) => {
  const { request } = useFetch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [inviteIsLoading, setInviteIsLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const handleSubmit = (
    { first_name, last_name, email, phone_number },
    { setSubmitting }
  ) => {
    request(
      id ? modifyUser : addUser,
      { email, phone_number, name: `${first_name} ${last_name}` },
      id
    )
      .then((data) => data && history.push("/users-list"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  const revokeAccess = () => {
    setLoading(true);
    request(archiveUser, id)
      .then((data) => data && history.push("/users-list"))
      .finally(() => setLoading(false));
  };

  const inviteUserToPortal = () => {
    setInviteIsLoading(true);
    request(reactivateUser, id)
      .then((data) => {
        if (!data) return;
        info(`${name} has been Invited to the portal!`);
      })
      .finally(() => setInviteIsLoading(false));
  };

  const resetAccountData = () => {
    setResetLoading(true);
    request(resetAccount, id)
      .then((data) => {
        if (!data) return;
        info("User's account has been reset!");
      })
      .finally(() => setResetLoading(false));
  };

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={id ? data : DetailsInitialValues}
        validationSchema={userProfileSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            {id && (
              <div className="mt-2 mb-7 row">
                <div className="col-8 d-flex justify-content-end">
                  {resetLoading ? (
                    <div className="mr-15">
                      <CircularProgress size="1.2rem" />
                    </div>
                  ) : (
                    <div className="mr-3">
                      <Button
                        label="Reset Account"
                        size="small"
                        text="#599D36"
                        background="light"
                        boxShadow
                        textTransform="uppercase"
                        fontWeight="600"
                        className="mr-5"
                        data-testid="reset"
                        onClick={resetAccountData}
                      />
                    </div>
                  )}
                  {loading ? (
                    <div className="mr-15">
                      <CircularProgress size="1.2rem" />
                    </div>
                  ) : (
                    <div className="mr-3">
                      <Button
                        label="Revoke Portal Access"
                        size="small"
                        text="#599D36"
                        background="light"
                        boxShadow
                        textTransform="uppercase"
                        fontWeight="600"
                        className="mr-5"
                        data-testid="revoke"
                        onClick={revokeAccess}
                      />
                    </div>
                  )}
                  {data.status === "archived" &&
                    (inviteIsLoading ? (
                      <div className="mr-15">
                        <CircularProgress size="1.2rem" />
                      </div>
                    ) : (
                      <Button
                        label="Invite User to Portal"
                        size="small"
                        text="#599D36"
                        background="light"
                        boxShadow
                        textTransform="uppercase"
                        fontWeight="600"
                        className="mr-5"
                        data-testid="revoke"
                        onClick={inviteUserToPortal}
                      />
                    ))}
                </div>
              </div>
            )}

            <h3 className="mb-10">
              <strong>{id ? name : "Add User"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="First Name"
                  name="first_name"
                  data-testid="first_name"
                />
                <MappedInput
                  label="Last Name"
                  name="last_name"
                  data-testid="last_name"
                />
                <MappedInput
                  label="Mobile"
                  name="phone_number"
                  data-testid="phone_number"
                />
                <MappedInput
                  label="Email"
                  name="email"
                  data-testid="email"
                  disable={id ? true : false}
                />
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
