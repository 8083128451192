import { laboratoryTypes } from "./actions";

const initialState = {
  laboratoriesList: [],
};
export function laboratoriesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case laboratoryTypes.SET_DATA:
      return {
        ...state,
        laboratoriesList: payload,
      };

    case laboratoryTypes.REMOVE_LABORATORY:
      return {
        ...state,
        laboratoriesList: [...state.laboratoriesList.filter(({ id }) => id !== payload)],
      };

    case laboratoryTypes.UNARCHIVE_DATA:
      const index = state.laboratoriesList.findIndex((item) => item.id === payload.id);
      const myState = [...state.laboratoriesList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        laboratoriesList: [...myState],
      };

    default:
      return state;
  }
}
