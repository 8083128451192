import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { getSelectedId, handleSearch } from "../../../../ui/helpers";
import { HEADINGS, searchMap } from "./constants";
import { removeTag, setTagsList, updateTagsList } from "./_redux/actions";
import { renderTagsRow } from "./helpers";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { ListButton } from "../../../../ui/components/ListButton";
import { useFetch } from "../../../../hooks/fetch.hook";
import { deleteTag, getCropTags, getTagById, modifyTag } from "../../Settings/Tags/_api";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { Modal } from "../../../../ui/components/Modal";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(true);
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");

  const data = useSelector(({ cropTags: { tagsList } }) => tagsList) || [];

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const fetchTags = () => {
    setLoading(true);
    request(getCropTags, search, archived, field, field && direction)
      .then((data) => {
        if (!data) return;
        dispatch(setTagsList(data));
        setInitialData(data);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTags();
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const handleUnarchive = () => {
    const TagID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === TagID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(modifyTag, { status: "active" }, TagID)
      .then((data) => {
        if (!data) return;
        dispatch(updateTagsList(data));
        setSelected({});
      })
      .finally(() => setUnarchiving(false));
  };

  const handleDelete = (id, setSubmitting) => {
    setSubmitting(true);
    request(deleteTag, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getTagById, id).then((data) => data && dispatch(updateTagsList(data)));
        } else dispatch(removeTag(id));
      })
      .finally(() => {
        setSubmitting(false);
        setConfirmDeleteOpen(false);
      });
  };

  const renderButtons = () => (
    <>
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-crop-tag`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
    </>
  );

  const KEY_MAP = {
    "Tag ID": data.map((item) => item.code),
    "Tag Name": data.map((item) => item.name),
    "Tag Category": data.map((item) => item.category),
    Status: data.map((item) => item.status),
  };

  const resetSearch = () => {
    dispatch(setTagsList(initialData));
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        {confirmDeleteOpen && (
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleDelete}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).name}
              />
            }
          />
        )}
        <div>
          <SearchBar
            onSearch={(data) => handleSearch(data, searchMap, setSearch)}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter Tags..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
            focused={focused}
            setFocused={setFocused}
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader
            title="Crops Tag Management"
            renderButtons={renderButtons}
            handleFocus={handleFocus}
          />
          <ArchiveCheckbox
            archivedLoading={archivedLoading}
            archived={archived}
            showArchived={showArchived}
          />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderTagsRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            selectable
            selected={selected}
            setSelected={setSelected}
            editable
            deletable
            handleDelete={handleOpenModal}
            loading={loading}
            paginationOptions={[50, 100, 200]}
            paginationDefaultValue={50}
            link="crop-tags"
          />
        </div>
      </div>
    </div>
  );
};
