import React from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { HEADINGS } from "./constants";
import { renderGroupsRow } from "./helpers";
import { ListHeader } from "../../../../ui/structures/ListHeader";
// import { ListButton } from "../../../../ui/components/ListButton";
// import { Modal } from "../../../../ui/components/Modal";
// import { AddTenantGroup } from "./AddTenantGroup";

//Uncomment in case we will decide to allow adding new groups
export const TenantGroups = ({ data, id, name, setTenantGroups }) => {
  // const [modalOpen, setModalOpen] = useState(false);

  // const handleModalOpen = () => setModalOpen(true);
  // const handleModalClose = () => setModalOpen(false);

  // const renderButtons = () => (
  //   <>
  //     <div className="mr-5">
  //       <ListButton label="Add" onClick={handleModalOpen} data-testid="add" />
  //     </div>
  //   </>
  // );

  return (
    <>
      {/* {modalOpen && (
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={
            <AddTenantGroup
              handleClose={handleModalClose}
              id={id}
              setTenantGroups={setTenantGroups}
            />
          }
        />
      )} */}
      <div className="row justify-content-center bg-white py-15 px-10">
        <div className="col-12">
          <ListHeader title={`${name} Tenant Groups`} />
          <Datagrid data={data} headings={HEADINGS} renderRow={renderGroupsRow} />
        </div>
      </div>
    </>
  );
};
