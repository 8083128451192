import React, { useState } from "react";
import { Formik } from "formik";
import { TextField, makeStyles } from "@material-ui/core";
import { GetApp as GetAppIconIcon } from "@material-ui/icons";
import Dropzone from "react-dropzone";
import { ListButton } from "../../../../ui/components/ListButton";
import { Modal } from "../../../../ui/components/Modal";

import { uploadFile } from "../../AWS-S3/upload";
import { downloadFile } from "../../AWS-S3/download";
import { useFetch } from "../../../../hooks/fetch.hook";
import { CorrectiveActionSchema } from "../../../../helpers/schemas";
import { formatDate } from "../../../../ui/helpers";
import {
  modifyCorrectiveAction,
  submitCorrectiveAction,
  downloadCorrectiveActionAttachment,
} from "../_api";

import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import Button from "@bit/the-glue.frontendcomponents.button";
import { MappedCheckbox } from "../../../../ui/components/Checkbox";
import { error } from "../../../../helpers/toasts";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormLabel-root": {
      color: "#71bf44",
      paddingRight: 12,
      background: "white",
    },
    "& input:disabled + fieldset": {
      borderColor: "#71bf44 !important",
      borderWidth: 2,
    },
  },
}));

export const CorrectiveActions = ({ data = {}, fetchResults, handleClose }) => {
  const corrective_action_id = data?.corrective_action?.id;
  const isCompleted = data?.corrective_action?.status === "completed";
  const classes = useStyles();
  const { request } = useFetch();
  const [fileData, setFileData] = useState([]);
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfrimClose = () => setConfirmOpen(false);
  const handleConfirmOpen = () => setConfirmOpen(true);

  const initialValues = {
    organisation_name:
      data.corrective_action.organisation_id === data.biller_org.id
        ? data.bill_to
        : data.corrective_action.organisation_id === data.tester_org.id
        ? data.test_organisation
        : "",
    person_raising: data?.corrective_action?.person_raising || "",
    problem_details: data?.corrective_action?.problem_details || "",
    has_problem_occurred_before:
      data?.corrective_action?.has_problem_occurred_before || false,
    fix_short_term: data?.corrective_action?.fix_short_term || "",
    date_completed_short_term: data?.corrective_action
      ?.date_completed_short_term
      ? new Date(data?.corrective_action?.date_completed_short_term)
      : new Date(Date.now()),
    person_responsible_short_term:
      data?.corrective_action?.person_responsible_short_term || "",
    fix_long_term: data?.corrective_action?.fix_long_term || "",
    date_completed_long_term: data?.corrective_action?.date_completed_long_term
      ? new Date(data?.corrective_action?.date_completed_long_term)
      : new Date(Date.now()),
    person_responsible_long_term:
      data?.corrective_action?.person_responsible_long_term || "",
    is_actions_taken_effective:
      data?.corrective_action?.is_actions_taken_effective || false,
    created_at: data?.corrective_action?.created_at || null,
    last_modified: data?.corrective_action?.last_modified || null,
    date_finalised: data?.corrective_action?.date_finalised || null,
    person_completed_by: data?.corrective_action?.person_completed_by || "",
  };

  const saveUrlFieldsValues = (value) => {
    request(modifyCorrectiveAction, corrective_action_id, {
      attachment_evidence_s3_key: value,
    });
  };

  function updateCAR(values, { setSubmitting }, isSubmit = false) {
    if (fileData.length)
      uploadFile(
        "corrective_actions",
        corrective_action_id,
        fileData[0],
        saveUrlFieldsValues
      );

    request(modifyCorrectiveAction, corrective_action_id, values)
      .then((data) => {
        if (!data) return;

        if (isSubmit)
          request(submitCorrectiveAction, corrective_action_id).then(() => {
            fetchResults();
            handleClose();
          });
      })
      .finally(() => {
        if (!isSubmit) fetchResults();
        setSubmitting(false);
      });
  }

  const handleDownload = (id) => {
    setFileDownloadLoading(true);
    downloadFile(
      "",
      downloadCorrectiveActionAttachment,
      setFileDownloadLoading,
      null,
      id
    );
  };

  return (
    <div className="bg-white p-10">
      <Formik
        onSubmit={handleConfirmOpen}
        initialValues={initialValues}
        validationSchema={CorrectiveActionSchema}
      >
        {({ handleSubmit, isSubmitting, values, setSubmitting }) => (
          <>
            {confirmOpen && (
              <Modal
                isOpen={confirmOpen}
                submitable
                onClose={handleConfrimClose}
                maxWidth="sm"
                modalContent={
                  <div className="p-5">
                    <div className="text-center mb-8">
                      <h3 className="text-danger">
                        Are you sure you want to submit the form?
                      </h3>
                    </div>
                    <div>
                      Kindly be informed that once the form is submitted, any
                      further modifications will not be permitted. If you
                      require additional time to review and verify the details,
                      it is recommended to utilise the 'Save' button.
                    </div>
                    <div className="mt-10 d-flex justify-content-end">
                      <div className="mr-3">
                        <ListButton
                          label="Cancel"
                          size="large"
                          onClick={handleConfrimClose}
                          variant="outlined"
                          boxShadow={false}
                          text="#407A28"
                        />
                      </div>
                      <div>
                        <ListButton
                          label="Confirm"
                          onClick={() => {
                            updateCAR(values, { setSubmitting }, true);
                            handleConfrimClose();
                          }}
                          size="large"
                          boxShadow={false}
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            )}
            <h3 className="mb-10">
              <strong>Corrective Action Form</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-9">
                <div className="mb-5">
                  <MappedInput
                    label="Business Name"
                    name="organisation_name"
                    disabled
                    data-testid="name"
                  />
                </div>
                <div className="mb-5">
                  <MappedInput
                    disabled={isCompleted}
                    label="Person Raising Corrective Action"
                    name="person_raising"
                    data-testid="name"
                  />
                </div>
                <div className="mb-5">
                  <MappedInput
                    disabled={isCompleted}
                    label="What is the problem?"
                    name="problem_details"
                    data-testid="name"
                  />
                </div>
              </div>

              <div className="col-3">
                <TextField
                  className={classes.root}
                  label="Created Date"
                  variant="outlined"
                  defaultValue={formatDate(values.created_at)}
                  disabled
                />
                <TextField
                  className={`${classes.root} mt-5`}
                  label="Last Modified"
                  variant="outlined"
                  defaultValue={formatDate(values.last_modified)}
                  disabled
                />
                {values.date_finalised ? (
                  <TextField
                    className={`${classes.root} mt-5`}
                    label="Date Finalised"
                    variant="outlined"
                    defaultValue={formatDate(values.date_finalised)}
                    disabled
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-9">
                <div className="mb-5">
                  <MappedInput
                    disabled={isCompleted}
                    label="Short Term Fix (what can be done now to fix the problem)?"
                    name="fix_short_term"
                    multiline
                    rows={6}
                    data-testid="name"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="mb-5">
                  <MappedInput
                    disabled={isCompleted}
                    label="Person Responsible"
                    name="person_responsible_short_term"
                    data-testid="name"
                  />
                </div>
                <MappedDatePicker
                  disabled={isCompleted}
                  name="date_completed_short_term"
                  label="Date Completed"
                />
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-9">
                <div className="mb-5">
                  <MappedInput
                    disabled={isCompleted}
                    label="Long Term Fix (what can be done to prevent the problem from happening again)?"
                    name="fix_long_term"
                    multiline
                    rows={6}
                    data-testid="name"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="mb-5">
                  <MappedInput
                    disabled={isCompleted}
                    label="Person Responsible"
                    name="person_responsible_long_term"
                    data-testid="name"
                  />
                </div>
                <MappedDatePicker
                  disabled={isCompleted}
                  name="date_completed_long_term"
                  label="Date Completed"
                  testId="date"
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-9">
                <div className="mb-5">
                  <MappedInput
                    disabled={isCompleted}
                    label="Completed by"
                    name="person_completed_by"
                    data-testid="name"
                  />
                </div>

                <MappedCheckbox
                  disabled={isCompleted}
                  label="Have the actions taken be effective?"
                  name="is_actions_taken_effective"
                  checked={values.is_actions_taken_effective}
                />
                <MappedCheckbox
                  disabled={isCompleted}
                  label="Has the problem occured before?"
                  name="has_problem_occurred_before"
                  checked={values.has_problem_occurred_before}
                />
              </div>
              <div className="col-3">
                {isCompleted ? (
                  ""
                ) : (
                  <Dropzone
                    maxFiles={1}
                    maxSize={5000000}
                    onDropRejected={(event) =>
                      event.map(({ errors, file }) =>
                        error(`${file?.name} - ${errors[0]?.message}`)
                      )
                    }
                    onDrop={(acceptedFiles) => {
                      setFileData(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            flex: "1",
                            display: "flex",
                            flexDirection: data?.corrective_action
                              ?.attachment_evidence_s3_key
                              ? "row"
                              : "column",
                            alignItems: "center",
                            padding: data?.corrective_action
                              ?.attachment_evidence_s3_key
                              ? "15px 20px 15px"
                              : "20px 20px 10px 20px",
                            borderWidth: 2,
                            borderRadius: 2,
                            borderColor: "#eeeeee",
                            borderStyle: "dashed",
                            backgroundColor: "#fafafa",
                            color: "#bdbdbd",
                            outline: "none",
                            transition: "border .24s ease-in-out",
                            cursor: "pointer",
                            overflow: "hidden",
                          }}
                        >
                          <input {...getInputProps()} />
                          <GetAppIconIcon
                            style={{
                              margin: data?.corrective_action
                                ?.attachment_evidence_s3_key
                                ? "0px 14px 0"
                                : "15px auto",
                            }}
                          />
                          <p className="m-0" style={{ textAlign: "center" }}>
                            {fileData.length
                              ? fileData.map((file) => file.name)
                              : data?.corrective_action
                                  ?.attachment_evidence_s3_key
                              ? "Change Evidence"
                              : "Attach Evidence (Optional)"}
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}

                {isCompleted ||
                data?.corrective_action?.attachment_evidence_s3_key ? (
                  <>
                    <Button
                      label={
                        <>
                          <GetAppIconIcon style={{ margin: "5px 5px 5px 0" }} />
                          Download Attachment
                        </>
                      }
                      size="small"
                      background="#71BF44"
                      textTransform="uppercase"
                      fontWeight="500"
                      fontSize="12px"
                      variant="outlined"
                      className="px-10 w-100"
                      style={{
                        marginTop: data?.corrective_action
                          ?.attachment_evidence_s3_key
                          ? 8
                          : 0,
                      }}
                      disabled={
                        fileDownloadLoading ||
                        !data?.corrective_action?.attachment_evidence_s3_key
                      }
                      onClick={() => handleDownload(corrective_action_id)}
                    ></Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-10 d-flex justify-content-end">
              <Button
                label="Cancel"
                size="small"
                background="#71BF44"
                textTransform="uppercase"
                fontWeight="500"
                fontSize="12px"
                variant="outlined"
                className="px-9 mr-3"
                disabled={isSubmitting}
                onClick={handleClose}
              />
              {isCompleted ? (
                ""
              ) : (
                <>
                  <Button
                    label="Save"
                    size="small"
                    background="#71BF44"
                    textTransform="uppercase"
                    fontWeight="500"
                    fontSize="12px"
                    variant="outlined"
                    className="px-9 mr-3"
                    disabled={isSubmitting}
                    onClick={() => updateCAR(values, { setSubmitting })}
                  />

                  <Button
                    label="Submit"
                    size="small"
                    background="#71BF44"
                    text="#E8F5E1"
                    boxShadow
                    textTransform="uppercase"
                    fontWeight="500"
                    fontSize="12px"
                    className="px-9 mr-3"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  />
                </>
              )}
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
