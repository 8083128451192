import React, { useEffect } from "react";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFormikContext } from "formik";

const paymentOptions = [
  { label: "Credit Account", value: "credit_account" },
  { label: "Credit Service", value: "credit_service" },
  { label: "Stripe", value: "stripe" },
];

export const TenantPaymentSettings = ({ tenant_name = "", tenant_id }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values[`${tenant_id}_payment_method`] === "stripe") {
      setFieldValue(`${tenant_id}_account_number`, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[`${tenant_id}_payment_method`]]);
  
  return (
    <div className="d-flex justify-content-start" style={{ height: "90px" }}>
      <h4 className="pt-5" style={{ width: "15%" }}>
        {tenant_name}
      </h4>
      <div className="w-25">
        <MappedSelect
          options={paymentOptions}
          label="Payment Method"
          name={`${tenant_id}_payment_method`}
        />
      </div>
      {(values[`${tenant_id}_payment_method`] === "credit_account" ||
        values[`${tenant_id}_payment_method`] === "credit_service") && (
        <div className="w-50 ml-10">
          <MappedInput label="Account Number" name={`${tenant_id}_account_number`} />
        </div>
      )}
    </div>
  );
};
