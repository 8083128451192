import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash";
import { TextField } from "@material-ui/core";

export const TagsAutocomplete = ({
  name,
  placeholder,
  setValue,
  options,
  loading,
  onClose,
  currentValue,
  multiple,
  defaultValue,
  disabled,
  ...other
}) => {
  const [tags, setTags] = useState([]);

  const handleSubmit = () => {
    setValue(name, [...currentValue, ...tags.map((tag) => tag.id)]);
    onClose();
  };

  const handleChangeMultiple = (_, value, reason) => {
    setTags(value);
  };

  const handleCHange = (_, value) => {
    setValue(name, get(value, "name", ""));
  };

  return (
    <>
      <Autocomplete
        options={options}
        id={name}
        multiple={multiple}
        onChange={multiple ? handleChangeMultiple : handleCHange}
        getOptionLabel={(item) => (item.category ? `${item.name} - ${item.category}` : item.name)}
        defaultValue={options.find(({ id }) => id === defaultValue)}
        disabled={disabled}
        {...other}
        renderInput={(params) => (
          <TextField {...params} label={placeholder} variant="outlined" color="secondary" />
        )}
      />
      <div className="d-flex justify-content-end mb-5 mr-10">
        <button onClick={onClose} className="btn btn-white">
          <strong>Cancel</strong>
        </button>
        <button onClick={handleSubmit} className="btn btn-white">
          <strong>OK</strong>
        </button>
      </div>
    </>
  );
};
