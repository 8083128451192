import config from "react-global-configuration";

export const addContact = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getContacts = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/contacts?${search && "&" + search}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTenantContacts = (tenant_id, search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts?${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field && "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getContactById = (contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyContact = (data, contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archiveContact = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const inviteUser = (contact_id, payload) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/invite`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include",
  });

export const getContactOrganisations = (contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/organisations`, {
    mode: "cors",
    credentials: "include",
  });

export const linkOrganisationToContact = (contact_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/organisations`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editContactTags = (contact_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteContactTags = (contact_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/tags`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
  
export const getContactTags = (contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/tags`, {
    mode: "cors",
    credentials: "include",
  });
