import React from "react";
import { buttonStyle, itemStyle } from "./constants";
import { ReactComponent as StripeLogo } from "./static/Stripe.svg";

export const Stripe = ({ handleConnect, connected, handleDisconnect }) => {
  return (
    <>
      <div style={itemStyle} className="bg-white p-7 mt-10">
        <div className="text-center mb-15">
          <StripeLogo style={{ height: 78 }} />
        </div>
        <h4 className="mb-2">
          <strong>Stripe</strong>
        </h4>
        <div className="mb-15">
          This integration connects the FreshTest platform with Stripe.
        </div>
        <div>
          {connected ? (
            <div className="text-right">
              <button
                className={`${buttonStyle} text-danger`}
                onClick={handleDisconnect}
              >
                Disconnect
              </button>
            </div>
          ) : (
            <button
              className="text-uppercase border-0 bg-transparent text-primary font-weight-bold"
              onClick={handleConnect}
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </>
  );
};
