import React, { useState } from "react";
import { Formik } from "formik";
import { TagsField } from "./TagsField";
import { Modal } from "../../components/Modal";
import { TagsAutocomplete } from "./TagsAutocomplete";
import { ListButton } from "../../components/ListButton";

export const TagsForm = ({ id, tags = [], linkedTags = [], handleSubmit, wrapperClassName = "col-4"}) => {
  const initialValues = {
    tags: linkedTags.map(({ id }) => id),
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <>
            {modalOpen && (
              <Modal
                maxWidth="sm"
                isOpen={modalOpen}
                submitable
                onClose={handleModalClose}
                modalContent={
                  <TagsAutocomplete
                    name="tags"
                    placeholder="Select Tags"
                    setValue={setFieldValue}
                    options={tags.filter((item) => !values.tags.includes(item.id))}
                    loading={!tags.length}
                    onClose={handleModalClose}
                    currentValue={values.tags}
                    multiple
                  />
                }
              />
            )}
            <div className={wrapperClassName}>
              <TagsField
                label="Tags"
                tags={values.tags}
                handleAdd={handleModalOpen}
                setValue={setFieldValue}
                predefinedTags={tags}
                fieldName="tags"
              />
              <div className="mt-10 text-right">
                <ListButton
                  label="Submit Tags"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  size="medium"
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
