import React, { useState } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { AnalyteHeadings } from "./constants";
import { renderAnalytesRow } from "./helpers";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { ListButton } from "../../../ui/components/ListButton";
import { useFetch } from "../../../hooks/fetch.hook";
import { modifyTestRequest } from "../Tests/_api";
import { info } from "../../../helpers/toasts";
import { updateResultsList } from "./_redux/actions";
import { useDispatch } from "react-redux";
import { Loader } from "../../../ui/components/Loader";
import { modifyResultObject } from "../ResultsReview/helpers";

export const AnalytesList = ({
  data = [],
  setAnalytesData,
  selectedResult,
  tenants = [],
  fetchResults,
}) => {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);

  const ascSort = (data = [], key) => {
    data.sort(function(a, b) {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  };

  const descSort = (data = [], key) => {
    data.sort(function(a, b) {
      if (a[key] > b[key]) return -1;
      if (a[key] < b[key]) return 1;
      return 0;
    });
  };

  const handleSort = (data = [], key) => {
    setDirection((state) => {
      setField(key);
      state === "desc" ? ascSort(data, key) : descSort(data, key);
      return key !== field ? "desc" : direction === "desc" ? "asc" : "desc";
    });
  };

  const sortFields = ["AnalyteID", "name"];

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      sortFields.includes(key) ? (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => handleSort(data, key)}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      ) : (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      )
    );
  }

  const handleApproveLimits = () => {
    setLoading(true);
    const payload = {
      results: data,
      status: "admin_review",
    };
    request(modifyTestRequest, payload, selectedResult)
      .then((data) => {
        if (!data) return;
        dispatch(updateResultsList(modifyResultObject(data, tenants)));
        fetchResults();
        info("Limits have been updated!");
      })
      .finally(() => setLoading(false));
  };

  const renderButtons = () => {
    return (
      <div>
        <ListButton
          label="Submit Limits"
          onClick={handleApproveLimits}
          disabled={loading}
        />
      </div>
    );
  };

  return (
    <>
      {loading && <Loader title="Loading..." isOpen={loading} />}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Results" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={AnalyteHeadings}
              renderRow={(headings, item) =>
                renderAnalytesRow(headings, item, data, setAnalytesData)
              }
              renderHeaderWithSorting={renderHeaderWithSorting}
              paginationDefaultValue={100000}
            />
          </div>
        </div>
      </div>
    </>
  );
};
