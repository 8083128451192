import React, { useState } from "react";
import { Formik } from "formik";
import {
  blockMinHeight,
  buttonClassName,
  nexStepButtonStyle,
  tenantFiltersMap,
} from "./constants";
import { tenantSelectSchema } from "../../../helpers/schemas";
import { Filter } from "../../../ui/components/Filter";
import { filterResults, getKeys } from "./helpers";
import { MappedRadio } from "../../../ui/components/Radio";

export const TenantSelect = ({
  handleNextStep,
  selectedTenant,
  data = [],
  handlePrevStep,
  step,
}) => {
  const { tenant_id } = selectedTenant;

  const inititalValues = {
    tenant_id,
  };

  const getSelectedResult = () => {
    const result = data.find(({ id }) => id === tenant_id);
    return result && [result];
  };

  const getInitialFilterValue = () => {
    const selectedOption = data.find(({ id }) => tenant_id === id);
    if (selectedOption) {
      return [{ name: selectedOption.name }];
    }
  };

  const [searchResults, setSearchResults] = useState(getSelectedResult() || []);
  const [filterValue, setFilterValue] = useState(getInitialFilterValue() || []);

  const handleFilter = (filterValue) => {
    const keys = getKeys(filterValue);
    setSearchResults(
      !keys.length ? [] : filterResults(data, keys, filterValue)
    );
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "selectedTenant");
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={inititalValues}
      validationSchema={tenantSelectSchema}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          <div className="row">
            <div className="col-6">
              <div className="bg-white p-10" style={blockMinHeight}>
                <h4 className="mb-10">
                  <strong>Select Sample Delivery Location</strong>
                </h4>
                {tenantFiltersMap.map(({ name, placeholder }) => (
                  <Filter
                    key={name}
                    name={name}
                    placeholder={placeholder}
                    options={data}
                    handleFilter={handleFilter}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    setValue={setFieldValue}
                    defaultValue={values.tenant_id}
                    radioButtonName="tenant_id"
                  />
                ))}
              </div>
            </div>
            <div className="col-6">
              <div className="bg-white p-10" style={blockMinHeight}>
                <h4 className="mb-10">
                  <strong>Search Results</strong>
                </h4>
                <div style={{ minHeight: "50px" }}>
                  <MappedRadio options={searchResults} name="tenant_id" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-15">
            <div className="col-6 text-center">
              {step !== 0 ? (
                <button className={buttonClassName} onClick={handlePrevStep}>
                  Back
                </button>
              ) : null}
            </div>
            <div className="col-6 text-center">
              <button
                className={nexStepButtonStyle}
                onClick={handleSubmit}
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
