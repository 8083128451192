import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { blockMinHeight, buttonClassName, nexStepButtonStyle } from "./constants";

export const PopulateData = ({
  data = [],
  handleNextStep,
  handleSubmit,
  selectedTestRequest = {},
}) => {
  const { test } = selectedTestRequest;

  const inititalValues = {
    test: test || {},
  };

  const handleSkip = (setTouched) => {
    setTouched(["test"], false);
    handleNextStep();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={inititalValues}
      validationSchema={Yup.object({
        test: Yup.string().required("State is a required field"),
      })}
    >
      {({ handleSubmit, setFieldValue, setTouched, values }) => (
        <>
          <div className="bg-white p-10" style={blockMinHeight}>
            <h4 className="mb-10">
              <strong>Select Test to Populate Data from</strong>
            </h4>
            <div className="w-50 mt-15">
              <Autocomplete
                name="test"
                placeholder="Select Test"
                setValue={setFieldValue}
                options={data}
                defaultValue={test.code}
                defaultValueField="code"
                label="code"
                data-testid="test"
                variant="outlined"
                defaultStyle
                isObjectValue
              />
              <div className="mt-15 mb-5 text-center">
                <button
                  className={`${buttonClassName} col-12`}
                  onClick={() => handleSkip(setTouched)}
                >
                  Skip
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-15">
            <div className="col-6"></div>
            <div className="col-6 text-center">
              <button
                className={nexStepButtonStyle}
                onClick={handleSubmit}
                type="submit"
                disabled={!Object.keys(values.test).length}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
