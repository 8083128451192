export const HEADINGS = [
  ["action", "Action"],
  ["effect", "Effect"],
  ["policy_pattern", "Policy Pattern"],
];

export const initialValues = {
  action: "",
  effect: "",
  policy_pattern: "",
};

export const actionOptions = [
  { value: "create", label: "Create" },
  { value: "read", label: "Read" },
  { value: "update", label: "Update" },
  { value: "delete", label: "Delete" },
];

export const effectOptions = [
  { value: "allow", label: "Allow" },
  { value: "deny", label: "Deny" },
];
