import React from "react";
import { OrganisationDetails } from "../../app/modules/Organisations/OrganisationDetails";

export const AddLaboratoryPage = () => {
  return (
    <div>
      <OrganisationDetails isLaboratory />
    </div>
  );
};
