import React from "react";
import { useHistory } from "react-router";
import { useFetch } from "../../hooks/fetch.hook";
import { TenantDetails } from "../modules/Tenants/TenantDetails";
import { addTenant } from "../modules/Tenants/_api";

export function TenantDetailsPage() {
  const { request } = useFetch();
  const history = useHistory();

  const handleSubmit = (values, { setSubmitting }) => {
    request(addTenant, values)
      .then((data) => data && history.push("/tenant-administration"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  return <TenantDetails handleClose={handleClose} handleSubmit={handleSubmit} />;
}
