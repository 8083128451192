export const HEADINGS = [
  ["colour", ""],
  ["code", "Batch Code"],
  ["sample_reference", "Customer Ref"],
  ["test_code", "Test Code"],
  ["bill_to", "Bill to"],
  ["test_organisation", "Test Org"],
  ["site", "Test Site"],
  ["state", "State"],
  ["submit_date", "Submitted"],
  ["receive_date", "Received"],
  ["result_date", "Results"],
  ["status", "Status"],
  ["ft_cert", "FT Cert"],
  ["nata_cert", "NATA Cert"],
  ["car_status", "CAR"],
];

export const SEARCH_HELPER = [
  ["code", "Batch Code"],
  ["biller_org.trading_name", "Bill To"],
  ["tester_org.trading_name", "Test Org"],
  ["test_code", "Test Code"],
  ["product", "Product"],
  ["sample_reference", "Customer Ref"],
  ["colour", "Colour"],
  ["car_status", "CAR Status"],
];

export const searchMap = {
  "Batch Code": "code",
  "Customer Ref": "sample_reference",
  "Bill To": "biller_org.trading_name",
  "Test Org": "tester_org.trading_name",
  "Test Code": "test_code",
  Product: "product",
  Status: "status",
  Colour: "colour",
  "CAR Status": "car_status",
};

export const filterDataHelper = {
  "Bill To": "biller_org_id",
  "Test Org": "tester_org_id",
  "Test Code": "test_id",
  Product: "crop_id",
  "Batch Code": "code",
  "Customer Ref": "sample_reference",
  Status: "status",
  Colour: "colour",
  "CAR Status": "corrective_action.status",
};
