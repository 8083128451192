export const HEADINGS = [
  ["code", "Batch Code"],
  ["product", "Product"],
  ["sample_reference", "Customer Ref"],
  ["test_code", "Test Code"],
  ["bill_to", "Bill to"],
  ["test_organisation", "Test Org"],
  ["site", "Test Site"],
  ["state", "State"],
  ["submit_date", "Submitted"],
  ["receive_date", "Received"],
  ["result_date", "Results"],
  ["status", "Status"],
  ["laboratory_price", "Cost"],
];

export const SEARCH_HELPER = [
  ["test_request.code", "Batch Code"],
  ["test_request.biller_org_id", "Bill To"],
  ["test_request.tester_org_id", "Test Org"],
  ["test_code", "Test Code"],
  ["product", "Product"],
  ["test_request.sample_reference", "Customer Ref"],
];

export const searchMap = {
  "Batch Code": "test_request.code",
  "Customer Ref": "test_request.sample_reference",
  "Bill To": "test_request.biller_org_id",
  "Test Org": "test_request.tester_org_id",
  "Test Code": "test_code",
  Product: "product",
};

export const filterDataHelper = {
  "Bill To": "test_request.biller_org_id",
  "Test Org": "test_request.tester_org_id",
  "Test Code": "test_request.test_id",
  Product: "crop_id",
  "Batch Code": "test_request.code",
  "Customer Ref": "test_request.sample_reference",
};
