import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { getSelectedId, handleSearch } from "../../../ui/helpers";
import { HEADINGS } from "./constants";
import { removeContact, setContactsList, updateContactsList } from "./_redux/actions";
import { renderContactsRow } from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  archiveContact,
  getContactById,
  getContacts,
  getTenantContacts,
  modifyContact,
} from "./_api";
import { searchMap } from "./constants";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { getTags } from "../Settings/Tags/_api";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { AccessControl } from "../../../ui/structures/AccessControl";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [tags, setTags] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(({ contacts: { contactsList } }) => contactsList);

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const requestContacts = () => {
    if (admins_tenant) {
      return request(getTenantContacts, admins_tenant, search, archived, field, field && direction);
    } else return request(getContacts, search, archived, field, field && direction);
  };

  const fetchContacts = () => {
    setLoading(true);
    requestContacts()
      .then((data) => {
        if (!data) return;
        dispatch(setContactsList(data));
        setInitialData(data);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  useEffect(() => {
    request(getTags, "type=Contact").then((data) => data && setTags(data));
    // eslint-disable-next-line
  }, []);

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setConfirmDeleteOpen(false);
    setActionLoading(true);
    request(archiveContact, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getContactById, id).then((data) => data && dispatch(updateContactsList(data)));
        } else dispatch(removeContact(id));
        info("Contact has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
        setSubmitting(false);
      });
  };

  const handleUnarchive = () => {
    const contactID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === contactID) || {}).status;
    if (status !== "archived") return;
    setActionLoading(true);
    setUnarchiving(true);
    request(modifyContact, { status: "active" }, contactID)
      .then((data) => {
        if (!data) return;
        dispatch(updateContactsList(data));
        setSelected({});
        info("Contact has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <AccessControl
      desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
      elseContent={
        <div className="mr-10">
          <Link
            to={{
              pathname: `/add-contact`,
            }}
            data-testid="new"
          >
            <ListButton label="New" />
          </Link>
        </div>
      }
    >
      <>
        {archived && (
          <UnarchiveButton
            handleClick={handleUnarchive}
            disabled={unArchiveDisabled}
            loading={unArchiving}
          />
        )}
        <div className="mr-10">
          <Link
            to={{
              pathname: `/add-contact`,
            }}
            data-testid="new"
          >
            <ListButton label="New" />
          </Link>
        </div>
      </>
    </AccessControl>
  );

  const KEY_MAP = {
    "First Name": data.map((item) => item.first_name),
    "Last Name": data.map((item) => item.last_name),
    Phone: data.map((item) => item.phone),
    Mobile: data.map((item) => item.mobile),
    Email: data.map((item) => item.email),
    Tag: tags.map((item) => (item || {}).name),
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    dispatch(setContactsList(initialData));
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <>
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).first_name}
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <SearchBar
              onSearch={(data) => handleSearch(data, searchMap, setSearch)}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter contacts..."
              elevation={2}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
              focused={focused}
              setFocused={setFocused}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Contacts" renderButtons={renderButtons} handleFocus={handleFocus} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderContactsRow}
              selected={selected}
              setSelected={setSelected}
              editable
              loading={loading}
              selectable
              deletable={Boolean(!admins_tenant)}
              handleDelete={handleOpenModal}
              renderHeaderWithSorting={renderHeaderWithSorting}
              link="contacts"
            />
          </div>
        </div>
      </div>
    </>
  );
};
