import config from "react-global-configuration";

export const getIntegrationToken = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getIntegrations = () =>
  fetch(`${config.get("ApiUrl").Rest}/integrations`, {
    mode: "cors",
    credentials: "include",
  });

export const getTenantIntegrations = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/integrations`, {
    mode: "cors",
    credentials: "include",
  });

export const getXeroConnections = (access_token) =>
  fetch(`https://api.xero.com/connections`, {
    headers: {
      "Content-Type": "application/json",
      authorization: `"Bearer " + ${access_token}`,
    },
  });

export const deleteIntegration = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getXeroParameters = () =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/xero_parameters`, {
    mode: "cors",
    credentials: "include",
  });

export const getIntegrationConfig = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${id}`, {
    mode: "cors",
    credentials: "include",
  });

export const setIntegrationConfig = (data, id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getMyobParameters = () =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/myob_parameters`, {
    mode: "cors",
    credentials: "include",
  });

export const createStripeIntegration = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
