export const priceTtypes = {
  SET_DATA: "PRICES/SET_DATA",
  REMOVE_PRICE: "PRICES/REMOVE_PRICE",
  SET_FILTER: "PRICES/SET_FILTER",
};

export function setPricesList(data) {
  return {
    type: priceTtypes.SET_DATA,
    payload: data,
  };
}

export function removePriceList(data) {
  return {
    type: priceTtypes.REMOVE_PRICE,
    payload: data,
  };
}

export function setPricesListFilter(data) {
  return {
    type: priceTtypes.SET_FILTER,
    payload: data,
  };
}
