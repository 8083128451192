import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState, useMemo } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { getContactOrganisations } from "../../modules/Contacts/_api";
import { ContactCard } from "./ContactCard";
import { ContactsList } from "./ContactsList";

export const Contacts = ({ name, organisationId, simpleOrganisations = [] }) => {
  const { request } = useFetch();

  const [component, setComponent] = useState("list");
  const [componentId, setComponentId] = useState("");
  const [linkedOrgsLoading, setLinkedOrgsLoading] = useState(false);
  const [linkedOrganisations, setLinkedOrganisations] = useState([]);
  const [contactsList, setContactsList] = useState([]);

  useEffect(() => {
    if (componentId) {
      setLinkedOrgsLoading(true);
      request(getContactOrganisations, componentId)
        .then((data) => data && setLinkedOrganisations((data || []).map(({ id }) => id)))
        .finally(() => setLinkedOrgsLoading(false));
    }
    // eslint-disable-next-line
  }, [componentId]);

  const organiastionOptions = useMemo(
    () =>
      simpleOrganisations.map((organiastion) => ({
        value: organiastion.id,
        label: organiastion.trading_name,
      })),
    [simpleOrganisations]
  );

  if (linkedOrgsLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const getContactById = () => contactsList && contactsList.find(({ id }) => id === componentId);

  const handleExpand = (id) => () => {
    setComponentId(id);
    setComponent("edit");
  };

  const handleNew = () => {
    setComponent("new");
  };

  const handleCancel = () => {
    setComponent("list");
  };

  const componentsMap = {
    list: (
      <ContactsList
        name={name}
        handleExpand={handleExpand}
        handleNew={handleNew}
        organisationId={organisationId}
        setContactsList={setContactsList}
        data={contactsList}
      />
    ),
    new: (
      <ContactCard
        handleCancel={handleCancel}
        organisationId={organisationId}
        organiastionOptions={organiastionOptions}
        linkedOrganisations={[organisationId]}
      />
    ),
    edit: (
      <ContactCard
        data={getContactById()}
        id={componentId}
        handleCancel={handleCancel}
        organisationId={organisationId}
        organiastionOptions={organiastionOptions}
        linkedOrganisations={linkedOrganisations}
      />
    ),
  };

  return <>{componentsMap[component]}</>;
};
