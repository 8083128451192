import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { formatPrice } from "../../../ui/helpers";

import {
  getOrganisationPaymentMethods,
  getOrganisationById,
} from "../Organisations/_api";
import { buttonClassName } from "./constants";

export const Payment = ({
  handleSubmit,
  handleStripeSubmit,
  handlePrevStep,
}) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState({});

  const { request } = useFetch();

  const data = useSelector(({ newTest: { data } }) => data);

  const { tests, selectedTenant, customer, testOrganisation } = data;
  const total = (tests || {}).payment_total;
  const tenant_id = (selectedTenant || {}).tenant_id;
  const biller_org_id = (customer || {}).biller_org_id;

  useEffect(() => {
    if (data) {
      // customer, testOrganisation, site, product
      Promise.all([
        request(getOrganisationById, data.customer.biller_org_id),
        request(getOrganisationById, data.testOrganisation.tester_org_id),
      ]).then(([customer, testOrganisation]) => {
        if (!customer || !testOrganisation) return;
        setCardData({
          customer: customer,
          testOrganisation: testOrganisation,
        });
      });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    request(getOrganisationPaymentMethods, biller_org_id)
      .then((data) => data && setPaymentMethods(data))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedPaymentMethod = paymentMethods.find(
    (method) => (method || {}).tenant_id === tenant_id
  );

  const paymentMethodAvailable =
    (selectedPaymentMethod || {}).payment_method === "stripe";

  if (loading) return <Skeleton variant="rect" width={"100%"} height={520} />;

  return (
    <>
      <div className="bg-white p-10">
        <h4 className="mb-10">
          <strong>Test Summary</strong>
        </h4>
        {data ? (
          <>
            <div
              class="row"
              style={{
                width: "100%",
                marginTop: 7,
              }}
            >
              <div class="col-3">
                <h5 class="title">Bill To Organisation</h5>
                <p>
                  {cardData?.customer ? (
                    <>
                      <div>{cardData.customer?.trading_name}</div>
                      <div>{cardData.customer?.legal_name}</div>
                      <div>{cardData.customer?.physical_address}</div>
                      <div>{cardData.customer?.physical_address2}</div>
                      <div>
                        {data.site?.site?.physical_city}{" "}
                        {data.site?.site?.physical_state}{" "}
                        {data.site?.site?.physical_postcode}
                      </div>
                    </>
                  ) : customer?.trading_name ? (
                    <>
                    <div>{customer?.trading_name}</div>
                    <div>Address information is stored but not displayed for privacy</div>
                    </>
                    ) : (
                    "Bill To Organisation not specified"
                  )}
                </p>
              </div>
              <div class="col-3">
                <h5 class="title">Tested Organisation</h5>
                <p>
                  {cardData?.testOrganisation ? (
                    <>
                      <div>{cardData.testOrganisation?.trading_name}</div>
                      <div>{cardData.testOrganisation?.legal_name}</div>
                      <div>{cardData.testOrganisation?.physical_address}</div>
                      <div>{cardData.testOrganisation?.physical_address2}</div>
                      <div>
                        {data.site?.site?.physical_city}{" "}
                        {data.site?.site?.physical_state}{" "}
                        {data.site?.site?.physical_postcode}
                      </div>
                    </>
                  ) : testOrganisation?.trading_name ? (
                  <>
                  <div>{testOrganisation?.trading_name}</div>
                  <div>Address information is stored but not displayed for privacy</div>
                  </>
                  ) :
                  (
                    "Tested Organisation not specified"
                  )}
                </p>
              </div>
              <div class="col-3">
                <h5 class="title">Site Tested</h5>
                <p>
                  {data.site ? (
                    <>
                      <div>{data.site?.site?.name}</div>
                      <div>{data.site?.site?.physical_address}</div>
                      <div>{data.site?.site?.physical_address2}</div>
                      <div>
                        {data.site?.site?.physical_city}{" "}
                        {data.site?.site?.physical_state}{" "}
                        {data.site?.site?.physical_postcode}
                      </div>
                    </>
                  ) : (
                    "Site not specified"
                  )}
                </p>
              </div>
              <div class="col-2">
                <h5 class="title">Product</h5>
                <p>
                  {data.product ? (
                    <>
                      <div>{data.product?.name}</div>
                    </>
                  ) : (
                    "Product not specified"
                  )}
                </p>
              </div>
              <div class="col-1">
                <h5 class="title">Sample Reference</h5>
                <p>
                  {data.additionalInfo?.sample_reference ? (
                    <>
                      <div>{data.additionalInfo?.sample_reference}</div>
                    </>
                  ) : (
                    "Sample Reference not specified"
                  )}
                </p>
              </div>
            </div>
            {data?.tests?.tests?.length ? (
              <div
                class="row"
                style={{
                  width: "100%",
                  marginTop: 40,
                }}
              >
                <div class="col-2">
                  <h5 class="title">Test Code</h5>
                </div>
                <div class="col-4">
                  <h5 class="title">Test Name</h5>
                </div>
                <div class="col-2 text-right">
                  <h5 class="title">Price (ex GST)</h5>
                </div>
                <div class="col-2 text-right">
                  <h5 class="title">GST</h5>
                </div>
                <div class="col-2 text-right">
                  <h5 class="title">Line Total</h5>
                </div>
                {data.tests.tests.map((test) => (
                  <>
                    <div class="col-2">
                      <p class="title">{test?.code}</p>
                    </div>
                    <div class="col-4">
                      <p class="title">{test?.name}</p>
                    </div>
                    <div class="col-2 text-right">
                      <p class="title">{formatPrice(test?.price)}</p>
                    </div>
                    <div class="col-2 text-right">
                      <p class="title">{formatPrice(test?.price * 0.1)}</p>
                    </div>
                    <div class="col-2 text-right">
                      <p class="title">
                        {formatPrice(test?.price + test?.price * 0.1)}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <div
                class="row"
                style={{
                  width: "100%",
                  marginTop: 7,
                }}
              >
                Tests are not specified
              </div>
            )}

            <div
              class="row"
              style={{
                width: "100%",
                marginTop: 7,
              }}
            >
              <div class="col-10 text-right">
                <h5 class="title">Payment Total*</h5>
              </div>
              <div class="col-2 text-right">
                <h5 class="title">{formatPrice(total + total * 0.1)}</h5>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div
          class="row"
          style={{
            width: "100%",
            marginTop: 60,
          }}
        >
          <div class="col-6">
            <h5 class="title">Special Request</h5>
            <p>
              {data?.additionalInfo?.special_request || "No special request"}
            </p>
          </div>
          <div class="col-6">
            <h5 class="title">Delivery Details</h5>
            {data?.selectedTenant?.bill_to_organisation_name ? (
              <p>
                You have nominated the sample drop off location as{" "}
                {data?.selectedTenant?.bill_to_organisation_name}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          class="row"
          style={{
            width: "100%",
            marginTop: 40,
          }}
        >
          <div class="col-6">
            <ListButton
              label="Pay by Credit Card"
              size="large"
              style={{ width: "100%" }}
              onClick={handleStripeSubmit}
            />
            <p style={{ fontSize: 9, marginTop: 5 }}>
              *Merchant Surcharges apply for Credit Card Payments, these will be
              automatically added by the payment processor based on card type on
              the screen.
            </p>
          </div>
          <div class="col-6">
            {!paymentMethodAvailable && (
              <div>
                <ListButton
                  label=" Charge to Credit Account"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={handleSubmit}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-15">
        <div className="col-6 text-center">
          <button className={buttonClassName} onClick={handlePrevStep}>
            Back
          </button>
        </div>
      </div>
    </>
  );
};
