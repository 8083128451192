import React from "react";
import { formatPrice, isPrice } from "../../../ui/helpers";

export const filterResults = (data, keys, filterValue) =>
  data.filter((option) => {
    return keys.every(
      (key) => option[key] === filterValue.find((filterOption) => filterOption[key])[key]
    );
  });

export const getKeys = (filterValue) =>
  filterValue.map((item) => Object.keys(item)).reduce((acc, val) => acc.concat(val), []);

export function renderCommonTestsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0">
      {isPrice(key) ? formatPrice(item[key]) : item[key]}
    </td>
  ));
}
