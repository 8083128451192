import React from "react";
import { itemStyle } from "./constants";
import SymbioIcon from "./static/symbio.png";

export const Symbio = ({ handleConnect }) => {
  return (
    <>
      <div style={itemStyle} className="bg-white p-7 mt-10">
        <div className="text-center mb-15">
          <img src={SymbioIcon} alt="logo" style={{ height: 78 }} />
        </div>
        <h4 className="mb-2">
          <strong>Symbio</strong>
        </h4>
        <div className="mb-15">
          This integration connects the FreshTest platform with Symbio
          Laboratories.
        </div>
        <div>
          <button
            className="text-uppercase border-0 bg-transparent text-primary font-weight-bold"
            onClick={handleConnect}
          >
            Connect
          </button>
        </div>
      </div>
    </>
  );
};
