import React from "react";
import { Formik } from "formik";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { DetailsInitialValues } from "./constants";
import { TenantDetailsSchema } from "../../../helpers/schemas";
import { MappedSwitch } from "../../../ui/components/MappedSwitch";

export const TenantDetails = ({
  data = {},
  id,
  name,
  handleSubmit,
  handleClose,
}) => {
  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={id ? data : DetailsInitialValues}
        validationSchema={TenantDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? "Manage Tenant" : "Add Tenant"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="Name" name="name" data-testid="name" />
                <MappedInput
                  label="Legal Name"
                  name="legal_name"
                  data-testid="legal_name"
                />
                <MappedInput
                  label="Bill To Organisation Name"
                  name="bill_to_organisation_name"
                  data-testid="bill_to_organisation_name"
                />
                <MappedInput label="ABN" name="abn" data-testid="legal_name" />
                <MappedInput label="Phone" name="phone" data-testid="phone" />
                <MappedInput label="Email" name="email" data-testid="email" />
                <MappedInput
                  label="Address 1"
                  name="physical_address"
                  data-testid="physical_address"
                />
                <MappedInput
                  label="Address 2"
                  name="physical_address2"
                  data-testid="physical_address2"
                />
                <MappedInput
                  label="City"
                  name="physical_city"
                  data-testid="physical_city"
                />
                <MappedInput
                  label="State"
                  name="physical_state"
                  data-testid="physical_state"
                />
                <MappedInput
                  label="Postal Code"
                  name="physical_postcode"
                  data-testid="physical_postcode"
                />
              </div>
              <div className="col-4">
                <MappedInput
                  label="Delivery Details"
                  name="delivery_instruction"
                  data-testid="delivery_instruction"
                  multiline
                  rows={12}
                />

                <div className="d-flex align-items-center mt-3">
                  <p className="mt-2 col-6">Public Drop Off Location</p>
                  <MappedSwitch
                    name="public_location"
                    checked={values.public_location}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
