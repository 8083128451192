import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import Button from "@bit/the-glue.frontendcomponents.button";
import { CircularProgress } from "@material-ui/core";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Skeleton } from "@material-ui/lab";
import { ContactDetailsSchema } from "../../../helpers/schemas";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  addContact,
  inviteUser,
  modifyContact,
  linkOrganisationToContact,
  deleteContactTags,
  editContactTags,
  getContactTags,
} from "../../modules/Contacts/_api";
import { archiveUser } from "../../modules/Users/_api";
import { AutocompleteMultiple } from "../../../ui/components/AutocompleteMultiple";
import { sortHelper } from "../../../ui/helpers";
import { TagsForm } from "../../../ui/structures/TagsForm";
import { info } from "../../../helpers/toasts";
import { getTags } from "../../modules/Settings/Tags/_api";
import { Loader } from "../../../ui/components/Loader";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";

export const ContactCard = ({
  data = {},
  id,
  handleCancel,
  organiastionOptions = [],
  linkedOrganisations = [],
  organisationId,
}) => {
  const history = useHistory();
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const [loading, setLoading] = useState(false);
  const [revokeIsloading, setRevokeIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [linkedTags, setLinkedTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState([]);

  const isLinkedContact = history.location.pathname === `/organisations-list/${organisationId}`;

  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    mobile: "",
    email: "",
    position_title: "",
    organisation_id: [],
  };

  const name = [data.first_name, data.last_name].join(" ");

  useEffect(() => {
    setTagsLoading(true);
    if (id) {
      Promise.all([request(getContactTags, id), request(getTags, "type=_like(Contact)_")])
        .then(([linkedTags, tags]) => {
          linkedTags && setLinkedTags(linkedTags);
          tags && setTags(tags);
        })
        .finally(() => setTagsLoading(false));
    } else {
      request(getTags, "type=_like(Contact)_")
        .then((data) => data && setTags(tags))
        .finally(() => setTagsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createContact = (tenantAdminPayload, values, setSubmitting) => {
    setLoading(true);
    request(addContact, admins_tenant ? tenantAdminPayload : values)
      .then((data) => {
        if (!data) return;
        isLinkedContact ? handleCancel() : history.push("/organisations/contacts-list");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const updateContact = (tenantAdminPayload, values, setSubmitting) => {
    setLoading(true);
    Promise.all([
      request(modifyContact, admins_tenant ? tenantAdminPayload : values, id),
      request(
        linkOrganisationToContact,
        id,
        values.organisation_id.map((id) => ({
          id,
        }))
      ),
    ])
      .then(([contactData, contactLinkage]) => {
        if (!contactData || !contactLinkage) return;
        info("Details have been updated!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const tenantAdminPayload = { ...values, tenant_id: admins_tenant };
    id
      ? updateContact(tenantAdminPayload, values, setSubmitting)
      : createContact(tenantAdminPayload, values, setSubmitting);
  };

  const inviteUserToPortal = () => {
    setLoading(true);
    request(inviteUser, id)
      .then((data) => {
        if (!data) return;
        info(`${name} has been Invited to the portal!`);
      })
      .finally(() => setLoading(false));
  };

  const revokeAccess = () => {
    setRevokeIsLoading(true);
    if (!data.user_id) {
      info(`${name} is not invited to the portal`);
      setRevokeIsLoading(false);
    } else
      request(archiveUser, data.user_id)
        .then((data) => {
          if (!data) return;
          info("Portal access has been revoked!");
        })
        .finally(() => setRevokeIsLoading(false));
  };

  const editTagsRequest = (values) => {
    if (values.tags.length) {
      return request(
        editContactTags,
        id,
        values.tags.map((tagID) => ({ id: tagID }))
      ).then(
        (data) => data && request(getContactTags, id).then((data) => data && setLinkedTags(data))
      );
    } else {
      return request(
        deleteContactTags,
        id,
        linkedTags.map(({ id }) => ({ id }))
      ).then((data) => data && setLinkedTags([]));
    }
  };

  const handleSubmitTags = (values, { setSubmitting }) => {
    setLoading(true);
    editTagsRequest(values)
      .then((data) => {
        if (!data) return;
        info("Contact's Tags have been updated!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && <Loader title="Loading..." isOpen={loading} />}
      <Formik
        onSubmit={handleSubmit}
        initialValues={id ? { ...data, organisation_id: linkedOrganisations } : initialValues}
        validationSchema={ContactDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, ...formik }) => (
          <>
            {id && (
              <div className="my-5 d-flex col-8 justify-content-end">
                {loading ? (
                  <div className="mr-15">
                    <CircularProgress size="1.2rem" />
                  </div>
                ) : (
                  <div className="mr-3">
                    <Button
                      label="Invite User to Portal"
                      size="small"
                      text="#599D36"
                      background="light"
                      boxShadow
                      textTransform="uppercase"
                      fontWeight="600"
                      onClick={inviteUserToPortal}
                    />
                  </div>
                )}

                <AccessControl desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}>
                  {revokeIsloading ? (
                    <div className="ml-15">
                      <CircularProgress size="1.2rem" />
                    </div>
                  ) : (
                    <Button
                      label="Revoke Portal Access"
                      size="small"
                      text="#599D36"
                      background="light"
                      boxShadow
                      textTransform="uppercase"
                      fontWeight="600"
                      onClick={revokeAccess}
                    />
                  )}
                </AccessControl>
              </div>
            )}
            <h3 className="mb-5">
              <strong>{id ? name : "New Contact"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="First Name" name="first_name" data-testid="first_name" />
                <MappedInput label="Last Name" name="last_name" data-testid="last_name" />
                <MappedInput label="Phone" name="phone" data-testid="phone" />
                <MappedInput label="Mobile" name="mobile" data-testid="mobile" />
                <MappedInput label="Email" name="email" data-testid="email" />
                <MappedInput label="Position" name="position_title" data-testid="position_title" />
                {organiastionOptions.length ? (
                  <div className="mt-3 col-12">
                    <AutocompleteMultiple
                      name="organisation_id"
                      placeholder="Organisation"
                      setValue={setFieldValue}
                      options={organiastionOptions.sort(sortHelper)}
                      defaultValueField="value"
                      disabled={isLinkedContact}
                      defaultValue={linkedOrganisations}
                      variant="standard"
                    />
                    {formik.touched.organisation_id && formik.errors.organisation_id ? (
                      <div className="text-danger">{formik.errors.organisation_id}</div>
                    ) : null}
                  </div>
                ) : (
                  <div className="mt-3 col-8">
                    <Skeleton variant="rect" width={"100%"} height={55} />
                  </div>
                )}
                <div className="mt-10">
                  <ButtonGroup
                    handleClose={handleCancel}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    textTransofrm="uppercase"
                    size="large"
                  />
                </div>
              </div>
              {id &&
                (tagsLoading ? (
                  <div className="col-4">
                    <Skeleton variant="rect" width={"100%"} height={200} />
                  </div>
                ) : (
                  <TagsForm
                    id={id}
                    tags={tags}
                    linkedTags={linkedTags}
                    handleSubmit={handleSubmitTags}
                  />
                ))}
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
