import config from "react-global-configuration";
import { error } from "../../../helpers/toasts";

export const downloadFile = (
  fileName,
  getUrl = getDownloadFileUrl,
  setLoading,
  customErrorMessage,
  ...props
) => {
  return getUrl(fileName, props)
    .then((data) => data.json())
    .then((data) => {
      if (!data) return;
      if (data.data) {
        return startDownload((data.data || {}).url);
      } else throw new Error(data.message);
    })
    .catch((err) => {
      error(customErrorMessage ? customErrorMessage : (err || {}).message);
    })
    .finally(() => setLoading && setLoading(false));
};

const getDownloadFileUrl = (fileName) =>
  fetch(`${config.get("ApiUrl").Rest}/files`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ file_name: fileName }),
  });

const startDownload = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};
