const config = {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "ap-southeast-2",
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "ap-southeast-2_kCZSCYIfz",
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "7bttpngf4565e3vp2ahrjl694e",
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: "app.freshtest.com.au",
        // OPTIONAL - Cookie path
        //path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 1,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
        // CSRF
        sameSite: "lax",
      },
    },
    ApiUrl: {
      Rest: "https://api.app.freshtest.com.au",
      WebSocket: "https://api.app.freshtest.com.au",
    },
    Branding: {
      LoginBackground: {
        "app.freshtest.com.au": "/media/bg/freshtest-bg.jpg",
      },
    },
  };
  
  export default config;
  