export const HEADINGS = [
  ["code", "Code"],
  ["name", "Name"],
  ["type", "Type"],
  ["description", "Description"],
  ["show_policies", "Policies"],
];

export const initialValues = {
  name: "",
  type: "",
  description: "",
};

export const groupTypeOptions = [
  { label: "Tenant Admin", value: "tenant_admin" },
  { label: "Tenant Read", value: "tenant_read" },
  { label: "Tenant Limited", value: "tenant_limited" },
  { label: "Tenant Guest", value: "tenant_guest" },
  { label: "General", value: "general" },
  { label: "Custom", value: "custom" },
];
