import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { laboratoryHeadings } from "../constants";
import { Modal } from "../../../../../ui/components/Modal";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ListHeader } from "../../../../../ui/structures/ListHeader";
import { LaboratoriesList } from "./LaboratoriesList";
import { getTestLaboratories, unlinkLaboratories } from "../_api";
import { renderLaboratoriesRow } from "../../Laboratories/helpers";
import { getLaboratories } from "../../Laboratories/_api";
import { Loader } from "../../../../../ui/components/Loader";

export const TestLaboratories = ({ data, name, testId, setCardData }) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [laboratoriesList, setLaboratoriesList] = useState([]);
  const [linkedLaboratories, setLinkedLaboratories] = useState(data);
  const [nonLinkedLaboratories, setNonLinkedLaboratories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setSelected({});
  };

  const handleFocus = () => setFocused(true);

  useEffect(() => {
    setLoading(true);
    const arrayOfIds = data.map((item) => item.id);
    request(getLaboratories)
      .then((labs) => {
        if(!labs) return
        setNonLinkedLaboratories(labs.filter((item) => !arrayOfIds.includes(item.id)))
        setLaboratoriesList(labs)
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const arrayOfIds = linkedLaboratories.map((item) => item.id);
    setNonLinkedLaboratories(laboratoriesList.filter((item) => !arrayOfIds.includes(item.id)));
  // eslint-disable-next-line
  }, [linkedLaboratories]);

  const KEY_MAP = {
    "Organisation Name": data.map((item) => item.trading_name),
    "Phone Number": data.map((item) => item.phone),
    "Email Address": data.map((item) => item.email),
    Status: data.map((item) => item.status),
  };

  const handleSearch = (search, isRemoveKey) => {
    setCardData((state) => ({
      ...state,
      laboratories: (isRemoveKey ? linkedLaboratories : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          laboratoryHeadings.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      }),
    }));
  };

  const resetSearch = () => {
    setCardData((state) => ({
      ...state,
      laboratories: linkedLaboratories,
    }));
  };

  const updateLaboratoriesList = () => {
    setLoading(true);
    return request(getTestLaboratories, testId)
      .then((data) => {
        if (!data) return;
        setCardData((state) => ({
          ...state,
          laboratories: data,
        }));
        setLinkedLaboratories(data)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveLab = () => {
    setLoading(true);
    const payload = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({
        id: item,
      }));
    request(unlinkLaboratories, payload, testId).then((ids) => {
      if (!ids) {
        setLoading(false);
        return;
      }
      updateLaboratoriesList();
      setSelected({});
    });
  };

  const unlinkDisabled = useMemo(() => Object.values(selected).filter(Boolean).length < 1, [
    selected,
  ]);

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton label="Add" onClick={handleModalOpen} />
      </div>
      <div className="mr-5">
        <ListButton label="Remove Lab" onClick={handleRemoveLab} disabled={unlinkDisabled} />
      </div>
    </>
  );

  return (
    <>
      {loading && (
        <Loader title="Loading ..." isOpen={loading} disableBackdropClick disableEscapeKeyDown />
      )}
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleClose}
          modalContent={
            <LaboratoriesList
              handleClose={handleClose}
              data={nonLinkedLaboratories}
              testId={testId}
              setNonLinkedLaboratories={setNonLinkedLaboratories}
              setLoading={setLoading}
              updateLaboratoriesList={updateLaboratoriesList}
            />
          }
        />
      )}
      <div className="row justify-content-center bg-white py-15 px-10">
        <div className="col-12">
          <h3 className="mb-15">
            <strong>{name}</strong>
          </h3>
          <div className="mb-10">
            <SearchBar
              onSearch={handleSearch}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter Laboratories..."
              elevation={2}
              focused={focused}
              setFocused={setFocused}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
            />
          </div>
          <div>
            <ListHeader
              title="Laboratories"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <Datagrid
              data={data}
              headings={laboratoryHeadings}
              renderRow={renderLaboratoriesRow}
              selected={selected}
              setSelected={setSelected}
              selectable
            />
          </div>
        </div>
      </div>
    </>
  );
};
