import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { getUser } from "./_redux/selectors";
import { getUserById, getUserGroups } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { UserDetails } from "./UserDetails";
import { modifyUserData } from "./helpers";
import { UserPermissions } from "./UserPermissions";
import { UserSites } from "./UserSites";
import { getTenants } from "../Tenants/_api";
import { getGroups } from "../../../helpers/permissionsAPI";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getUser(id));
  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const [cardData, setCardData] = useState({});
  const [tenants, setTenants] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (!data) {
      request(getUserById, id).then((data) => data && setCardData(modifyUserData(data)));
    } else {
      setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Promise.all([request(getTenants), request(getUserGroups, id), request(getGroups)]).then(
      ([tenants, userGroups, groups]) => {
        tenants && setTenants(tenants);
        userGroups && setUserGroups(userGroups);
        groups && setGroups(groups);
      }
    );
    // eslint-disable-next-line
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  if (id && !tenants.length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const { first_name, last_name } = cardData;

  const name = [first_name, last_name].join(" ");

  const nationalAdminTabs = [
    {
      label: "Profile",
      tabPanel: <UserDetails data={cardData} name={name} id={id} />,
    },
    {
      label: "Permissions",
      tabPanel: (
        <UserPermissions
          name={name}
          id={id}
          tenants={tenants}
          userGroups={userGroups}
          groupsList={groups}
          setUserGroups={setUserGroups}
        />
      ),
    },
    {
      label: "linked Sites",
      tabPanel: <UserSites name={name} id={id} />,
    },
  ];

  const tabs = [
    {
      label: "Profile",
      tabPanel: <UserDetails data={cardData} name={name} id={id} />,
    },
    {
      label: "linked Sites",
      tabPanel: <UserSites name={name} id={id} />,
    },
  ];

  return (
    <div className="mt-10">
      <Tabs defaultActive="0" tabs={admins_tenant ? tabs : nationalAdminTabs} align="right" />
    </div>
  );
};
