import React, { useState } from "react";
import { ListButton } from "./ListButton";

export const ConfirmDelete = ({ name = "this item", handleClose, handleSubmit, id }) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <div className="pb-5">
      <div className="text-center">
        <h3 className="text-danger">Are you sure you want to archive {name} ?</h3>
      </div>
      <div className="mt-10 d-flex justify-content-end mr-4">
        <div className="mr-3">
          <ListButton
            label="Cancel"
            size="small"
            onClick={handleClose}
            data-testid="cancel"
            variant="outlined"
            text="#407A28"
            boxShadow={false}
          />
        </div>
        <div>
          <ListButton
            label="Archive"
            size="small"
            disabled={submitting}
            onClick={() => handleSubmit(id, setSubmitting)}
            data-testid="submit"
          />
        </div>
      </div>
    </div>
  );
};
