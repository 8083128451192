import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

function isPolicies(key) {
  return ["show_policies"].includes(key);
}

const ShowPolicies = (item) => {
  const history = useHistory();
  return (
    <>
      <Button data-testid="policies" onClick={() => history.push(`/groups/${item.id}`)}>
        <ArrowForwardIcon />
      </Button>
    </>
  );
};

export function renderGroupsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {isPolicies(key) ? ShowPolicies(item) : item[key]}
    </td>
  ));
}
