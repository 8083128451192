/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@bit/the-glue.frontendcomponents.button";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { handleSearchTR, renderTestsRow } from "../Tests/helpers";
import { modifyTestsArray } from "./helpers";
import {
  filterDataHelper,
  HEADINGS,
  searchMap,
  SEARCH_HELPER,
} from "./constants";
import { setData } from "./_redux/actions";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getSalesLogs,
  getSalesLogsAmount,
  getSalesLogsAutocomplete,
  markAsInvoicedbyIds,
} from "./_api";
import {
  getOrganisationsListAutocomplete,
  getSimpleOrganisations,
} from "../Organisations/_api";
import { getCrops, getCropsAutocomplete } from "../Crops/CropsManagement/_api";
import { getTests, getTestsAutocomplete } from "../Settings/Tests/_api";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { objectCheck } from "../../../ui/helpers";
import { removeTest } from "./_redux/actions";
import { uniq } from "lodash";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { ListButton } from "../../../ui/components/ListButton";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;
  const tenants = user.tenants || [];

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [testCodes, setTestCodes] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [simpleOrganisations, setSimpleOrganisations] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const hanldeModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const data = useSelector(
    ({ invoiceReconciliation: { testsList } }) => testsList
  );

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteField === "product") {
      return request(getCropsAutocomplete, 80, "name", autocompleteValue);
    } else if (autocompleteField === "test_code") {
      return request(getTestsAutocomplete, 80, "code", autocompleteValue);
    } else if (
      autocompleteField === "test_request.biller_org_id" ||
      autocompleteField === "test_request.tester_org_id"
    ) {
      return request(
        getOrganisationsListAutocomplete,
        80,
        "trading_name",
        autocompleteValue,
        "",
        "",
        "",
        "",
        admins_tenant
      );
    } else
      return request(
        getSalesLogsAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        searchFields,
        admins_tenant
      );
  };

  function fetchTestsAutocomplete(
    autocompleteValue,
    autocompleteField,
    searchFields
  ) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then(
      (data) => {
        if (data && Array.isArray(data) && data.length && objectCheck(data)) {
          setAutocompleteData(uniq(data));
        } else {
          setAutocompleteData(["No option found"]);
        }
        setAutocompleteLoading(false);
      }
    );
  }

  const fetchTestRequests = () => {
    setLoading(true);
    request(
      getSalesLogs,
      page,
      pageSize,
      search,
      field,
      field && direction,
      admins_tenant
    )
      .then((data) => {
        if (!data) return;
        setSelected({});
        dispatch(setData(modifyTestsArray(data, tenants)));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTestRequests();
  }, [search, field, direction, page, pageSize]);

  useEffect(() => {
    request(getSalesLogsAmount, search, admins_tenant).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search]);

  useEffect(() => {
    Promise.all([
      request(getCrops, "", true),
      request(getTests, "", true),
    ]).then(([crops, tests]) => {
      crops && setProductsList(crops);
      tests && setTestCodes(tests);
    });
  }, []);

  useEffect(() => {
    request(getSimpleOrganisations).then(
      (data) => data && setSimpleOrganisations(data)
    );
  }, []);

  const resetSearch = () => {
    setPage(0);
    setSearch("");
    setSelected({});
  };

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      key === "code" ? (
        <th
          key={key}
          style={sortHeaderStyle}
          className="text-nowrap"
          onClick={() => {
            setDirection(
              `test_request.${key}` !== field
                ? "desc"
                : direction === "desc"
                ? "asc"
                : "desc"
            );
            setField(`test_request.${key}`);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {`test_request.${key}` === field && (
            <TableSortLabel active direction={direction} />
          )}
        </th>
      ) : (
        <th key={key} style={sortHeaderStyle} className="text-nowrap">
          {header}
        </th>
      )
    );
  }

  const selectedIds = useMemo(
    () => Object.keys(selected).filter((item) => Boolean(selected[item])),
    [selected]
  );
  const selectedTests = useMemo(
    () => data.filter((item) => selectedIds.includes(item.id)),
    [selected]
  );

  const markInvoices = () => {
    handleModalClose();
    setActionLoading(true);

    request(markAsInvoicedbyIds, admins_tenant, {
      is_invoiced: true,
      sales_log_ids: selectedIds,
    })
      .then((data) => {
        data && selectedIds.map((id) => dispatch(removeTest(id)));
      })
      .finally(() => {
        setActionLoading(false);
        setSelected({});
      });
  };

  const handleInvoicing = () => {
    if (!selectedIds.length) return;
    if (selectedTests.some((test) => !test.laboratory_price)) {
      hanldeModalOpen();
    } else markInvoices(selectedIds);
  };

  const handleSelect = () => {
    return selectedTests
      .reduce((acc, value) => acc + value.laboratory_price, 0)
      .toFixed(2);
  };

  const handleFocus = () => setFocused(true);

  const markDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length === 0,
    [selected]
  );

  return (
    <div className="row justify-content-center mt-10">
      <Modal
        isOpen={modalOpen}
        onClose={handleModalClose}
        submitable
        maxWidth="sm"
        modalContent={
          <div className="px-10 py-3 text-center">
            <h3 className="mb-10">
              You have selected a test that does not have a cost specified. Your
              invoice total may not balance. Click OK to proceed.
            </h3>
            <ListButton
              label="OK"
              size="large"
              boxShadow={false}
              onClick={markInvoices}
            />
          </div>
        }
      />
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      <div className="col-12">
        <div>
          <ServerSearchBar
            className="mb-5"
            onSearch={(data) =>
              handleSearchTR(
                data,
                filterDataHelper,
                setSearch,
                setPage,
                simpleOrganisations,
                productsList,
                testCodes
              )
            }
            keyMap={SEARCH_HELPER}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchTestsAutocomplete}
            placeholder="Search Tests..."
            clearSearch={resetSearch}
            focused={focused}
            setFocused={setFocused}
            searchMap={searchMap}
            searchFields={search}
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader
            title="Invoice Reconciliation"
            handleFocus={handleFocus}
          />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderTestsRow}
            selected={selected}
            setSelected={setSelected}
            loading={loading}
            selectable
            headerClassName="nospaces-header"
            renderHeaderWithSorting={renderHeaderWithSorting}
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isServerPagination
            totalRecords={totalRecords}
          />
          <div className="d-flex justify-content-between mt-15">
            <h4>
              <strong>{`Selection Total : $ ${handleSelect()}`}</strong>
            </h4>
            <div>
              <Button
                label="mark selection as invoiced"
                size="small"
                text="#599D36"
                background="light"
                boxShadow
                textTransform="uppercase"
                fontWeight="600"
                onClick={handleInvoicing}
                disabled={markDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
