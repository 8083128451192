import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { getSelectedId, handleSearch, objectCheck } from "../../../ui/helpers";
import { HEADINGS, searchMap, SEARCH_HELPER } from "./constants";
import { setSitesList, updateSitesList } from "./_redux/actions";
import { renderSitesRow, modifySitesObject } from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { getSites, getSitesAmount, getSitesListAutocomplete } from "./_api";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { modifySite } from "../../submodules/Sites/_api";
import { Loader } from "../../../ui/components/Loader";
import { ListButton } from "../../../ui/components/ListButton";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [unArchiving, setUnarchiving] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState([]);

  const data = useSelector(({ sites: { sitesList } }) => sitesList);

  const fetchSites = () => {
    setLoading(true);
    request(getSites, search, archived, field, field && direction, page, pageSize)
      .then((data) => {
        if (!data) return;
        const modifiedResponse = data.map((item) => modifySitesObject(item));
        dispatch(setSitesList(modifiedResponse));
        setInitialData(modifiedResponse);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSites();
    // eslint-disable-next-line
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getSitesAmount, search, archived).then((data) => data && setTotalRecords(data));
    // eslint-disable-next-line
  }, [search, archived]);

  const fetchSitesAutocomplete = (autocompleteValue, autocompleteField, searchFields, archived) => {
    if (autocompleteValue === "") return;
    if (autocompleteField === "status") {
      setAutocompleteData(["new", "active", "inactive"]);
      return;
    }
    setAutocompleteLoading(true);
    request(
      getSitesListAutocomplete,
      80,
      autocompleteField,
      autocompleteValue,
      field,
      field && direction,
      searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  const resetSearch = () => {
    dispatch(setSitesList(initialData));
    setSearch("");
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const handleUnarchive = () => {
    const siteID = getSelectedId(selected);
    const site = data.find(({ id }) => id === siteID) || {};
    const status = site.status;
    const organisationID = site.organisation_id;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(modifySite, { status: "active" }, organisationID, siteID)
      .then((data) => {
        if (!data) return;
        dispatch(updateSitesList(data));
        setSelected({});
      })
      .finally(() => setUnarchiving(false));
  };

  const renderButtons = () => (
    <>
      {archived && (
        <ListButton label="Unarchive" disabled={unArchiveDisabled} onClick={handleUnarchive} />
      )}
    </>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <>
      {unArchiving && (
        <Loader isOpen={unArchiving} title="Loading..." disableBackdropClick disableEscapeKeyDown />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) => handleSearch(data, searchMap, setSearch, setPage)}
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchSitesAutocomplete}
              placeholder="Search Sites..."
              clearSearch={resetSearch}
              focused={focused}
              setFocused={setFocused}
              searchMap={searchMap}
              searchFields={search}
              archived={archived}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Sites" renderButtons={renderButtons} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderSitesRow}
              renderHeaderWithSorting={renderHeaderWithSorting}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
