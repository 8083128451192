import { capitalize } from "lodash";
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../ui/helpers";
import { renderInvoiceLinesRow } from "./helpers";
import { IconButton } from "@material-ui/core";

const HEADINGS = [
  ["code", "Code"],
  ["transaction_description_extra", "Test Code"],
  ["item_price", "Price"],
  ["item_quantity", "Quantity"],
  ["transaction_tax", "Tax"],
  ["transaction_total", "Total"],
];

export const InvoiceDetails = ({ name, data = {}, tenants = [], invoice_lines = [] }) => {
  const dataMap = ["invoice_date", "invoice_type", "organisation_name", "payment_status", "state"];

  const history = useHistory();

  const handleCancel = () => history.goBack();

  return (
    <>
      <div className="border border-secondary bg-white p-10">
        <div className="d-flex align-items-center mb-10">
          <div>
            <IconButton>
              <ArrowBackIcon onClick={handleCancel} />
            </IconButton>
          </div>
          <h3 className="ml-2 mt-1">{name}</h3>
        </div>
        {Object.keys(data).map((key) => {
          return (
            dataMap.includes(key) && (
              <div className="row mb-3" key={key}>
                <div className="col-2">
                  <p className="text-right text-muted">{capitalize(key.split("_").join(" "))}</p>
                </div>
                <div className={`col-9 ${key === "payment_status" && "text-warning"}`}>
                  <div>
                    {key === "invoice_date"
                      ? formatDate(new Date(data[key]))
                      : key === "invoice_type"
                      ? capitalize(data[key].split("_").join(" "))
                      : key === "payment_status"
                      ? data[key].toUpperCase()
                      : data[key]}
                  </div>
                </div>
              </div>
            )
          );
        })}
        <div className="row mb-3">
          <div className="col-2">
            <p className="text-right text-muted">State</p>
          </div>
          <div className={`col-9`}>
            <div>{(tenants.find(({ id }) => id === data.tenant_id) || {}).name}</div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <h3 className="mb-20">Invoice Lines</h3>
            <Datagrid data={invoice_lines} headings={HEADINGS} renderRow={renderInvoiceLinesRow} />
          </div>
        </div>
      </div>
    </>
  );
};
