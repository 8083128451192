import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { capitalize, get } from "lodash";
import SVG from "react-inlinesvg";
import {
  formatDate,
  isDate,
  isStatus,
  isPrice,
  formatPrice,
  isFTCertificate,
  isNATACertificate,
  hasLongContentRow,
  isTestCode,
  isColourKey,
} from "../../../ui/helpers";
import { ReactComponent as CertificateIcon } from "../../../ui/static/Certificate.svg";
import { downloadFile } from "../AWS-S3";
import { exportCertificate, exportMATACertificate } from "./_api";
import ToolTip from "../../../ui/components/Tooltip";
import { Loader } from "../../../ui/components/Loader";
import {
  AssignmentLate as AssignmentLateIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  ErrorOutline as ErrorOutlineIcon,
  AccessAlarms as AccessAlarmsIcon,
  OfflineBoltOutlined as OfflineBoltOutlinedIcon,
} from "@material-ui/icons";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const handleFTcertificate = (id, setLoading) => {
  setLoading(true);
  downloadFile("", exportCertificate, setLoading, null, id);
};

const handleNATAcertificate = (id, setLoading) => {
  setLoading(true);
  downloadFile(
    "",
    exportMATACertificate,
    setLoading,
    "Your NATA certificate is not currently available for download, please contact the FreshTest office via phone or email to request this document",
    id
  );
};

const ceritifcatesStatuses = ["Completed", "Admin Review", "Plat Review"];

const renderFTCertificate = (item, setLoading) => {
  return ceritifcatesStatuses.includes(item.status || "") ? (
    <>
      <Button
        data-testid="ft_ceritifcate"
        onClick={() => handleFTcertificate(item.id, setLoading)}
      >
        <CertificateIcon />
      </Button>
    </>
  ) : (
    "N/A"
  );
};

const renderNATACertificate = (item, setLoading) => {
  return ceritifcatesStatuses.includes(item.status || "") ? (
    <>
      <Button
        data-testid="nata_ceritifcate"
        onClick={() => handleNATAcertificate(item.id, setLoading)}
      >
        <CertificateIcon />
      </Button>
    </>
  ) : (
    "N/A"
  );
};

const renderInstructions = (item) => {
  return item.special_request ? (
    <>
      <ToolTip
        content={<ErrorOutlineIcon color="primary" />}
        title={item.special_request}
      />
    </>
  ) : null;
};

const formatLongRow = (content = "") => {
  if (content === null) return content;
  if (content.length > 11) {
    return (
      <ToolTip
        content={<div>{content.slice(0, 11) + "..."}</div>}
        title={content}
      />
    );
  } else return content;
};

const formatTestCodeRow = (content = "", category) => {
  if (category) {
    return <ToolTip content={<div>{content}</div>} title={category} />;
  } else return content;
};

export function renderTestsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0  ${
        isStatus(key) ? "text-uppercase" : ""
      } ${isFTCertificate(key) || isNATACertificate(key) ? "text-center" : ""}`}
    >
      {isOverdueResult(item, key) ? (
        renderOverdueResults(item, key)
      ) : isDate(key) ? (
        item[key] ? (
          formatDate(new Date(item[key]))
        ) : (
          "N/A"
        )
      ) : isPrice(key) ? (
        formatPrice(item[key])
      ) : isStatus(key) ? (
        item[key].split("_").join(" ")
      ) : isFTCertificate(key) ? (
        renderFTCertificate(item)
      ) : isNATACertificate(key) ? (
        renderNATACertificate(item)
      ) : hasLongContentRow(key) ? (
        formatLongRow(item[key])
      ) : isTestCode(key) ? (
        <div className="d-flex">
          {formatTestCodeRow(item[key], (item || {}).test_category)}
          {item?.is_challenging ? (
            <div className="pl-3">
              <ToolTip
                content={
                  <OfflineBoltOutlinedIcon
                    style={{ color: "#F2994A" }}
                    fontSize="small"
                  />
                }
                title={"Challenge test"}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : key === "special_request" ? (
        renderInstructions(item)
      ) : (
        item[key]
      )}
    </td>
  ));
}

const rendeColourKey = (item = {}) => {
  const iconsMap = {
    1: "/assets/icons/check.svg",
    2: "/assets/icons/alert.svg",
    3: "/assets/icons/cross.svg",
  };
  if (item.manual_verdict) {
    return (
      <span className="pr-3">
        <SVG src={toAbsoluteUrl(iconsMap[String(item.manual_verdict)])}></SVG>
      </span>
    );
  } else if (item.verdict) {
    return (
      <span className="pr-3">
        <SVG src={toAbsoluteUrl(iconsMap[String(item.verdict)])}></SVG>
      </span>
    );
  } else return null;
};

const renderOverdueResults = (item = {}, key = "") => {
  return (
    <ToolTip
      content={
        <div
          className="d-flex align-items-center pt-1"
          style={{ color: "#FFA90D" }}
        >
          <span className="pr-1">
            <AccessAlarmsIcon />
          </span>{" "}
          {item["due_date"] ? formatDate(new Date(item["due_date"])) : "N/A"}
        </div>
      }
      title={`Your results are due on ${formatDate(
        new Date(item["due_date"])
      )}, please check back soon`}
    />
  );
};

const isOverdueResult = (item = {}, key = "") => {
  return key === "result_date" && !item[key] && item["due_date"];
};

const isCorrectiveAction = (key = "") => {
  return key === "car_status";
};

const rendeCorrectiveAction = (item = {}, handleCAR) => {
  if (item.car_status === "pending") {
    return (
      <div
        className="d-flex justify-content-center align-items-center pt-1"
        style={{ color: "#EF5754" }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => handleCAR(item.id)}
        >
          <AssignmentLateIcon />
        </div>
      </div>
    );
  } else if (item.car_status === "completed") {
    return (
      <div
        className="d-flex justify-content-center align-items-center pt-1"
        style={{ color: "#6EC43F" }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => handleCAR(item.id)}
        >
          <AssignmentTurnedInIcon />
        </div>
      </div>
    );
  }
};

export const R = ({ headings, item, handleCAR }) => {
  const [loading, setLoading] = useState(false);
  const handleLoadingClose = () => setLoading(false);
  return (
    <>
      {loading && (
        <Loader
          title="Downloading..."
          isOpen={loading}
          onClose={handleLoadingClose}
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      {headings.map(([key]) => (
        <td
          key={key}
          className={`align-middle no-line border-0  ${
            isStatus(key) ? "text-uppercase" : ""
          } ${
            isFTCertificate(key) || isNATACertificate(key) ? "text-center" : ""
          }`}
        >
          {isOverdueResult(item, key) ? (
            renderOverdueResults(item, key)
          ) : isDate(key) ? (
            item[key] ? (
              formatDate(new Date(item[key]))
            ) : (
              "N/A"
            )
          ) : isPrice(key) ? (
            formatPrice(item[key])
          ) : isStatus(key) ? (
            item[key].split("_").join(" ")
          ) : isFTCertificate(key) ? (
            renderFTCertificate(item, setLoading)
          ) : isNATACertificate(key) ? (
            renderNATACertificate(item, setLoading)
          ) : hasLongContentRow(key) ? (
            formatLongRow(item[key])
          ) : isTestCode(key) ? (
            <div className="d-flex">
              {formatTestCodeRow(item[key], (item || {}).test_category)}
              {item?.is_challenging ? (
                <div className="pl-3">
                  <ToolTip
                    content={
                      <OfflineBoltOutlinedIcon
                        style={{ color: "#F2994A" }}
                        fontSize="small"
                      />
                    }
                    title={"Challenge Test"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : isColourKey(key) ? (
            rendeColourKey(item)
          ) : isCorrectiveAction(key) ? (
            rendeCorrectiveAction(item, handleCAR)
          ) : (
            item[key]
          )}
        </td>
      ))}
    </>
  );
};

export function renderResultsRow(headings, item) {
  return <R headings={headings} item={item} />;
}

export function modifyTestRequestStatus(test_status) {
  if (["plat_review", "admin_review"].includes(test_status)) return "Review";

  return test_status
    .split("_")
    .map(capitalize)
    .join(" ");
}

export function modifyTestObject(test = {}, tenants = []) {
  return {
    ...test,
    bill_to: get(test, "biller_org.trading_name", ""),
    test_organisation: get(test, "tester_org.trading_name", ""),
    site: get(test, "test_site.name", ""),
    test_code: get(test, "test.code", ""),
    test_category: get(test, "test.category", ""),
    state: (tenants.find((tenant) => tenant.id === test.tenant_id) || {})
      .physical_state,
    product: get(test, "crop.name", ""),
    status: modifyTestRequestStatus(test.status),
    car_status: test?.corrective_action?.status || "inactive",
  };
}

const fieldNamesMap = {
  bill_to: "biller_org.trading_name",
  test_organisation: "tester_org.trading_name",
  site: "test_site.name",
  test_code: "test.code",
  test_category: "test.category",
  product: "crop.name",
};

export const modifyTestFieldSearch = (field) => {
  const modifiedFields = Object.keys(fieldNamesMap);
  if (modifiedFields.includes(field)) {
    return fieldNamesMap[field];
  } else return field;
};

export function modifyTestsArray(tests = [], tenants = []) {
  return tests.map((item) => modifyTestObject(item, tenants));
}

export const handleSearchTR = (
  data = {},
  filterDataHelper,
  setSearch,
  setPage,
  simpleOrganisations = [],
  productsList = [],
  testCodes = []
) => {
  const modifiedObject = {
    ...data,
    "Bill To": (
      simpleOrganisations.find(
        ({ trading_name }) => trading_name === data["Bill To"]
      ) || {}
    ).id,
    "Test Org": (
      simpleOrganisations.find(
        ({ trading_name }) => trading_name === data["Test Org"]
      ) || {}
    ).id,
    Product: (productsList.find(({ name }) => name === data["Product"]) || {})
      .id,
    "Test Code": (
      testCodes.find(({ code }) => code === data["Test Code"]) || {}
    ).id,
  };
  setSearch(
    Object.keys(modifiedObject)
      .filter((key) => modifiedObject[key])
      .map((key) => {
        if (filterDataHelper[key] && modifiedObject[key]) {
          return `${filterDataHelper[key]}=_like(${encodeURIComponent(
            modifiedObject[key]
          )})_`;
        } else return "";
      })
      .join("&")
  );
  setPage && setPage(0);
};

export const modifyColourValue = (search) => {
  if (search.includes("colour=_like(Green)")) {
    return search.replace("colour=_like(Green)_", `final_verdict=${1}`);
  }
  if (search.includes("colour=_like(Amber)")) {
    return search.replace("colour=_like(Amber)_", `final_verdict=${2}`);
  }
  if (search.includes("colour=_like(Red)")) {
    return search.replace("colour=_like(Red)_", `final_verdict=${3}`);
  }
  return search;
};
