import config from "react-global-configuration";
import { modifySearchValue } from "../helpers";

export const addAnalyte = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/analytes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getAnalytes = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/analytes?${search && "&" + search}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + modifySearchValue(field)) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getAnalyteById = (analyte_id) =>
  fetch(`${config.get("ApiUrl").Rest}/analytes/${analyte_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyAnalyte = (data, analyte_id) =>
  fetch(`${config.get("ApiUrl").Rest}/analytes/${analyte_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteAnalyte = (analyte_id) =>
  fetch(`${config.get("ApiUrl").Rest}/analytes/${analyte_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
