import { cropTypes } from "./actions";

const initialState = {
  cropsList: [],
};

export function cropsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case cropTypes.SET_DATA:
      return {
        ...state,
        cropsList: payload,
      };

    case cropTypes.REMOVE_CROP:
      return {
        ...state,
        cropsList: [...state.cropsList.filter(({ id }) => id !== payload)],
      };

    case cropTypes.UPDATE_DATA:
      const index = state.cropsList.findIndex((item) => (item || {}).id === (payload || {}).id);
      return {
        ...state,
        cropsList: [
          ...state.cropsList.slice(0, index),
          payload,
          ...state.cropsList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
