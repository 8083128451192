import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { info } from "../../../../helpers/toasts";
import { useFetch } from "../../../../hooks/fetch.hook";
import { shareAllResults, shareResult } from "../_api";
import { ConfirmShare } from "./ConfirmShare";
import { EmailForm } from "./EmailForm";

export const Share = ({
  shareType,
  handleClose,
  users = [],
  selectedResults = [],
  organisationId,
}) => {
  const { request } = useFetch();

  const [step, setStep] = useState(0);
  const [usersIds, setUsersIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNextStep = () => setStep(step + 1);

  const handleShareRequest = (id, setSubmitting) => {
    const payload = {
      role: shareType,
      user_ids: usersIds,
    };
    setLoading(true);
    request(shareResult, payload, id)
      .then((data) => {
        if (!data) return;
        handleClose();
        info("Results have been shared!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleShare = (values, { setSubmitting }) => {
    setSubmitting(true);
    const promises = selectedResults.map((id) => handleShareRequest(id, setSubmitting));
    Promise.all(promises);
  };

  const handleShareAllResults = (values, { setSubmitting }) => {
    setSubmitting(true);
    const payload = {
      role: shareType,
      user_ids: usersIds,
    };
    request(shareAllResults, payload, organisationId)
      .then((data) => {
        if (!data) return;
        handleClose();
        info("Results have been shared!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const stepsContent = [
    <EmailForm
      handleNextStep={handleNextStep}
      key={0}
      handleClose={handleClose}
      users={users}
      setUsersIds={setUsersIds}
      shareType={shareType}
      setLoading={setLoading}
      selectedResults={selectedResults}
      handleShareAllResults={handleShareAllResults}
      organisationId={organisationId}
    />,
    <ConfirmShare
      key={1}
      shareType={shareType}
      handleClose={handleClose}
      selectedResults={selectedResults}
      usersIds={usersIds}
      handleShare={handleShare}
      handleShareAllResults={handleShareAllResults}
      organisationId={organisationId}
    />,
  ];

  return (
    <div>
      {stepsContent.find((item, index) => index === step)}
      <div className="py-2">{loading && <LinearProgress />}</div>
    </div>
  );
};
