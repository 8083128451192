const type = "tests";

export const setTestsList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      testsList: payload,
    };
  },
  type,
});

export const updateTestsList = (payload) => ({
  execute: (state) => {
    const index = state.testsList.findIndex((item) => item.id === payload.id);
    const myState = [...state.testsList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      testsList: [...myState],
    };
  },
  type,
});
