import { newTestActions } from "./actions";

const initialState = {
  data: {
    customer: {
      biller_org_id: "",
      abn: "",
      legal_name: "",
      name: "",
    },
    testOrganisation: {
      tester_org_id: "",
      abn: "",
      legal_name: "",
      name: "",
      address: "",
    },
    site: {
      test_site_id: "",
      name: "",
      address: "",
    },
    product: {
      crop_id: "",
      name: "",
    },
    tests: {
      common_tests: [],
      payment_total: [],
    },
    additionalInfo: {
      sample_reference: "",
      special_request: "",
    },
    selectedTenant: {
      tenant_id: "",
    },
    selectedTestRequest: {
      test: {},
    },
  },
};

export function addNewTestReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case newTestActions.SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.key]: { ...payload.data },
        },
      };

    case newTestActions.POPULATE_DATA:
      return {
        ...state,
        data: {
          customer: {
            biller_org_id: payload.biller_org_id,
            biller_site_id: payload.biller_site_id
          },
          testOrganisation: {
            tester_org_id: payload.tester_org_id,
          },
          site: {
            test_site_id: payload.test_site_id,
          },
          product: {
            crop_id: payload.crop_id,
          },
          tests: {
            common_tests: [],
          },
          laboratory_org_id: payload.laboratory_org_id,
          additionalInfo: {
            sample_reference: payload.sample_reference,
            special_request: payload.special_request,
          },
          selectedTenant: {
            tenant_id: payload.tenant_id,
          },
          selectedTestRequest: {
            test: payload
          }
        },
      };

    case newTestActions.RESET:
      return initialState;

    default:
      return state;
  }
}
