import config from "react-global-configuration";
import { modifyAbnSearchValue } from "../../../Organisations/helpers";

export const getLaboratoryTests = (laboratory_id, field = "", direction = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${laboratory_id}/tests?include_laboratory_price=y${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addLaboratory = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations?laboratory=true`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const linkTests = (data, laboratory_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${laboratory_id}/tests`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const unlinkTests = (data, laboratory_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${laboratory_id}/tests`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getLaboratories = (
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) => {
  return fetch(
    `${config.get("ApiUrl").Rest}/organisations?${search &&
      "&" + modifyAbnSearchValue(search)}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}&laboratory=true`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getLaboratoryTestsPrices = (laboratory_id, test_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${laboratory_id}/tests/${test_id}/laboratory_prices`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addLaboratoryTestsPrice = (laboratory_id, test_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${laboratory_id}/tests/${test_id}/laboratory_prices/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyLaboratoryTestsPrice = (
  laboratory_id,
  test_id,
  price_id,
  data
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${laboratory_id}/tests/${test_id}/laboratory_prices/${price_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteLaboratoryTestsPrice = (laboratory_id, test_id, price_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${laboratory_id}/tests/${test_id}/laboratory_prices/${price_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const exportLaboratoryTestPrices = (laboratory_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${laboratory_id}/tests/export`,
    {
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  );

export const importLaboratoryTestPrices = (laboratory_id, filename) =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${laboratory_id}/tests/import`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        file_name: filename,
      }),
      mode: "cors",
      credentials: "include",
    }
  );
