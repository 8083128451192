import React from "react";
import { Link } from "react-router-dom";
import { getStatusRowStyle, isStatus } from "../../../ui/helpers";
import { get } from "lodash";

export function renderSitesRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {key === "organisation_name" || key === "name" ? (
        <Link className="text-dark" to={`/organisations-list/${item.organisation_id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function modifySitesObject(sites = {}) {
  return {
    ...sites,
    organisation_name: get(sites, "organisation.trading_name", ""),
  };
}

const fieldNamesMap = {
  organisation_name: "organisation.trading_name",
};

export const modifySiteFieldSearch = (field) => {
  const modifiedFields = Object.keys(fieldNamesMap);
  if (modifiedFields.includes(field)) {
    return fieldNamesMap[field];
  } else return field;
};
