export const HEADINGS = [
  ["id", "Tenant ID"],
  ["name", "Tenant Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
];

export const DetailsInitialValues = {
  legal_name: "",
  abn: "",
  phone: "",
  mobile: "",
  email: "",
  name: "",
  primary_contact: "",
  physical_address: "",
  physical_address2: "",
  physical_city: "",
  physical_state: "",
  physical_postcode: "",
};

export const logoMapUrl = {
  "localhost.dev.freshtest.com.au": "https://api.dev.freshtest.com.au",
  "dev.freshtest.com.au": "https://api.dev.freshtest.com.au",
  "beta.freshtest.com.au": "https://api.beta.freshtest.com.au",
  "app.freshtest.com.au": "https://api.app.freshtest.com.au",
};
