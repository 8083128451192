export const itemStyle = {
  boxShadow:
    "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)",
  width: "32%",
};

export const buttonStyle =
  "text-uppercase border-0 bg-transparent font-weight-bold";

export const MYOBaccountsTitles = [
  {
    label: "Sales Revenue",
    name: "account_uid",
  },
  {
    label: "GST",
    name: "tax_uid",
  },
  {
    label: "Payments",
    name: "payment_uid",
  },
  {
    label: "Merchant Fees",
    name: "merchant_uid",
  },
  {
    label: "Categories",
    name: "category_uid",
  },
];

export const XeroAccountsTitles = [
  {
    label: "Sales Revenue",
    name: "sales_account_code",
  },
  {
    label: "GST",
    name: "tax_type",
  },
  {
    label: "Payments",
    name: "payment_code",
  },
  {
    label: "Merchant Fees",
    name: "merchant_code",
  },
];

export const callBackUrlMap = {
  "localhost.dev.freshtest.com.au":
    "https://localhost.dev.freshtest.com.au:3000/integrations",
  "dev.freshtest.com.au": "https://dev.freshtest.com.au/integrations",
  "beta.freshtest.com.au": "https://beta.freshtest.com.au/integrations",
  "app.freshtest.com.au": "https://app.freshtest.com.au/integrations",
};

export const getXeroAuthUrl = (userId, clientId, callback, scopes) =>
  `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callback}&scope=${scopes}&state=${userId}`;

export const getMyobAuthUrl = (key, callback, scopes) =>
  `https://secure.myob.com/oauth2/account/authorize?client_id=${key}&redirect_uri=${callback}&response_type=code&scope=${scopes}`;
