import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { testCategoryOptions } from "./constants";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addTest, getTests, modifyTest } from "./_api";
import { MappedSwitch } from "../../../../ui/components/MappedSwitch";
import { removeEmpty, sortHelper } from "../../../../ui/helpers";
import { Autocomplete } from "../../../../ui/components/Autocomplete";

export const TestDetails = ({ data = {}, id, name }) => {
  const initialValues = {
    name: data.name || "",
    code: data.code || "",
    category: data.category || "",
    upgrade_to_test_id: data.upgrade_to_test_id || "",
    favourite: data.favourite || false,
    enable_summary_reporting: data.enable_summary_reporting || false,
    non_food: data.non_food || false,
  };

  const { request } = useFetch();
  const history = useHistory();

  const [testsList, setTestsList] = useState([]);
  const [testsLoading, setTestsLoading] = useState(false);

  useEffect(() => {
    setTestsLoading(true);
    request(getTests)
      .then((data) => data && setTestsList(data))
      .finally(() => setTestsLoading(false));
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      ...values,
      upgrade_to_test_id: values.upgrade_to_test_id || null,
    };
    removeEmpty(payload);
    request(id ? modifyTest : addTest, payload, id)
      .then((data) => data && history.push("/settings/tests"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  const testOptions = testsList.length
    ? testsList.map(({ code, id }) => ({ value: id, label: code }))
    : [];

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? name : "Add Test"}</strong>
            </h3>
            <div className="row">
              <div className="col-8">
                <MappedInput label="Test Name" name="name" />
                <MappedInput label="Test Code" name="code" />
                <MappedSelect
                  label="Test Category"
                  name="category"
                  options={testCategoryOptions}
                />
                {testsLoading ? (
                  <Skeleton variant="rect" width={"100%"} height={60} />
                ) : (
                  <Autocomplete
                    name="upgrade_to_test_id"
                    placeholder="Upgrade to"
                    setValue={setFieldValue}
                    options={testOptions.sort(sortHelper)}
                    defaultValueField="value"
                    defaultValue={id ? data.upgrade_to_test_id : ""}
                    variant="standard"
                  />
                )}
              </div>
              <div className="row col-8">
                <div className="d-flex col-6 align-items-center mt-3">
                  <p className="mt-2 col-6">Favourite</p>
                  <MappedSwitch name="favourite" checked={values.favourite} />
                </div>
                <div className="d-flex col-6 align-items-center mt-3">
                  <p className="mt-2 col-6">Non-Food</p>
                  <MappedSwitch name="non_food" checked={values.non_food} />
                </div>
                <div className="d-flex col-6 align-items-center mt-3">
                  <p className="mt-2 col-6">Enable Summary Reporting</p>
                  <MappedSwitch
                    name="enable_summary_reporting"
                    checked={values.enable_summary_reporting}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
