import React from "react";
import { formatDate } from "../helpers";

export const TRDetails = ({ data = {}, tenants = [] }) => {
  const {
    code,
    biller_org,
    biller_site,
    laboratory_org,
    crop,
    test,
    test_site,
    tester_org,
    invoice,
    receive_date,
    submit_date,
    dispatch_date,
    analysis_date,
    result_date,
    receipt_temp,
    storage_temp,
    sample_reference,
    special_request,
    status,
    notes,
    tags,
    tenant_id,
  } = data;

  const dateMapFirst = {
    State: (tenants.find(({ id }) => id === tenant_id) || {}).name,
    Status: status,
    "Biller Organisation": (biller_org || {}).trading_name,
    "Biller Site": (biller_site || {}).name,
    "Laboratory Organisation": (laboratory_org || {}).trading_name,
    Crop: (crop || {}).name,
    Test: (test || {}).name,
    "Test Site": (test_site || {}).name || "Site not specified",
    "Tester Organisation": (tester_org || {}).trading_name,
    "Invoice Number": (invoice || {}).code,
    "Special Request": special_request,
  };

  const dateMapSecond = {
    "Invoice Date": (invoice || {}).invoice_date && formatDate((invoice || {}).invoice_date),
    "Receive Date": receive_date && formatDate(receive_date),
    "Submit Date": submit_date && formatDate(submit_date),
    "Dispatch Date": dispatch_date && formatDate(dispatch_date),
    "Analysis Date": analysis_date && formatDate(analysis_date),
    "Result Date": result_date && formatDate(result_date),
    "Sample Reference": sample_reference,
    "Receipt Temp": receipt_temp,
    "Storage Temp": storage_temp,
    Tags: tags,
    Notes: notes,
  };
  
  return (
    <div>
      <h4 className="text-center mb-9">
        <strong>Test Details - {code}</strong>
      </h4>
      <div className="d-flex">
        <div className="w-50">
          {Object.keys(dateMapFirst).map((key) => (
            <div className="d-flex">
              <div className="col-3">
                <p style={{ color: "#517687" }}>{key}:</p>
              </div>
              <div className="col-9">
                <span className="text-dark">{dateMapFirst[key]}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="w-50">
          {Object.keys(dateMapSecond).map((key) => (
            <div className="d-flex">
              <div className="col-3">
                <p style={{ color: "#517687" }}>{key}:</p>
              </div>
              <div className="col-9">
                <span className="text-dark">{dateMapSecond[key]}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
