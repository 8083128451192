import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getInvoice } from "./_redux/selectors";
import { useFetch } from "../../../hooks/fetch.hook";
import { getInvoiceById } from "./_api";
import { InvoiceDetails } from "./InvoiceDetails";
import { modifyInvoiceData } from "./helpers";
import { getTenants } from "../Tenants/_api";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const data = useSelector(getInvoice(id));

  const [cardData, setCardData] = useState({});
  const [tenants, setTenants] = useState([]);

  const { code, invoice_lines } = cardData || "";

  useEffect(() => {
    if (!data) {
      request(getInvoiceById, id).then((data) => data && setCardData(modifyInvoiceData(data)));
    } else setCardData(modifyInvoiceData(data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    request(getTenants).then((data) => data && setTenants(data));
    // eslint-disable-next-line
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <InvoiceDetails
      name={code}
      data={cardData}
      id={id}
      tenants={tenants}
      invoice_lines={invoice_lines}
    />
  );
};
