import { toast } from "react-toastify";

const COMMON_CLASSNAME = "p-5 rounded";

export function info(message, props) {
  return toast.info(message, {
    ...props,
    className: `bg-info ${COMMON_CLASSNAME}`,
  });
}

export function success(message, props) {
  return toast.info(message, {
    ...props,
    className: `${COMMON_CLASSNAME}`,
  });
}
export function error(message, props) {
  return toast.error(message, {
    ...props,
    className: `bg-danger ${COMMON_CLASSNAME}`,
  });
}

export function warning(message, props) {
  return toast.warning(message, {
    ...props,
    className: `bg-warning ${COMMON_CLASSNAME}`,
  });
}

export function dark(message, props) {
  return toast.warning(message, {
    ...props,
    className: `bg-dark ${COMMON_CLASSNAME}`,
  });
}

export function light(message, props) {
  return toast.warning(message, {
    ...props,
    className: `bg-light ${COMMON_CLASSNAME}`,
  });
}
