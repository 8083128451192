/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import LensIcon from "@material-ui/icons/Lens";
import { capitalize, get } from "lodash";
import { formatLimit, isStatus, objectIsEmpty } from "../../../ui/helpers";
import { Tooltip } from "@material-ui/core";
import Input from "@bit/the-glue.frontendcomponents.input";

const green = "#1bcf4b";
const amber = "#f2e018";
const red = "#eb132c";

const getStatusColor = (item = {}) => {
  const MRL = item.MRL > item.MRL_APVMA ? item.MRL_APVMA : item.MRL;
  const LOR = item.LOR;
  let result = (item.ResultValue || "").replace(`<`, "");
  if (item.AnalyteID === "AN0000760") return green;
  if (item.AnalyteID === "AN0000826" && !MRL) return amber;
  if ((item.ResultValue || "").includes("<") && Number(MRL) === 0) return green;
  if (result !== "0" && Number(MRL) === 0) return red;
  if (item.type === "Heavy Metal" && MRL === "") return green;
  if (result === "ND" && LOR === "ND" && MRL === "D") return green;
  if (
    (result === "D" || typeof result === "number") &&
    LOR === "ND" &&
    MRL === "D"
  )
    return red;
  result = result === "ND" ? 0 : Number(result);
  if ((item.ResultValue || "").includes("<") && result === Number(LOR))
    return green;
  const percentLimit = ((result / Number(MRL)) * 100).toFixed();
  if (!MRL && MRL !== 0) return amber;
  if (typeof result !== "number") return red;
  if (percentLimit < 80) return green;
  else if (percentLimit > 100) return red;
  else return amber;
};

const renderLimitPercent = (item = {}) => {
  let result = (item.ResultValue || "").replace(`<`, "");
  const MRL = item.MRL > item.MRL_APVMA ? item.MRL_APVMA : item.MRL;
  const LOR = item.LOR;
  if (item.AnalyteID === "AN0000760") return "";
  if ((item.ResultValue || "").includes("<") && Number(MRL) === 0)
    return formatLimit(0);
  if (result !== "0" && Number(MRL) === 0) return ">100%";
  if (result === "ND" && LOR === "ND" && MRL === "D") return formatLimit(0);
  if (
    (result === "D" || typeof result === "number") &&
    LOR === "ND" &&
    MRL === "D"
  )
    return formatLimit(100);
  result = result === "ND" ? 0 : Number(result);
  if ((item.ResultValue || "").includes("<") && result === Number(LOR))
    return formatLimit(0);
  if (MRL !== 0 && !MRL) return;
  const value = result / Number(MRL);
  return value === 0 || value ? formatLimit(value * 100) : "Can't calculate";
};

const renderTooltipRow = (field, name = "") => {
  return field ? (
    <div style={{ fontSize: "9px" }}>
      {name}: {field}
    </div>
  ) : (
    ""
  );
};

const renderResicheckResult = (item, resicheck = {}) => {
  return (
    <Tooltip
      title={
        <div style={{ fontSize: 12 }}>
          {renderTooltipRow(
            resicheck.matched_commodity_name,
            "Matched Commodity name"
          )}
          {renderTooltipRow(item.suggested_mrl, "Suggested MRL")}
          {!objectIsEmpty(resicheck.fsanz22_crop_commodity) ? (
            <p style={{ margin: "15px 0 4px 0" }}>fsanz22 Crop Commodity</p>
          ) : (
            ""
          )}
          {renderTooltipRow(
            resicheck.fsanz22_crop_commodity?.commodity_name,
            "Commodity Name"
          )}
          {renderTooltipRow(
            resicheck.fsanz22_crop_commodity?.commodity_subgroup,
            "Commodity Subgroup"
          )}
          {renderTooltipRow(
            resicheck.fsanz22_crop_commodity?.commodity_group,
            "Commodity Group"
          )}
          {renderTooltipRow(
            resicheck.fsanz22_crop_commodity?.commodity_class,
            "Commodity Class"
          )}
          {!objectIsEmpty(resicheck.analyte_residue_limits) ? (
            <p style={{ margin: "15px 0 4px 0" }}>Analyte Residue Limits</p>
          ) : (
            ""
          )}
          {renderTooltipRow(
            resicheck.analyte_residue_limits?.nice_name,
            "Nice Name"
          )}
          {renderTooltipRow(resicheck.analyte_residue_limits?.see, "See")}
          {renderTooltipRow(
            resicheck.analyte_residue_limits?.see_also,
            "See Also"
          )}
          {renderTooltipRow(
            resicheck.analyte_residue_limits?.original_analyte,
            "Original Analyte"
          )}
          {resicheck.analyte_residue_limits?.crops ? (
            <p style={{ margin: "15px 0 4px 0" }}>Crops</p>
          ) : (
            " "
          )}
          {resicheck.analyte_residue_limits?.crops
            ? Object.entries(
                resicheck.analyte_residue_limits?.crops
              ).map(([key, value]) => renderTooltipRow(value, key))
            : ""}
        </div>
      }
      arrow
      placement="top"
    >
      <div>{resicheck.mrl ? resicheck.mrl : item.suggested_mrl}</div>
    </Tooltip>
  );
};

const RenderEditableCell = ({
  item = {},
  editableCellName,
  data = [],
  setAnalytesData,
}) => {
  const [editMode, setEditMode] = useState(true);

  const [cellValue, setCellValue] = useState(
    item[editableCellName]
      ? item[editableCellName]
      : editableCellName === "MRL"
      ? item.resicheck?.mrl
      : editableCellName === "MRL_APVMA"
      ? item.resicheck_apvma?.mrl
      : ""
  );
  const [editableItem, setEditableItem] = useState("");

  useEffect(() => {
    handleCellBlur(cellValue, item);
  }, [cellValue, setCellValue]);

  const handleCellChange = (e) => {
    // handleEditOpen(e.target.value)
    setCellValue(e.target.value);
  };

  const handleCellBlur = (value, result = {}) => {
    const index = data.findIndex(
      (item) => (item || {}).id === (result || {}).id
    );
    const modifiedResults = [
      ...data.slice(0, index),
      { ...result, [editableCellName]: value },
      ...data.slice(index + 1),
    ];
    setAnalytesData(modifiedResults);
  };

  const handleEditable = (id) => () => {
    // handleEditOpen()
    setCellValue(cellValue);
    setEditMode(true);
    setEditableItem(id);
  };

  const onBlur = (cellValue, item) => {
    setEditMode(false);
    handleCellBlur(cellValue, item);
  };

  const renderCellValue = (value = "") => {
    if (!value) return value;
    else {
      return value.length > 15 ? value.slice(0, 15) + "..." : value;
    }
  };

  const rowStyle = item[editableCellName]
    ? {
        paddingTop: "17px",
        paddingBottom: "17px",
      }
    : {
        paddingTop: "26px",
        paddingBottom: "27px",
      };

  return editMode && item.id === editableItem ? (
    <div style={{ width: "150px", overflow: "hidden", height: 37.43 }}>
      <Input
        value={cellValue}
        onChange={handleCellChange}
        onBlur={() => onBlur(cellValue, item)}
        autoFocus
      />
    </div>
  ) : (
    <div
      style={{
        ...rowStyle,
        height: 37.43,
        overflow: "hidden",
        width: "150px",
        background: "rgb(240, 240, 240)",
        borderBottom: "1px solid #333",
        padding: "14px 16px 4px",
      }}
      onClick={handleEditable(item.id)}
    >
      {renderCellValue(cellValue)}
    </div>
  );
};

export function renderAnalytesRow(headings, item, data, setAnalytesData) {
  return headings.map(([key]) =>
    isStatus(key) ? (
      <td
        key={key}
        className={`no-line border-0 px-5 status`}
        style={{ color: getStatusColor(item) }}
      >
        <LensIcon fontSize="small" className="ml-4" /> {item[key]}
      </td>
    ) : (
      <td key={key} className={`align-middle no-line border-0 px-5 py-5`}>
        {key === "limit_percent" ? (
          renderLimitPercent(item)
        ) : key === "resicheck" ? (
          !objectIsEmpty(item["resicheck"]) ? (
            renderResicheckResult(item, item["resicheck"])
          ) : (
            ""
          )
        ) : key === "resicheck_apvma" ? (
          !objectIsEmpty(item["resicheck_apvma"]) ? (
            renderResicheckResult(item, item["resicheck_apvma"])
          ) : (
            ""
          )
        ) : key === "MRL" || key === "MRL_APVMA" ? (
          <RenderEditableCell
            item={item}
            editableCellName={key}
            data={data}
            setAnalytesData={setAnalytesData}
          />
        ) : (
          item[key]
        )}
      </td>
    )
  );
}

export function modifyResultObject(test = {}, tenants = []) {
  return {
    ...test,
    bill_to: get(test, "biller_org.trading_name", ""),
    test_organisation: get(test, "tester_org.trading_name", ""),
    site: get(test, "test_site.name", ""),
    test_code: get(test, "test.code", ""),
    test_category: get(test, "test.category", ""),
    state: (tenants.find((tenant) => tenant.id === test.tenant_id) || {})
      .physical_state,
    product: get(test, "crop.name", ""),
    status: test.status
      .split("_")
      .map(capitalize)
      .join(" "),
  };
}

export function modifyResultsArray(tests = [], tenants = []) {
  return tests.map((item) => modifyResultObject(item, tenants));
}

const getRandomNumber = (max) => Math.floor(Math.random() * Math.floor(max));

export const setID = (max, data = []) => {
  let random = getRandomNumber(max);
  if (!data.includes(random)) {
    return random;
  } else return setID(max, data);
};
