import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TestsList } from "./TestsList";
import { Modal } from "../../../../ui/components/Modal";
import { objectIsEmpty } from "../../../../ui/helpers";
import { UpgradeTests } from "./UpgradeTests";

export const CommonTests = ({
  commonTests = [],
  unCommonTests = [],
  handlePrevStep,
  handleNextStep,
  setLookaupData,
}) => {
  const [selected, setSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [upgradeOpen, setUpgrradeOpen] = useState(false);
  const [upgradableTests, setUpgradableTests] = useState([]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleUpgradeOpen = () => setUpgrradeOpen(true);
  const handleUpgradeClose = () => setUpgrradeOpen(false);

  const selectedTests = useSelector(
    ({
      newTest: {
        data: {
          tests: { common_tests },
        },
      },
    }) => common_tests
  );

  useEffect(() => {
    const selectedObjects = selectedTests
      .map((id) => ({ [id]: true }))
      .reduce((acc, value) => ({ ...acc, ...value }), {});
    setSelected(selectedObjects);
    // eslint-disable-next-line
  }, []);

  const selectedIDs = Object.keys(selected).filter((item) =>
    Boolean(selected[item])
  );
  const total = (
    [...new Set([...commonTests, ...unCommonTests])].filter(({ id }) =>
      (selectedIDs || []).includes(id)
    ) || []
  ).map((test) => (test || {}).price);

  const handleSubmit = () => {
    const selectedTests = commonTests.filter(({ id }) =>
      (selectedIDs || []).includes(id)
    );
    const listIDs = [...commonTests, ...unCommonTests].map(({ id }) => id);
    const upgradableTests = selectedTests.filter(
      ({ upgrade_to_test_id, upgrade_to_test }) =>
        upgrade_to_test_id &&
        upgrade_to_test &&
        !objectIsEmpty(upgrade_to_test) &&
        listIDs.includes(upgrade_to_test_id)
    );

    if (upgradableTests.length) {
      setUpgradableTests(upgradableTests);
      handleUpgradeOpen();
    } else {
      handleNextStep(
        {
          common_tests: selectedIDs,
          tests: [
            ...new Set(
              [...commonTests, ...unCommonTests].filter(({ id }) =>
                selectedIDs.includes(id)
              )
            ),
          ],
          payment_total: (total || []).reduce((acc, value) => acc + value, 0),
        },
        "tests"
      );
    }
  };

  const handleContinue = () => {
    handleUpgradeClose();
    handleNextStep(
      {
        common_tests: selectedIDs,
        tests: [
          ...new Set(
            [...commonTests, ...unCommonTests].filter(({ id }) =>
              selectedIDs.includes(id)
            )
          ),
        ],
        payment_total: (total || []).reduce((acc, value) => acc + value, 0),
      },
      "tests"
    );
  };

  const handleUprgadeSumbit = () => {
    const upgradeToIds = upgradableTests.map(
      ({ upgrade_to_test_id }) => upgrade_to_test_id
    );
    const upgradableIds = upgradableTests.map(({ id }) => id);
    const modifySelectedIDs = [
      ...selectedIDs.filter((id) => !upgradableIds.includes(id)),
      ...upgradeToIds,
    ];
    const upgradeToList = [
      ...new Set(
        [...commonTests, ...unCommonTests].filter(({ id }) =>
          modifySelectedIDs.includes(id)
        )
      ),
    ];
    const total = upgradeToList.map((test) => (test || {}).price);
    handleNextStep(
      {
        common_tests: modifySelectedIDs,
        tests: upgradeToList,
        payment_total: (total || []).reduce((acc, value) => acc + value, 0),
      },
      "tests"
    );
  };

  const handleAddTests = () => {
    handleModalClose();
  };

  const selectedCount = Object.values(selected).filter(Boolean).length;
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        submitable
        onClose={handleModalClose}
        modalContent={
          <TestsList
            data={unCommonTests}
            commonTests={commonTests}
            selectedCount={selectedCount}
            selected={selected}
            setSelected={setSelected}
            handleSubmit={handleAddTests}
            handlePrevStep={handleModalClose}
            setLookaupData={setLookaupData}
          />
        }
      />
      <Modal
        isOpen={upgradeOpen}
        submitable
        onClose={handleUpgradeClose}
        maxWidth="sm"
        modalContent={
          <UpgradeTests
            uprgadableTests={upgradableTests}
            handleContinue={handleContinue}
            handleSubmit={handleUprgadeSumbit}
          />
        }
      />
      <TestsList
        data={commonTests}
        selectedCount={selectedCount}
        selected={selected}
        setSelected={setSelected}
        handleSubmit={handleSubmit}
        handlePrevStep={handlePrevStep}
        handleModalOpen={handleModalOpen}
        isFavorite
        setLookaupData={setLookaupData}
        hideSelect
      />
    </div>
  );
};
