export const cropTypes = {
  SET_DATA: "CROPS/SET_DATA",
  REMOVE_CONTACT: "CROPS/REMOVE_CROP",
  UPDATE_DATA: "CROPS/UPDATE_DATA",
};

export function setCropsList(data) {
  return {
    type: cropTypes.SET_DATA,
    payload: data,
  };
}

export function removeCrop(data) {
  return {
    type: cropTypes.REMOVE_CONTACT,
    payload: data,
  };
}

export function updateCropsList(data) {
  return {
    type: cropTypes.UPDATE_DATA,
    payload: data,
  };
}