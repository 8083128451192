import * as Yup from "yup";

export const OrganisationDetailsSchema = Yup.object({
  trading_name: Yup.string().required("Organisation Name is a required field"),
  legal_name: Yup.string().required("Legal Name is a required field"),
  phone: Yup.string()
    .required("Phone Number is a required field")
    .min(9)
    .max(13)
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  mobile: Yup.string()
    .required("Mobile is a required field")
    .min(9)
    .max(13)
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email Address is a required field")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  physical_address: Yup.string().required(
    "Physical Address is a required field"
  ),
  physical_address2: Yup.string(),
  physical_state: Yup.string().required("Physical State is a required field"),
  physical_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Postcode must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  postal_address: Yup.string().required("Postal Address is a required field"),
  postal_address2: Yup.string(),
  postal_state: Yup.string().required("Postal State is a required field"),
  postal_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Postcode must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  ABN: Yup.string()
    .required()
    .matches(/(^[0-9 ]+$)/, "Must be 11 digits"),
  status: Yup.string().required("Status is required"),
});

export const ContactDetailsSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  phone: Yup.string()
    .required("Phone Number is a required field")
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  mobile: Yup.string().matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  organisation_id: Yup.array().required("Organisation is a required field"),
});

export const SiteCardSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone is required"),
  site_email: Yup.string()
    .required("Site Email is required")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  status: Yup.string().required("Status is required"),
  physical_postcode: Yup.string()
    .required("Post Code is required")
    .length(4)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
});

export const userProfileSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is required")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is required")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  phone_number: Yup.string()
    .required("Phone Number is required")
    .min(9, "Phone number must be at least 9 characters")
    .max(13, "Phone number must be at most 13 characters")
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
});

export const UserPasswordSchema = Yup.object({
  old_password: Yup.string().required("Current Password is required"),
  new_password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{14,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 14 characters, one uppercase, one number and one special case character"
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .when("new_password", {
      is: (new_password) =>
        new_password && new_password.length > 0 ? true : false,
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Password doesn't match"
      ),
    }),
});

export const confirmPasswordSchema = Yup.object({
  code: Yup.string().required("Code is required"),
  new_password: Yup.string().required("New Password is required"),
});

export const customerLookupSchema = Yup.object({
  biller_org_id: Yup.string().required("Please select a customer"),
});

export const testOrgLookupSchema = Yup.object({
  tester_org_id: Yup.string().required("Please select a testing organisation"),
});

export const siteLookupSchema = Yup.object({
  test_site_id: Yup.string().required("Please select a site"),
});

export const productLookupSchema = Yup.object({
  crop_id: Yup.string().required("Please select a product"),
});

export const requestCallSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone_number: Yup.string().required("Phone Number is required"),
  message: Yup.string().required("Message is required"),
});

export const CropDetailsSchema = Yup.object({
  name: Yup.string().required("Common Name is a required field"),
});

export const CorrectiveActionSchema = Yup.object({
  person_raising: Yup.string().required("Person is a required field"),
  problem_details: Yup.string().required("Problem is a required field"),
  fix_short_term: Yup.string().required("Short term fix is a required field"),
  person_responsible_short_term: Yup.string().required(
    "People is a required field"
  ),
  date_completed_short_term: Yup.string().required(
    "Short term date is a required field"
  ),
  fix_long_term: Yup.string().required("Long term fix is a required field"),
  person_responsible_long_term: Yup.string().required(
    "Person is a required field"
  ),
  date_completed_long_term: Yup.string().required(
    "Long term date is a required field"
  ),
  person_completed_by: Yup.string().required(
    "Completed by is a required field"
  ),
});

export const TagsDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  type: Yup.string().required("Type is a required field"),
});

export const PriceListSchema = Yup.object({
  price_list_id: Yup.string().required("Price List is required"),
  sales_price: Yup.number()
    .required("Sell Price is required")
    .typeError("Must be a number"),
  test_id: Yup.string().required("Test is required"),
  start_date: Yup.string().required("Start Date is required"),
});

export const TenantDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  legal_name: Yup.string().required("Legal Name is a required field"),
  bill_to_organisation_name: Yup.string().required(
    "Bill to Organisation Name is a required field"
  ),
  phone: Yup.string().matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is a required field")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  physical_address: Yup.string().required(
    "Physical Address is a required field"
  ),
  physical_state: Yup.string().required("Physical State is a required field"),
  physical_postcode: Yup.string()
    .required("Post Code is a required field")
    .length(4)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
});

export const AnalyteDetailsSchema = Yup.object({
  name: Yup.string().required("Analyte Name is a required field"),
  reporting_unit: Yup.string().required(
    "Limit of Reporting Value is a required field"
  ),
  reporting_value: Yup.string().required("Reporting Unit is a required field"),
});

export const testRequestStatusSchema = Yup.object({
  status: Yup.string().required("Status is a required field"),
  date: Yup.string().required("Date is a required field"),
});

export const statusColourSchema = Yup.object({
  manual_verdict: Yup.string().required("Required field"),
});

export const editLimitSchema = Yup.object({
  MRL: Yup.string()
    .required("Limit is a required field")
    .matches(/(^[0-9+.ND/]+$)/, "Restricted characters"),
});

export const groupDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  type: Yup.string().required("Type is a required field"),
  description: Yup.string().required("Description is a required field"),
});

export const policyDetailsSchema = Yup.object({
  action: Yup.string().required("Action is a required field"),
  effect: Yup.string().required("Effect is a required field"),
  policy_pattern: Yup.string().required("Policy Pattern is a required field"),
});

export const clientDetailsSchema = Yup.object({
  client_id: Yup.string().required("Client ID is a required field"),
  client_secret: Yup.string().required("Client Secret is a required field"),
});

export const crsKeyFormSchema = Yup.object({
  api_key: Yup.string().required("API Key is a required field"),
});

export const priceListSchema = Yup.object({
  name: Yup.string().required("Price List Name is a required field"),
});

export const tenantSelectSchema = Yup.object({
  tenant_id: Yup.string().required("State is a required field"),
});

export const laborPriceListSchema = (findDuplicate) =>
  Yup.object({
    tenant_id: Yup.string()
      .required("State is a required field")
      .test(
        "State duplicate",
        "Cost is already exists in this state",
        (value) => value !== findDuplicate(value)
      ),
    laboratory_price: Yup.string()
      .matches(/^[0-9]*(\.[0-9]{0,2})?$/, "Only 0.00 format is allowed")
      .required("Laboratory price is a required field"),
  });

export const shareEmailSchema = (findEmail) =>
  Yup.object({
    email: Yup.string()
      .required("Email is a required field")
      .email("Must be a valid email")
      .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters")
      .test(
        "Email duplicate",
        "Email does not exist in the system!",
        (value) => value === findEmail(value)
      ),
  });

export const inviteUserSchema = Yup.object({
  email_to_invite: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F -']+$)/,
      "Only alphabet characters are allowed"
    ),
});

export const shareCertificateSchema = Yup.object({
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
});
