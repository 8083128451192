const type = "tenants";

export const setTenantsList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      tenantsList: payload,
    };
  },
  type,
});