import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { siteSearchMap, sitesHeadings, SITE_SEARCH_HELPER } from "./constants";
import { renderSitesRow } from "../../submodules/Sites/helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  removeUserLinks,
  getUserSites,
  getUserSitesAutocomplete,
  getUserSitesAmount,
} from "./_api";
import { handleSearch, objectCheck } from "../../../ui/helpers";
import { uniq } from "lodash";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";

export const UserSites = ({ name, id }) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [sites, setSites] = useState([]);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleFocus = () => setFocused(true);

  const fetchSites = () => {
    setLoading(true);
    request(getUserSites, id, search, page, pageSize)
      .then((data) => data && setSites(data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSites();
    // eslint-disable-next-line
  }, [search, page, pageSize]);

  useEffect(() => {
    request(getUserSitesAmount, id, search).then((data) => data && setTotalRecords(data));
    // eslint-disable-next-line
  }, [search]);

  const fetchSitesAutocomplete = (autocompleteValue, autocompleteField, searchFields) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getUserSitesAutocomplete,
      id,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  const resetSearch = () => {
    setSearch("");
  };

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton label="Remove Links" disabled={removeDisabled} onClick={handleRemoveLinks} />
      </div>
    </>
  );

  const removeDisabled = useMemo(() => Object.values(selected).filter(Boolean).length === 0, [
    selected,
  ]);

  const handleRemoveLinks = () => {
    const selectedIds = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({ id: item }));
    const idsForFilter = selectedIds.map(({ id }) => id);
    request(removeUserLinks, selectedIds, id).then((res) => {
      if (!res) return;
      setSites(sites.filter(({ id }) => !idsForFilter.includes(id)));
      setSelected({});
    });
  };

  return (
    <div className="row justify-content-center bg-white py-15 px-10">
      <div className="col-12">
        <h3 className="mb-15">
          <strong>{name}</strong>
        </h3>
        <div className="mb-10">
          <ServerSearchBar
            className="mb-5"
            onSearch={(data) => handleSearch(data, siteSearchMap, setSearch, setPage)}
            keyMap={SITE_SEARCH_HELPER}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchSitesAutocomplete}
            placeholder="Search Sites..."
            clearSearch={resetSearch}
            focused={focused}
            setFocused={setFocused}
            searchMap={siteSearchMap}
            searchFields={search}
          />
        </div>
        <div>
          <ListHeader title="Sites" renderButtons={renderButtons} handleFocus={handleFocus} />
          <Datagrid
            loading={loading}
            data={sites}
            headings={sitesHeadings}
            renderRow={renderSitesRow}
            selected={selected}
            setSelected={setSelected}
            selectable
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isServerPagination
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
};
