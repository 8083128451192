import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ListButton } from "../../../../ui/components/ListButton";
import { TenantPaymentSettings } from "./TenantPaymentSettings";
import { useFetch } from "../../../../hooks/fetch.hook";
import { linkOrganisationToTenants, modifyOrganisationPaymentMethods } from "../_api";
import { Loader } from "../../../../ui/components/Loader";

export const PaymentSettings = ({
  organisation_id,
  tenants = [],
  paymentMethods = [],
  fetchPaymentMethod,
}) => {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    let promises = [];

    const modifiedValues = Object.keys(values)
      .filter((key) => values[key])
      .map((key) => Number(key.split("_")[0]));

    const assignedPaymentMethods = paymentMethods.map(({ tenant_id }) => tenant_id);

    const modifiedTenantsAssigned = tenants.filter(
      ({ id }) => modifiedValues.includes(id) && assignedPaymentMethods.includes(id)
    );

    const modifiedTenantsUnassigned = tenants.filter(
      ({ id }) => modifiedValues.includes(id) && !assignedPaymentMethods.includes(id)
    );
    modifiedTenantsAssigned.length &&
      modifiedTenantsAssigned.forEach(({ id }) => {
        promises.push({
          tenant_id: id,
          payment_method: values[`${id}_payment_method`],
          account_number: values[`${id}_account_number`],
        });
      });

    modifiedTenantsUnassigned.length &&
      modifiedTenantsUnassigned.forEach(({ id }) => {
        promises.push({
          tenant_id: id,
          payment_method: values[`${id}_payment_method`],
          account_number: values[`${id}_account_number`],
        });
      });

    if (modifiedTenantsUnassigned.length) {
      request(linkOrganisationToTenants, organisation_id, [
        ...modifiedTenantsUnassigned.map(({ id }) => ({ id })),
        ...assignedPaymentMethods.map((id) => ({ id })),
      ])
        .then((data) => {
          if (!data) {
            setSubmitting(false);
            setLoading(false);
            return;
          }
          Promise.all([
            ...promises.map((payload) =>
              request(modifyOrganisationPaymentMethods, payload, organisation_id)
            ),
          ]).finally(() => {
            setSubmitting(false);
            setLoading(false);
          });
        })
        .then(() => {
          fetchPaymentMethod();
        });
    } else {
      Promise.all([
        ...promises.map((payload) =>
          request(modifyOrganisationPaymentMethods, payload, organisation_id)
        ),
      ])
        .then(() => {
          fetchPaymentMethod();
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    }
  };

  const linkedTenants = paymentMethods
    .map(({ tenant_id, payment_method, account_number }) => {
      return {
        [`${tenant_id}_account_number`]: account_number,
        [`${tenant_id}_payment_method`]: payment_method,
      };
    })
    .reduce((acc, value) => ({ ...acc, ...value }), {});

  const methodTenantIds = paymentMethods.map(({ tenant_id }) => tenant_id);

  const unLinkedTenants = tenants
    .filter(({ id }) => !methodTenantIds.includes(id))
    .map(({ id }) => {
      return {
        [`${id}_account_number`]: "",
        [`${id}_payment_method`]: "",
      };
    })
    .reduce((acc, value) => ({ ...acc, ...value }), {});

  const initialValues = {
    ...unLinkedTenants,
    ...linkedTenants,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object(
        tenants
          .map(({ tenant_id }) => {
            return {
              [`${tenant_id}_account_number`]: Yup.string().when(`${tenant_id}_payment_method`, {
                is: (value) => value === "credit_service" || value === "credit_account",
                then: Yup.string().required("Required field"),
                otherwise: Yup.string().strip(),
              }),
            };
          })
          .reduce((acc, value) => ({ ...acc, ...value }), {})
      )}
    >
      {({ handleSubmit, values, isSubmitting }) => (
        <>
          {loading && <Loader title="Loading..." isOpen={loading} />}
          <div className="border border-secondary bg-white p-10">
            <h3 className="mb-10">
              <strong>Payment Settings</strong>
            </h3>
            <div className="d-flex justify-content-between flex-column">
              {tenants.map(({ id, name }) => (
                <TenantPaymentSettings key={id} tenant_name={name} tenant_id={id} />
              ))}
            </div>
            <div className="text-right mt-5">
              <ListButton
                label="Save"
                size="large"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
