import React from "react";
import { LinearProgress, Dialog, DialogContent } from "@material-ui/core";

export const Loader = ({ title =  "Validating ABN...", onClose, isOpen, maxWidth = "xs", ...other }) => {
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth={true} maxWidth={maxWidth} {...other}>
      <DialogContent className="p-0 pt-10 mx-5">
        <div className="pb-10">
          <div className="mb-2">{title}</div>
          <LinearProgress />
        </div>
      </DialogContent>
    </Dialog>
  );
};
