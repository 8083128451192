/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import * as Version from "./version";
import { getBackendVersion } from "../../../../app/modules/Auth/_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { Tooltip, withStyles, Fade } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export function Footer() {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [backendVersion, setBackendVersion] = useState("");
  const frontendVersion = Version.frontendVersion
    ? Version.frontendVersion
    : "";

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  const fetchBackendVersion = () => {
    setLoading(true);
    request(getBackendVersion)
      .then((data) => {
        if (!data) return;
        setBackendVersion(
          `Last Backend Build ${data.build_time} [${data.commit_id}]`
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className={`footer py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
      style={{ background: "#ffffff" }}
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-end`}
      >
        <div className="order-1 order-md-2">
          <HtmlTooltip
            title={
              <div>
                {loading ? "Last Backend Build ... loading" : backendVersion}
                <br />
                <br />
                {frontendVersion}
              </div>
            }
            arrow
            interactive
            TransitionComponent={Fade}
            placement={"left"}
            enterDelay={500}
            leaveDelay={200}
            onOpen={fetchBackendVersion}
          >
            <img
              src={toAbsoluteUrl("/assets/illuminance_labs_logo.png")}
              alt="illuminance-labs"
            />
          </HtmlTooltip>
        </div>
      </div>
    </div>
  );
}
