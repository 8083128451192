import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedSwitch } from "../../../ui/components/MappedSwitch";

export const PopulateTestOrg = ({
  data,
  customer,
  setFilterOptions,
  setFilterValue,
  setSearchResults,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const passStepOneData = () => {
    const customerData = data.find(({ id }) => id === customer.biller_org_id) || {};
    setFilterValue([
      { ABN: customerData.ABN },
      { legal_name: customerData.legal_name },
      { trading_name: customerData.trading_name },
    ]);
    setFilterOptions([customerData]);
    setSearchResults([customerData]);
    setFieldValue("tester_org_id", customerData.id);
  };

  const resetStepOneData = () => {
    setFilterValue([]);
    setFilterOptions(data);
    setSearchResults([]);
    setFieldValue("tester_org_id", "");
  };

  useEffect(() => {
    values.as_customer ? passStepOneData() : resetStepOneData();
    // eslint-disable-next-line
  }, [values.as_customer]);

  return (
    <div className="d-flex align-items-center mt-3">
      <p className="mr-2 mt-3">Same as Customer</p>
      <MappedSwitch name="as_customer" checked={values.as_customer} />
    </div>
  );
};
