import React, { useState, useEffect } from "react";
import max from "date-fns/max";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { useFormikContext } from "formik";
import { addDays } from "date-fns";

export const PriceDate = ({ prices = [], tests = [] }) => {
  const { values, setFieldValue } = useFormikContext();

  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    const selectedTest = (tests.find(({ id }) => id === values.test_id) || {})
      .code;
    const filteredPrices =
      prices.filter(({ test: { code } }) => selectedTest === code) || [];
    if (filteredPrices.length) {
      const datesArray = filteredPrices.map(
        ({ end_date }) => new Date(end_date)
      );
      const latestDate = max(datesArray);
      setMinDate(addDays(latestDate, 1));
      setFieldValue("start_date", null);
      setFieldValue("end_date", null);
    } else setMinDate(null);
    // eslint-disable-next-line
  }, [values.test_id]);

  return (
    <div className="row">
      <div className="col-6">
        <MappedDatePicker
          name="start_date"
          label="Start Date"
          testId="start_date"
          minDate={minDate}
          disabled={!Boolean(values.price_list_id) || !Boolean(values.test_id)}
        />
      </div>
      <div className="col-6">
        <MappedDatePicker
          name="end_date"
          label="End Date"
          testId="end_date"
          minDate={minDate}
          disabled={!Boolean(values.price_list_id) || !Boolean(values.test_id)}
        />
      </div>
    </div>
  );
};
