import React, { useState } from "react";
import { SiteCard } from "./SiteCard";
import { SitesList } from "./SitesList";

export const Sites = ({ organisation_id }) => {
  const [component, setComponent] = useState("list");
  const [componentId, setComponentId] = useState("");
  const [sitesList, setSitesList] = useState([]);

  const getSiteById = () => sitesList && sitesList.find(({ id }) => id === componentId);

  const handleExpand = (id) => () => {
    setComponentId(id);
    setComponent("edit");
  };

  const handleNew = () => {
    setComponent("new");
  };

  const handleCancel = () => {
    setComponent("list");
  };

  const componentsMap = {
    list: (
      <SitesList
        data={sitesList}
        handleExpand={handleExpand}
        handleNew={handleNew}
        organisation_id={organisation_id}
        setData={setSitesList}
      />
    ),
    new: <SiteCard handleCancel={handleCancel} organisation_id={organisation_id} />,
    edit: (
      <SiteCard
        data={getSiteById()}
        id={componentId}
        handleCancel={handleCancel}
        organisation_id={organisation_id}
      />
    ),
  };

  return <>{componentsMap[component]}</>;
};
