import React from "react";
import { useHistory } from "react-router";
import { ListButton } from "../../ui/components/ListButton";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const StripeCancelPage = () => {
  const history = useHistory();

  const handleCancel = () => history.push("/invoices/test-invoices");

  return (
    <div className="bg-white rounded px-10 pb-20">
      <div className="containter h-100 mt-10">
        <div className="p-5 bg-white">
          <span>
            <img className="img-fluid" src={toAbsoluteUrl("/assets/icons/Logo.svg")} alt="Logo" />
          </span>
        </div>
      </div>
      <hr />
      <h4 className="text-center my-10">Canceled!</h4>
      <div className="text-center">
        <ListButton label="Back to invoices" onClick={handleCancel} size="large" />
      </div>
    </div>
  );
};
