import { Formik } from "formik";
import React from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { shareEmailSchema } from "../../../../helpers/schemas";
import Button from "@bit/the-glue.frontendcomponents.button";
import { shareAllResults, shareResult } from "../_api";
import { info } from "../../../../helpers/toasts";
import { useFetch } from "../../../../hooks/fetch.hook";

const initialValues = {
  email: "",
};

export const EmailForm = ({
  handleNextStep,
  handleClose,
  users = [],
  setUsersIds,
  shareType,
  setLoading,
  selectedResults = [],
  organisationId,
}) => {
  const { request } = useFetch();
  const handleSubmit = (values) => {
    const foundUsers = users
      .filter((user) => (user || {}).email === values.email)
      .map((user) => (user || {}).id);
    setUsersIds(foundUsers);
    handleNextStep();
  };

  const handleShareRequest = (id, setSubmitting, payload) => {
    setLoading(true);
    request(shareResult, payload, id)
      .then((data) => {
        if (!data) return;
        handleClose();
        info("Results have been shared!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const shareAllRequest = (payload, setSubmitting) => {
    setLoading(true);
    request(shareAllResults, payload, organisationId)
      .then((data) => {
        if (!data) return;
        handleClose();
        info("Results have been shared!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleShare = (values, { setSubmitting }) => {
    const foundUsers = users
      .filter((user) => (user || {}).email === values.email)
      .map((user) => (user || {}).id);

    const payload = {
      role: shareType,
      user_ids: foundUsers,
    };

    if (organisationId) {
      shareAllRequest(payload, setSubmitting);
      return;
    } else {
      const promises = selectedResults.map((id) => handleShareRequest(id, setSubmitting, payload));
      Promise.all(promises);
    }
  };

  const findEmail = (value) => {
    return (users.find((user) => (user || {}).email === value) || {}).email;
  };

  return (
    <Formik
      onSubmit={shareType === "owner" ? handleSubmit : handleShare}
      initialValues={initialValues}
      validationSchema={shareEmailSchema(findEmail)}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="p-4">
          <h4 className="mb-5">Share Results</h4>
          <p>
            Please provide the email address of the the user that you would like to share your
            results:
          </p>
          <MappedInput label="Email" name="email" />
          <div className="d-flex justify-content-end mt-10">
            <div className="mr-3">
              <Button
                label="Cancel"
                variant="outlined"
                text="#50B849"
                background="#50B849"
                textTransform="uppercase"
                fontWeight="500"
                onClick={handleClose}
                disabled={isSubmitting}
              />
            </div>
            <Button
              label="Submit"
              boxShadow
              text="white"
              background="#50B849"
              textTransform="uppercase"
              fontWeight="500"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
