import React, { useState } from "react";
import Button from "@bit/the-glue.frontendcomponents.button";

export const ConfirmShare = ({
  handleClose,
  selectedResults = [],
  usersIds,
  handleShare,
  organisationId,
  handleShareAllResults,
}) => {
  const [submitting, setSubmitting] = useState(false);
  return (
    <div>
      <div className="p-4">
        <h4 className="mb-5">Share Results</h4>
        <p>
          {organisationId
            ? "Are you absolutely sure you want to share ALL tests with selected user in the FreshTest Portal?"
            : `Are you absolutely sure you want to share ${selectedResults.length} ${
                selectedResults.length > 1 ? "tests" : "test"
              } with ${usersIds.length} ${
                usersIds.length > 1 ? "users" : "user"
              } in the FreshTest Portal?`}
        </p>
        <div className="d-flex justify-content-end mt-10">
          <div className="mr-3">
            <Button
              onClick={handleClose}
              label="Cancel"
              variant="outlined"
              text="#50B849"
              background="#50B849"
              textTransform="uppercase"
              fontWeight="500"
              disabled={submitting}
            />
          </div>
          <Button
            label="Share"
            onClick={() =>
              organisationId
                ? handleShareAllResults(null, { setSubmitting })
                : handleShare(null, { setSubmitting })
            }
            disabled={submitting}
            boxShadow
            text="white"
            background="#50B849"
            textTransform="uppercase"
            fontWeight="500"
          />
        </div>
      </div>
    </div>
  );
};
