import { capitalize } from "lodash";
import React from "react";

export function renderPoliciesRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {capitalize(item[key])}
    </td>
  ));
}
