/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { InputFile } from "../../../ui/components/InputFile";
import File from "../../../ui/structures/File";
import { modifyTenant } from "./_api";
import { ListButton } from "../../../ui/components/ListButton";
import { logoMapUrl } from "./constants";

export const TenantBranding = ({ data = {}, id, setCardData }) => {
  const { request } = useFetch();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [logoKey, setLogoKey] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const saveUrlFieldsValues = (value) => {
    setLogoKey(value);
  };

  const submitLogo = () => {
    if (logoKey) {
      setSubmitting(true);
      request(modifyTenant, { brand_logo: logoKey }, id)
        .then((data) => {
          if (!data) return;
          setCardData((state) => ({
            ...state,
            brand_logo: data.brand_logo,
          }));
        })
        .finally(() => {
          setSubmitting(false);
          setLogoKey("");
        });
    }
  };

  const updateFiles = () => "updated files...";

  return (
    <>
      <div className="border border-secondary bg-white p-10 pb-20 mb-10">
        <h3 className="mb-10">
          <strong>Branding</strong>
        </h3>
        <div className="text-center">
          {data.brand_logo ? (
            <img
              src={`${logoMapUrl[window.location.hostname]}/${data.brand_logo}`}
              alt="Brand Logo"
              className="img-fluid w-25"
            />
          ) : (
            <h4>Logo not found</h4>
          )}
        </div>
      </div>
      <div className="border border-secondary bg-white p-10">
        <InputFile
          className="rounded"
          onDrop={handleUploadFiles}
          description="You can upload only one file at a time"
        >
          {uploadedFiles.map((file) => (
            <File
              key={file.lastModified}
              senderId={id}
              senderType={"public_uploads"}
              file={file}
              saveUrlFieldsValues={saveUrlFieldsValues}
              updateFiles={updateFiles}
            />
          ))}
        </InputFile>
        <div className="text-right mt-5">
          <ListButton
            label="Save"
            size="large"
            boxShadow={false}
            onClick={submitLogo}
            disabled={submitting}
          />
        </div>
      </div>
    </>
  );
};
