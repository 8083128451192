export const AVAILABLE_ROLES = {
  NATIONAL_ADMIN: "NATIONAL_ADMIN",
  TENANT_ADMIN: "TENANT_ADMIN",
  TENANT_USER: "TENANT_USER",
  END_USER: "END_USER",
  TENANT_GUEST: "TENANT_GUEST",
};

export const sortHeaderStyle = {
  color: "#9e9e9e",
  fontWeight: 500,
  fontSize: "14px",
  paddingBottom: "1rem",
  whiteSpace: "nowrap",
  textAlign: "left",
};
