/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Skeleton from "@material-ui/lab/Skeleton";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { CustomerLookup } from "./CustomerLoockup";
import { SiteLookup } from "./SiteLookup";
import { ProductLookup } from "./ProductLoockup";
import { AdditionalInfo } from "./AdditionalInfo";
import { populateData, resetState, setData } from "./_redux/actions";
import { CommonTests } from "./CommonTests";
import { TestOrgLookup } from "./TestOrgLookup";
import { useFetch } from "../../../hooks/fetch.hook";
import { getSimpleOrganisations } from "../Organisations/_api";
import { addTestRequest } from "./_api";
import { getCrops } from "../Crops/CropsManagement/_api";
import { removeEmpty } from "../../../ui/helpers";
import { getTenants } from "../Tenants/_api";
import { isEndUser } from "../../../helpers/IsEndUser";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";
import { get } from "lodash";
import { Payment } from "./Payment,";
import { TenantSelect } from "./TenantLookup";
import { buttonClassName } from "./constants";
import { PopulateData } from "./PopulateData";
import { getTenantTestRequests, getTestRequests } from "../Tests/_api";
import { modifyTestsArray } from "../Tests/helpers";
import { getTests } from "../Settings/Tests/_api";
import { Loader } from "../../../ui/components/Loader";
import { error } from "../../../helpers/toasts";

const mockedLaboratoryIdMap = {
  "localhost.dev.freshtest.com.au": "5538ede2-7b0f-45c7-abf6-b02db54f9e41",
  "dev.freshtest.com.au": "5538ede2-7b0f-45c7-abf6-b02db54f9e41",
  "beta.freshtest.com.au": "acae3a2d-32af-40a1-b8f6-44df388c7efa",
  "app.freshtest.com.au": "0e422264-8c4e-4e36-acd8-596376993e45",
};

export const AddNewTest = () => {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const tenantUser_tenant = get(user, "permissions.is_tenant_user");
  const userTenants = get(user, "permissions.tenant", {});
  const admins_tenant = user.admins_tenant;
  const userGroups = user.groups;

  const predefinedId = history.location.id;

  const {
    data: {
      customer,
      testOrganisation,
      site,
      product,
      tests,
      additionalInfo,
      selectedTenant,
      selectedTestRequest,
    },
  } = useSelector((state) => state.newTest);

  const storedTestRequests =
    useSelector(({ tests: { testsList } }) => testsList) || [];

  const [step, setStep] = useState(0);
  const [lookupData, setLookaupData] = useState({});
  const [userOrganisations, setUserOrganisations] = useState([]);
  const [resetKey, setResetKey] = useState(false);
  const [testRequests, setTestRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [simpleOrganisations, setSimpleOrganisations] = useState([]);
  const [simpleLoading, setSimpleLoading] = useState(false);

  const modifyOrganisations = (data) => {
    return data.map((item) => {
      if (!item.ABN) return item;
      return {
        ...item,
        ABN: item.ABN.split(" ").join(""),
      };
    });
  };

  const modifyTests = (data) => {
    return data.map((item) => {
      if (!item.price) return item;
      return {
        ...item,
        price: (item.price || {}).sales_price,
      };
    });
  };

  useEffect(() => {
    Promise.all([request(getCrops), request(getTenants)]).then(
      ([crops, tenants]) => {
        if (!crops || !tenants) return;
        setLookaupData({
          tenants,
          crops,
        });
      }
    );
  }, []);

  useEffect(() => {
    setSimpleLoading(true);
    if (tenantUser_tenant !== false) {
      const key = "selectedTenant";
      const data = {
        tenant_id: tenantUser_tenant.id,
      };
      dispatch(setData({ data, key }));
      setStep(1);
    }
    request(getSimpleOrganisations)
      .then((data) => data && setSimpleOrganisations(modifyOrganisations(data)))
      .finally(() => setSimpleLoading(false));
  }, []);

  useEffect(() => {
    if (isEndUser(user, userTenants)) {
      request(getSimpleOrganisations, "user_only=true").then((data) => {
        if (!data) return;
        setUserOrganisations(modifyOrganisations(data));
        if (data.length === 1) {
          dispatch(
            setData({
              data: { biller_org_id: (data[0] || {}).id },
              key: "customer",
            })
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (predefinedId) {
      userGroups.find(({ name }) => name === "Bulk Entry") && setStep(1);
      const key = "customer";
      const data = {
        biller_org_id: predefinedId,
      };
      dispatch(setData({ data, key }));
    }
  }, []);

  useEffect(() => {
    if (admins_tenant) {
      const key = "selectedTenant";
      const data = {
        tenant_id: admins_tenant,
      };
      dispatch(setData({ data, key }));
    }
  }, []);

  useEffect(() => {
    if (!storedTestRequests.length) {
      request(
        admins_tenant ? getTenantTestRequests : getTestRequests,
        admins_tenant
      ).then((data) => data && setTestRequests(modifyTestsArray(data)));
    } else setTestRequests(storedTestRequests);
  }, []);

  const BulkEntryUser = userGroups.find(({ name }) => name === "Bulk Entry");

  const { commonTests, unCommonTests, crops, tenants } = lookupData;

  const handleNextStep = (data, key) => {
    dispatch(setData({ data, key }));
    setStep(step + 1);
  };

  const handlePrevStep = () => setStep(step - 1);

  const handleCancel = () => {
    dispatch(resetState());
    history.push("/tests-list");
  };

  const requestPayload = {
    biller_org_id: customer.biller_org_id,
    biller_site_id: customer.biller_site_id,
    tester_org_id: testOrganisation.tester_org_id,
    laboratory_org_id: mockedLaboratoryIdMap[window.location.hostname],
    test_site_id: site.test_site_id,
    crop_id: product.crop_id,
    test_id: tests.common_tests,
    sample_reference: additionalInfo.sample_reference,
    special_request: additionalInfo.special_request,
    tenant_id: selectedTenant.tenant_id,
  };

  const handleSubmit = () => {
    setLoading(true);
    removeEmpty(requestPayload);
    request(addTestRequest, requestPayload)
      .then((data) => {
        if (!data) return;
        dispatch(resetState());
        history.push(`/payment_success?invoice_id=${data[0]?.invoice_id}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStripeSubmit = () => {
    setLoading(true);

    const modifiedPayload = { ...requestPayload, stripe_payment: true };
    removeEmpty(modifiedPayload);
    request(addTestRequest, modifiedPayload)
      .then((data) => {
        if (!data) return;
        if (!data[0]?.invoice?.stripe_checkout_url) {
          error("Stripe payment url cannot be found");
          handleCancel();
          return;
        } else {
          window.location.href = data[0]?.invoice?.stripe_checkout_url;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getValidationStep = (step) => {
    let newStep = step;
    if (!admins_tenant) newStep = newStep + 1;
    if (BulkEntryUser) newStep = newStep + 1;
    return newStep;
  };

  const setFailedValidationStep = (payload) => {
    if (
      !(isEndUser(user, userTenants)
        ? userOrganisations
        : simpleOrganisations
      ).find(({ id }) => id === payload.biller_org_id)
    )
      return getValidationStep(0);
    if (!simpleOrganisations.find(({ id }) => id === payload.tester_org_id))
      return getValidationStep(1);
    if (!crops.find(({ id }) => id === payload.crop_id))
      return getValidationStep(3);
    return getValidationStep(4);
  };

  const handlePopulateData = (values) => {
    const { test } = values;
    const payload = {
      biller_org_id: test.biller_org_id,
      biller_site_id: test.biller_site_id,
      tester_org_id: test.tester_org_id,
      laboratory_org_id: mockedLaboratoryIdMap[window.location.hostname],
      test_site_id: test.test_site_id,
      crop_id: test.crop_id,
      sample_reference: test.sample_reference,
      special_request: test.special_request,
      tenant_id: test.tenant_id,
      code: test.code,
    };
    setLoading(true);
    request(
      getTests,
      `include_price=y&biller_id=${test.biller_org_id}&tenant_id=${test.tenant_id}`
    )
      .then((data) => {
        if (!data) return;
        setLookaupData((state) => ({
          ...state,
          commonTests: modifyTests(
            data.filter((item) => item.favourite && item.price)
          ),
          unCommonTests: modifyTests(
            data.filter((item) => !item.favourite && item.price)
          ),
        }));
        dispatch(populateData(payload));
        setStep(setFailedValidationStep(payload));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const CustomerLookupComponent = () => (
    <AccessControl
      desiredRoll={AVAILABLE_ROLES.END_USER}
      elseContent={
        <CustomerLookup
          data={simpleOrganisations}
          handleNextStep={handleNextStep}
          customer={customer}
          handlePrevStep={handlePrevStep}
          isStateAdmin={admins_tenant}
          key={resetKey}
          setResetKey={setResetKey}
          simpleLoading={simpleLoading}
        />
      }
    >
      <CustomerLookup
        data={userOrganisations}
        handleNextStep={handleNextStep}
        customer={customer}
        handlePrevStep={handlePrevStep}
        key={resetKey}
        setResetKey={setResetKey}
        simpleLoading={simpleLoading}
      />
    </AccessControl>
  );

  const stepsContent = [
    CustomerLookupComponent(),
    <TestOrgLookup
      data={simpleOrganisations}
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      testOrganisation={testOrganisation}
      customer={customer}
      key={resetKey}
      setResetKey={setResetKey}
      tenantId={selectedTenant.tenant_id}
      setLookaupData={setLookaupData}
    />,
    <SiteLookup
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      site={site}
      testOrganisation={testOrganisation}
    />,
    <ProductLookup
      data={crops}
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      product={product}
    />,
    <CommonTests
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      commonTests={commonTests}
      unCommonTests={unCommonTests}
      setLookaupData={setLookaupData}
    />,
    <AdditionalInfo
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      additionalInfo={additionalInfo}
      tenants={tenants}
      selectedTenant={selectedTenant}
    />,
    <Payment
      handleSubmit={handleSubmit}
      handlePrevStep={handlePrevStep}
      handleStripeSubmit={handleStripeSubmit}
    />,
  ];

  const bulkEntryData = {
    label: "Populate Data",
    icon: <SettingsIcon fontSize="large" />,
    key: "populate",
  };
  const selectState = {
    label: "Select State",
    icon: <LocationOnIcon fontSize="large" />,
    key: "selectedTenant",
  };

  const steps = [
    {
      label: "Select Customer",
      icon: <PersonAddIcon fontSize="large" />,
      key: "customer",
    },
    {
      label: "Select Testing Org",
      icon: <SettingsIcon fontSize="large" />,
      key: "tester",
    },
    {
      label: "Select Site",
      icon: <LocationOnIcon fontSize="large" />,
      key: "site",
    },
    {
      label: "Select Product",
      icon: <PersonAddIcon fontSize="large" />,
      key: "product",
    },
    {
      label: "Select Tests",
      icon: <SettingsIcon fontSize="large" />,
      key: "tests",
    },
    {
      label: "Additional Information",
      icon: <LocationOnIcon fontSize="large" />,
      key: "additional",
    },
    {
      label: "Payment",
      icon: <LocationOnIcon fontSize="large" />,
      key: "payment",
    },
  ];

  if (!Object.keys(lookupData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <>
      {loading && <Loader title="Loading..." isOpen={loading} />}
      <h3 className="mb-10">Register New Sample for Testing</h3>
      <AccessControl
        desiredRoll={AVAILABLE_ROLES.TENANT_ADMIN}
        elseContent={
          <Stepper activeStep={step} alternativeLabel>
            {(BulkEntryUser
              ? [bulkEntryData, selectState, ...steps]
              : [selectState, ...steps]
            ).map(({ label }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        }
      >
        <Stepper activeStep={step} alternativeLabel>
          {(BulkEntryUser ? [bulkEntryData, ...steps] : steps).map(
            ({ label }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          )}
        </Stepper>
      </AccessControl>
      <AccessControl
        desiredRoll={AVAILABLE_ROLES.TENANT_ADMIN}
        elseContent={
          <div className="mt-5">
            {(BulkEntryUser
              ? [
                  <PopulateData
                    data={testRequests}
                    handleSubmit={handlePopulateData}
                    handleNextStep={handleNextStep}
                    selectedTestRequest={selectedTestRequest}
                  />,
                  <TenantSelect
                    data={tenants}
                    handleNextStep={handleNextStep}
                    selectedTenant={selectedTenant}
                    handlePrevStep={handlePrevStep}
                    step={step}
                  />,
                  ...stepsContent,
                ]
              : [
                  <TenantSelect
                    data={tenants}
                    handleNextStep={handleNextStep}
                    selectedTenant={selectedTenant}
                    handlePrevStep={handlePrevStep}
                    step={step}
                  />,
                  ...stepsContent,
                ]
            ).find((item, index) => index === step)}
          </div>
        }
      >
        <div className="mt-5">
          {(BulkEntryUser
            ? [
                <PopulateData
                  data={testRequests}
                  handleSubmit={handlePopulateData}
                  handleNextStep={handleNextStep}
                  selectedTestRequest={selectedTestRequest}
                />,
                ...stepsContent,
              ]
            : stepsContent
          ).find((item, index) => index === step)}
        </div>
      </AccessControl>
      {step !== 0 && (
        <div className="row mt-8">
          <div className="col-6 text-center">
            <button className={buttonClassName} onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};
