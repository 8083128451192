import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { downloadFile } from "../AWS-S3";
import { exportInvoice } from "./_api";
import { Loader } from "../../../ui/components/Loader";
import { ReactComponent as CertificateIcon } from "../../../ui/static/Certificate.svg";

export const RenderInvoiceDownload = ({ item }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = (id) => {
    setLoading(true);
    downloadFile("", exportInvoice, setLoading, null, id);
  };

  const handleModalClose = () => setLoading(false);
  return (
    <>
      {loading && (
        <Loader
          title="Downloading PDF..."
          onClose={handleModalClose}
          isOpen={loading}
        />
      )}
      <Button data-testid="donwload" onClick={() => handleDownload(item.id)}>
        <CertificateIcon />
      </Button>
    </>
  );
};
