import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { IconButton } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../static/InfoIcon.svg";
import { ReactComponent as CloseIcon } from "../static/CloseIcon.svg";
import { makeStyles, Collapse } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#599D36",
  },
}));

export default function AlertBar({ severity = "info", message, handleClose }) {
  const classes = useStyles();
  return (
    <div>
      <Alert
        className={classes.root}
        severity={severity}
        icon={<InfoIcon />}
        variant="filled"
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      >
        <h6 className="ml-10 mt-3">{message}</h6>
      </Alert>
    </div>
  );
}

export function DevAlertBar({ severity = "info", message }) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  return (
    <div>
      <Collapse in={open}>
        <Alert
          className={classes.root}
          severity={severity}
          icon={<InfoIcon />}
          variant="filled"
          action={
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          }
        >
          <h6 className="ml-10 mt-2">{message}</h6>
        </Alert>
      </Collapse>
    </div>
  );
}

export const WarningAlertBar = ({ message }) => {
  return (
    <div>
      <Alert
        severity="warning"
        variant="filled"
        elevation={6}
        style={{ backgroundColor: "#F44336", fontSize: 14 }}
      >
        {message}
      </Alert>
    </div>
  );
};

export const IntegrationAlertBar = () => {
  return (
    <>
      {window.location.hostname === "beta.freshtest.com.au" && (
        <div className="w-100">
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            style={{ backgroundColor: "#F44336", fontSize: 14 }}
          >
            Caution - Test System
          </Alert>
        </div>
      )}
    </>
  );
};
