import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { renderRow } from "../../../../../ui/helpers";
import { buttonClassName, nexStepButtonStyle } from "../../../AddNewTest/constants";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { linkAnalytes } from "../_api";
import { analytesHeadings } from "../constants";

const HEADINGS = [
  ["code", "Analyte ID"],
  ["name", "Analyte Name"],
  ["level_of_reporting", "Limit of Reporting"],
  ["type", "Type"],
  ["group", "Group"],
  ["status", "Status"],
];

const headerStyle = {
  background: "E8F5E1",
  color: "#71BF44",
};

export const AnalytesList = ({
  data,
  handleClose,
  testId,
  setNonLinkedAnalytes,
  setLoading,
  updateAnalytesList,
}) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    setInitialData(data);
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    "Analyte ID": data.map((item) => item.code),
    "Analyte Name": data.map((item) => item.name),
    Status: data.map((item) => item.status),
  };

  const handleSearch = (search, isRemoveKey) => {
    setNonLinkedAnalytes(
      (isRemoveKey ? initialData : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          analytesHeadings.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    );
  };

  const handleSubmit = () => {
    setLoading(true)
    const payload = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({ id: item }));
    request(linkAnalytes, payload, testId).then((ids) => {
      if (!ids) return;
      updateAnalytesList("Analytes have been added!");
      handleClose();
    });
  };

  const resetSearch = () => setNonLinkedAnalytes(initialData);

  const selectedCount = Object.values(selected).filter(Boolean).length;

  return (
    <>
      <div className="bg-white rounded p-10">
        <h4 className="mb-6">
          <strong>Select Analytes</strong>
        </h4>
        <div className="mb-10">
          <SearchBar
            onSearch={handleSearch}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter Analytes..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
          />
        </div>
        <div className="border 1px solid #E5E5E5">
          <div
            className="d-flex justify-content-between p-5 align-items-center"
            style={headerStyle}
          >
            <h6>{`${
              selectedCount > 1 ? `${selectedCount} items` : `${selectedCount} item`
            } selected`}</h6>
          </div>
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderRow}
            selected={selected}
            setSelected={setSelected}
            selectable
          />
        </div>
        <div className="row mt-10">
          <div className="col-6 text-center">
            <button className={buttonClassName} onClick={handleClose}>
              Cancel
            </button>
          </div>
          <div className="col-6 text-center">
            <button className={nexStepButtonStyle} onClick={handleSubmit} disabled={!selectedCount}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
