import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import get from "lodash/get";

const filterOptions = createFilterOptions({
  matchFrom: "start",
});

export const Filter = ({
  options = [],
  tip,
  placeholder = "Select option",
  setValue,
  name,
  defaultValue,
  disabled,
  multiple,
  setMultiple,
  handleFilter,
  filterValue = [],
  setFilterValue,
  openDelay,
  radioButtonName,
  resetKey,
  isAbnField,
  ...other
}) => {
  const [inputValue, setInputValue] = useState("");
  const [fieldValue, setFieldValue] = useState(null);
  const [open, setOpen] = useState(false);

  const startFrom = openDelay ? 3 : 0;

  useEffect(() => {
    setFieldValue(options.find(({ id }) => id === defaultValue) || null);
    setInputValue(
      (options.find(({ id }) => id === defaultValue) || {})[name] || ""
    );
    // eslint-disable-next-line
  }, []);

  const handleChangeMultiple = (_, value, reason) => {
    setMultiple(value);
  };

  const handleInputChange = (event, value) => {
    let newValue;
    if (isAbnField) {
      newValue = value.replace(/\s+/g, "");
    } else newValue = value;
    setInputValue(newValue);
    if (newValue.length >= startFrom) {
      setOpen(true);
    } else setOpen(false);
  };

  const handleOpen = () => {
    if ((inputValue || "").length >= startFrom) {
      setOpen(true);
    }
  };

  const handleCHange = (_, value) => {
    setValue(name, get(value, name, ""));
    setInputValue((value || {})[name] || "");
    setFieldValue(value);
    let _filter;
    // actions when user selects option
    if (value) {
      setValue(radioButtonName, (value || {}).id);
      let obj = {};
      let key = name;
      obj = { [key]: value[name] };
      const index = filterValue.findIndex((obj) => obj[key]);
      const keys = filterValue
        .map((item) => Object.keys(item))
        .reduce((acc, val) => acc.concat(val), []);
      if (keys.includes(key)) {
        _filter = [
          ...filterValue.slice(0, index),
          obj,
          ...filterValue.slice(index + 1),
        ];
      } else _filter = [...filterValue, obj];
      setFilterValue && setFilterValue(_filter);
      handleFilter && handleFilter(_filter);
      // actions when user clears input
    } else if (!value) {
      if (filterValue.length === 1) setValue(radioButtonName, "");
      const index = filterValue.findIndex((obj) => obj[name]);
      filterValue.splice(index, 1);
      setFilterValue && setFilterValue(filterValue);
      handleFilter && handleFilter(filterValue);
    }
  };

  const getOptions = (options = []) => {
    return options.filter((option) => name in option);
  };

  return (
    <div className="mb-3">
      <MuiAutocomplete
        options={getOptions(options)}
        id={name}
        key={resetKey}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        filterOptions={filterOptions}
        multiple={multiple}
        onChange={multiple ? handleChangeMultiple : handleCHange}
        onInputChange={handleInputChange}
        getOptionLabel={(item) => String(item[name])}
        inputValue={inputValue}
        value={fieldValue}
        disabled={disabled}
        {...other}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            variant="outlined"
            color="secondary"
          />
        )}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
};
