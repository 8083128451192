import React from "react";
import { Formik } from "formik";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Chip, makeStyles } from "@material-ui/core";
import { Autocomplete } from "../../components/Autocomplete";
import { ListButton } from "../../components/ListButton";

const useStyles = makeStyles({
  root: {
    width: "100%",
    color: "white",
    overflow: "hidden",
  },
});

export const Mapping = ({
  handleClose,
  handleSubmit,
  data = {},
  taxOptions = [],
  accountsOptions = [],
  accountsTitles = [],
  categoryOptions = [],
}) => {
  const classes = useStyles();

  const { user_config } = data;

  const initialValues = () => {
    return accountsTitles
      .map(({ name }) => ({
        [name]: (user_config || {})[name],
      }))
      .reduce(
        (acc, value) => ({
          ...acc,
          ...value,
        }),
        {}
      );
  };

  const getOptions = (name) => {
    if (name === "tax_uid" || name === "tax_type") return taxOptions;
    else if (name === "category_uid") return categoryOptions;
    else return accountsOptions;
  };

  return (
    <>
      <Formik initialValues={initialValues()} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <>
            {accountsTitles.map(({ label, name }) => {
              if (name === "category_uid" && !categoryOptions.length) return null;
              else {
                return (
                  <div className="d-flex justify-content-between align-items-center mb-2" key={name}>
                    <div style={{ width: "30%" }}>
                      <Chip label={label} color="primary" classes={classes} />
                    </div>
                    <div style={{ width: "6%" }}>
                      <ArrowRightAltIcon fontSize="large" />
                    </div>
                    <div style={{ width: "64%" }}>
                      <Autocomplete
                        name={name}
                        placeholder="Select Option"
                        setValue={setFieldValue}
                        defaultValue={values[name]}
                        defaultValueField="value"
                        options={getOptions(name)}
                      />
                    </div>
                  </div>
                );
              }
            })}
            <div className="mt-10 mb-3 d-flex justify-content-end">
              <div className="mr-3">
                <ListButton label="Cancel" size="small" onClick={handleClose} data-testid="cancel" />
              </div>
              <div>
                <ListButton
                  label="Save"
                  size="small"
                  onClick={handleSubmit}
                  data-testid="submit"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
