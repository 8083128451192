import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { renderTenantsRow } from "./helpers";
import { onSearch } from "../../../ui/helpers";
import { HEADINGS } from "./constants";
import { setTenantsList } from "./_redux/actions";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTenants } from "./_api";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(true);
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);

  const data = useSelector(({ tenants: { tenantsList } }) => tenantsList);

  const fetchTenants = () => {
    setLoading(true);
    request(getTenants)
      .then((data) => {
        if (!data) return;
        dispatch(setTenantsList(data));
        setInitialData(data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTenants();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search, isRemoveKey) => {
    onSearch(search, isRemoveKey ? initialData : data, dispatch, setTenantsList, HEADINGS);
  };

  const KEY_MAP = {
    "Tenant Name": data.map((item) => item.name),
    "Phone Number": data.map((item) => item.phone),
    "Email Address": data.map((item) => item.email),
  };
  const resetSearch = () => {
    fetchTenants();
  };

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <Link
          to={{
            pathname: `/add-tenant`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
    </>
  );

  const handleFocus = () => setFocused(true);

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        <div>
          <SearchBar
            onSearch={handleSearch}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter tenants..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
            focused={focused}
            setFocused={setFocused}
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Tenants" renderButtons={renderButtons} handleFocus={handleFocus} />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderTenantsRow}
            editable
            loading={loading}
            link="tenants"
          />
        </div>
      </div>
    </div>
  );
};
