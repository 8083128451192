import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getTag } from "./_redux/selectors";
import { categoriesList, initialValues, typesList } from "./constants";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addTag, getTagById, modifyTag } from "../../Settings/Tags/_api";
import { TagsDetailsSchema } from "../../../../helpers/schemas";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const data = useSelector(getTag(id));
  const { request } = useFetch();

  const [cardData, setCardData] = useState({});

  useEffect(() => {
    if (id && !data) {
      request(getTagById, id).then((data) => data && setCardData(data));
    } else {
      setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  const handleSubmit = (values, { setSubmitting }) => {
    request(id ? modifyTag : addTag, values, id)
      .then((data) => data && history.push("/crops/tags"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={id ? cardData : initialValues}
        validationSchema={TagsDetailsSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Tag"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="Tag Name" name="name" data-testid="name" />
                <MappedSelect
                  label="Tag Category"
                  options={categoriesList}
                  name="category"
                  data-testid="category"
                />
                <MappedSelect
                  label="Tag Type"
                  options={typesList}
                  name="type"
                  disabled
                  data-testid="type"
                />
                <MappedInput
                  label="Tag Description"
                  name="description"
                  multiline
                  rows={6}
                  data-testid="description"
                />
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
