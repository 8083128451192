import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFetch } from "../../../hooks/fetch.hook";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { ConfirmMultiple } from "../../../ui/components/ConfirmMupltiple";
import { ListButton } from "../../../ui/components/ListButton";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { mergeOrganisation } from "./_api";

export const MergeOrganisationForm = ({
  organiastionOptions = [],
  handleClose,
  organisationID,
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const [selectedOrg, setSelectedOrg] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfrimClose = () => setConfirmOpen(false);
  const handleConfirmOpen = () => setConfirmOpen(true);

  const setValue = (name, value) => {
    setSelectedOrg(value);
  };

  const handleSubmit = (id, setSubmitting) => {
    setLoading(true);
    handleConfrimClose();
    request(mergeOrganisation, organisationID, { merge_to_other_id: id })
      .then((data) => {
        if (!data) return;
        handleClose();
        history.push("/organisations/organisations-list");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-white rounded p-10">
      {loading && (
        <Loader title="Merging..." isOpen={loading} disableBackdropClick disableEscapeKeyDown />
      )}
      {confirmOpen && (
        <Modal
          isOpen={confirmOpen}
          submitable
          onClose={handleConfrimClose}
          maxWidth="sm"
          modalContent={
            <ConfirmMultiple
              handleClose={handleConfrimClose}
              id={selectedOrg}
              handleSubmit={handleSubmit}
              confritmTitle="MERGE"
              warningMessage={
                <>
                  <div className="mb-4">WARNING!</div>
                  <div>
                    Merging will remove the organisation entirely from the system. It is not
                    possible to reverse this action. Are you sure you want to continue?
                  </div>
                </>
              }
            />
          }
        />
      )}
      <h4 className="text-center mb-10">Choose the organisation for merging</h4>
      <Autocomplete
        placeholder="Select Organisation"
        options={organiastionOptions}
        defaultValueField="value"
        setValue={setValue}
        defaultStyle
      />
      <div className="mt-10 d-flex justify-content-end mr-4">
        <div className="mr-3">
          <ListButton
            label="Cancel"
            size="large"
            onClick={handleClose}
            data-testid="cancel"
            variant="outlined"
            boxShadow={false}
            text="#407A28"
          />
        </div>
        <div>
          <ListButton
            label="Merge"
            size="large"
            boxShadow={false}
            data-testid="submit"
            onClick={handleConfirmOpen}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};
