/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { MoreHoriz as MoreHorizIcon } from "@material-ui/icons";
import SVG from "react-inlinesvg";
import { handleSearchTR, modifyTestsArray, R } from "../Tests/helpers";
import { objectCheck } from "../../../ui/helpers";
import { HEADINGS } from "./constants";
import { setResultsList } from "./_redux/actions";
import { Modal } from "../../../ui/components/Modal";
import { useFetch } from "../../../hooks/fetch.hook";
import { ShareScreen } from "./ShareScreen";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { filterDataHelper, searchMap, SEARCH_HELPER } from "./constants";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import {
  getResults,
  getResultsAmount,
  getResultsAutocomplete,
  getTenantResults,
} from "./_api";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";
import { getTests, getTestsAutocomplete } from "../Settings/Tests/_api";
import { getCrops, getCropsAutocomplete } from "../Crops/CropsManagement/_api";
import { getSimpleOrganisations } from "../Organisations/_api";
import { TRDetails } from "../../../ui/structures/TRDetails";
import { Share } from "./Share";
import { ShareEmail } from "./Share/ShareEmail";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { getSimpleUsers } from "../Users/_api";
import { CorrectiveActions } from "./CorrectiveActions";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;
  const tenants = user.tenants || [];

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [focused, setFocused] = useState(false);
  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [reviewedComponentID, setReviewedComponentID] = useState("");
  const [carModalOpen, setCarModalOpen] = useState(false);
  const [carComponentID, setCarComponentID] = useState("");

  const [simpleOrganisations, setSimpleOrganisations] = useState([]);
  const [testCodes, setTestCodes] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [shareType, setShareType] = useState("");
  const [shareOpen, setShareOpen] = useState(false);
  const [shareEmailOpen, setShareEmailOpen] = useState(false);
  const [usersList, setUsersList] = useState([]);

  const handleShare = (type) => {
    if (!type) return;
    setShareType(type);
    setOpenModal(false);
    if (type === "email") {
      setShareEmailOpen(true);
    } else if (type === "owner" || type === "viewer") {
      setShareOpen(true);
    }
  };

  const handleShareClose = () => setShareOpen(false);
  const handleShareEmailClose = () => setShareEmailOpen(false);
  const handleDetailsModalClose = () => setDetailsModalOpen(false);
  const handleCarModalClose = () => setCarModalOpen(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const data = useSelector(({ results: { resultsList } }) => resultsList);

  const requestResults = () => {
    if (admins_tenant) {
      return request(
        getTenantResults,
        page,
        pageSize,
        admins_tenant,
        search,
        field,
        field && direction
      );
    } else
      return request(
        getResults,
        page,
        pageSize,
        search,
        field,
        field && direction
      );
  };

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteField === "product") {
      return request(getCropsAutocomplete, 80, "name", autocompleteValue);
    } else if (autocompleteField === "test_code") {
      return request(getTestsAutocomplete, 80, "code", autocompleteValue);
    } else
      return request(
        getResultsAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        searchFields,
        "",
        admins_tenant
      );
  };

  function fetchResultsAutocomplete(
    autocompleteValue,
    autocompleteField,
    searchFields
  ) {
    if (autocompleteValue === "") return;
    if (autocompleteField === "colour") {
      setAutocompleteData(["Green", "Amber", "Red"]);
      return;
    }
    if (autocompleteField === "car_status") {
      setAutocompleteData(["Completed", "Pending"]);
      return;
    }
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then(
      (data) => {
        if (data && Array.isArray(data) && data.length && objectCheck(data)) {
          setAutocompleteData(uniq(data));
        } else {
          setAutocompleteData(["No option found"]);
        }
        setAutocompleteLoading(false);
      }
    );
  }

  const fetchResults = () => {
    setLoading(true);
    requestResults()
      .then((data) => {
        if (!data) return;
        dispatch(setResultsList(modifyTestsArray(data, tenants)));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchResults();
  }, [page, pageSize, search, field, direction]);

  useEffect(() => {
    request(getResultsAmount, admins_tenant, search).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search]);

  useEffect(() => {
    Promise.all([
      request(getCrops, "", true),
      request(getTests, "", true),
    ]).then(([crops, tests]) => {
      crops && setProductsList(crops);
      tests && setTestCodes(tests);
    });
  }, []);

  useEffect(() => {
    request(getSimpleOrganisations).then(
      (data) => data && setSimpleOrganisations(data)
    );
  }, []);

  // preload users for share function email duplicate check
  useEffect(() => {
    request(getSimpleUsers).then((data) => data && setUsersList(data));
  }, []);

  const resetSearch = () => {
    setSearch("");
    setPage(0);
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      key === "ft_cert" || key === "nata_cert" ? (
        <th key={key} style={sortHeaderStyle} className="text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="text-nowrap"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  const handleExpand = (id) => () => {
    setDetailsModalOpen(true);
    setReviewedComponentID(id);
  };

  const handleCAR = () => (id) => {
    setCarComponentID(id);
    setCarModalOpen(true);
  };

  const selectedResults = useMemo(() => {
    return Object.keys(selected).filter((key) => selected[key]);
  }, [selected]);

  const shareDisabled =
    selectedResults.length === 0 || selectedResults.length > 6;

  const shareLabel =
    selectedResults.length > 1 ? "Share multiple tests" : "Share";

  const renderButtons = () => (
    <>
      <ListButton
        label={shareLabel}
        className="py-3 px-12"
        background="#50B849"
        disabled={shareDisabled}
        onClick={handleOpenModal}
      />
    </>
  );

  function renderResultsRow(headings, item) {
    return <R headings={headings} item={item} handleCAR={handleCAR()} />;
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        submitable
        modalContent={
          <ShareScreen
            handleClose={handleCloseModal}
            selectedResults={selectedResults}
            handleShare={handleShare}
          />
        }
      />
      <Modal
        maxWidth="lg"
        isOpen={detailsModalOpen}
        onClose={handleDetailsModalClose}
        modalContent={
          <TRDetails
            data={data.find(({ id }) => id === reviewedComponentID)}
            tenants={tenants}
          />
        }
      />
      <Modal
        maxWidth="lg"
        isOpen={carModalOpen}
        organisations={simpleOrganisations}
        submitable
        modalContent={
          <CorrectiveActions
            data={data.find(({ id }) => id === carComponentID)}
            handleClose={handleCarModalClose}
            fetchResults={fetchResults}
          />
        }
      />
      <Modal
        maxWidth="sm"
        isOpen={shareEmailOpen}
        onClose={handleShareEmailClose}
        submitable
        disableBackdropClick
        disableEscapeKeyDown
        modalContent={
          <ShareEmail
            handleClose={handleShareEmailClose}
            selectedResults={selectedResults}
          />
        }
      />
      <Modal
        maxWidth="sm"
        isOpen={shareOpen}
        onClose={handleShareClose}
        submitable
        disableBackdropClick
        disableEscapeKeyDown
        modalContent={
          <Share
            shareType={shareType}
            handleClose={handleShareClose}
            users={usersList}
            selectedResults={selectedResults}
          />
        }
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearchTR(
                  data,
                  filterDataHelper,
                  setSearch,
                  setPage,
                  simpleOrganisations,
                  productsList,
                  testCodes
                )
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchResultsAutocomplete}
              fetchFunction={fetchResults}
              placeholder="Search Tests..."
              clearSearch={resetSearch}
              focused={focused}
              setFocused={setFocused}
              searchMap={searchMap}
              searchFields={search}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader
              title="Results"
              handleFocus={handleFocus}
              renderButtons={renderButtons}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderResultsRow}
              selectable
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              headerClassName="nospaces-header"
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              expandable
              handleExpand={handleExpand}
              expandHeader="Details"
              expandIcon={<MoreHorizIcon />}
            />
            <div className="d-flex">
              <div className="mr-10">
                <SVG src={toAbsoluteUrl("/assets/icons/check.svg")}></SVG>
                <span
                  className="ml-3"
                  style={{ color: "#50B849", cursor: "default" }}
                >
                  OK
                </span>
              </div>
              <div className="mr-10">
                <SVG src={toAbsoluteUrl("/assets/icons/cross.svg")}></SVG>
                <span
                  className="ml-3"
                  style={{ color: "#EB5757", cursor: "default" }}
                >
                  ADVERSE
                </span>
              </div>
              <div className="mr-10">
                <SVG src={toAbsoluteUrl("/assets/icons/alert.svg")}></SVG>
                <span
                  className="ml-3"
                  style={{ color: "#F2994A", cursor: "default" }}
                >
                  FOR REVIEW
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
