import React, { useState } from "react";
import Button from "@bit/the-glue.frontendcomponents.button";
import { ButtonGroup } from "@material-ui/core";

export const ShareScreen = ({ handleClose, handleShare }) => {
  const [selected, setSelected] = useState("");

  const selectedDescription = {
    viewer: (
      <p className="font-weight-bold">
        {`
        Caution: Adding viewer to your tests grants the user you invite permanent access to all results of the selected organisation in the FreshTest portal including the ability to download a copy of your
        FreshTest Certificate in PDF form.`}
      </p>
    ),
    owner: (
      <p className="text-danger">
        <strong>
          {`Caution: Adding an owner to your tests grants user you invite permanent access to all results of the selected organisation in the FreshTest portal including the ability to download a copy of your FreshTest Certificate in PDF form and for that user to share your tests with other FreshTest users now and in the future`}
        </strong>
      </p>
    ),
  };

  const handleSelectType = (e) => {
    setSelected(e.target.value);
  };
  return (
    <div className="bg-white p-10" style={{ fontSize: "16px" }}>
      <h3>
        <strong>Share Results</strong>
      </h3>
      <p>
        Sharing your results with others can be convenient but you should consider your choices on
        this screen carefully.
      </p>
      <p className="mt-8">Please select from one of the following sharing options: </p>
      <div onClick={handleSelectType}>
        <ButtonGroup>
          <Button
            label="Viewer"
            value="viewer"
            boxShadow
            textTransform="uppercase"
            fontWeight="500"
            fontSize="12px"
            background={selected === "viewer" ? "#50B849" : "#E8E9EB"}
            text={selected === "viewer" ? "white" : "#3F434A"}
            variant={selected === "viewer" ? "contained" : "ontlined"}
          />
          <Button
            label="Owner"
            value="owner"
            boxShadow
            textTransform="uppercase"
            fontWeight="500"
            fontSize="12px"
            background={selected === "owner" ? "#50B849" : "#E8E9EB"}
            text={selected === "owner" ? "white" : "#3F434A"}
            variant={selected === "owner" ? "contained" : "ontlined"}
          />
        </ButtonGroup>
      </div>
      <div className="mt-8">{selectedDescription[selected]}</div>
      <div className="d-flex justify-content-end">
        <Button
          label="Cancel"
          variant="outlined"
          text="#50B849"
          background="#50B849"
          textTransform="uppercase"
          fontWeight="500"
          boxShadow
          className="mr-5"
          onClick={handleClose}
        />
        <Button
          label="Share"
          boxShadow
          text="white"
          background="#50B849"
          textTransform="uppercase"
          fontWeight="500"
          onClick={() => handleShare(selected)}
        />
      </div>
    </div>
  );
};
