import React, { useState, useEffect } from "react";
import { ListButton } from "../../../ui/components/ListButton";
import { buttonStyle, callBackUrlMap, itemStyle, MYOBaccountsTitles } from "./constants";
import { Modal } from "../../../ui/components/Modal";
import { SuccessModal } from "./SuccessModal";
import { Mapping } from "../../../ui/structures/Mapping";
import MYOBIcon from "./static/myob.png";
import { Loader } from "../../../ui/components/Loader";
import { useFetch } from "../../../hooks/fetch.hook";
import { useHistory } from "react-router";
import { getCurrentIntegration } from "./helpers";
import { getIntegrationConfig, getIntegrationToken, setIntegrationConfig } from "./_api";
import { AccountField } from "../../../ui/structures/Mapping/AccountField";

export const Myob = ({
  handleConnect,
  handleDisconnect,
  connectedComponent,
  setConnectedComponent,
  tokenExpired = false,
  loading,
  setLoading,
  loaderTitle = "Connecting...",
  user = {},
  integrations,
  fulfillUser,
  currentIntegration = {},
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const [configurationsOpen, setConfigurationsOpen] = useState(false);
  const [connectedModalOpen, setConnectedModalOpen] = useState(false);
  const [integrationData, setIntegrationData] = useState({});
  const [accountID, setAccountID] = useState("");
  const [resetKey, setResetKey] = useState(Date.now());

  const handleCloseConfiguration = () => {
    setConfigurationsOpen(false);
    setLoading(false);
  };
  const handleOpenConfiguration = () => {
    setConnectedModalOpen(false);
    setConfigurationsOpen(true);
  };

  const handleCloseConnectedModal = () => setConnectedModalOpen(false);

  const integrationDisabled = connectedComponent && connectedComponent !== "myob";
  const connected = connectedComponent === "myob";

  function fetchCurrentIntegrationConfig() {
    request(getIntegrationConfig, currentIntegration.id)
      .then((data) => data && setIntegrationData(data))
      .finally(() => setLoading(false));
  }

  const handleAccountChange = (values) => {
    setLoading(true);
    const payload = {
      user_config: {
        user_account_id: values.user_account_id || "",
      },
    };
    setAccountID(values.user_account_id);
    request(setIntegrationConfig, payload, currentIntegration.id)
      .then((data) => {
        if (!data) return;
        setIntegrationData(data);
        setResetKey(Date.now());
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitConfig = (values, { setSubmitting }) => {
    setLoading(true);
    const account = accountID || integrationData.user_config.user_account_id;
    const payload = {
      user_config: { ...values, user_account_id: account },
    };
    request(setIntegrationConfig, payload, currentIntegration.id)
      .then((data) => {
        if (!data) return;
        setIntegrationData(data);
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
        handleCloseConfiguration();
      });
  };

  const getUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    return code;
  };

  useEffect(() => {
    const integration = localStorage.getItem("integration");
    if (integration !== "myob") return;
    const tenant_id = user.admins_tenant ? user.admins_tenant : null;
    const code = getUrlParams();
    const xeroConnected = getCurrentIntegration(integrations, tenant_id, "xero");
    if (code && !xeroConnected) {
      const payload = {
        integration_type: "myob",
        code: code,
        callback_url: callBackUrlMap[window.location.hostname],
        tenant_id: user.admins_tenant ? user.admins_tenant : null,
      };
      setLoading(true);
      request(getIntegrationToken, payload).then((data) => {
        if (!data) {
          setLoading(false);
          return;
        }
        fulfillUser({ ...user, integrations: [...user.integrations, data] });
        setLoading(false);
        history.push("/integrations");
        setConnectedComponent("myob");
        setConnectedModalOpen(true);
      });
    } else return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!connected) return;
    setLoading(true);
    fetchCurrentIntegrationConfig();
    // eslint-disable-next-line
  }, [connectedComponent]);

  const sortHelper = (a, b) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const userAccountsOptions = (integrationData.user_accounts || [])
    .map(({ Name, Id }) => ({
      value: Id,
      label: Name,
    }))
    .sort(sortHelper);

  const accountsOptions = (integrationData.accounts || [])
    .map(({ Name, UID }) => ({
      value: UID,
      label: Name,
    }))
    .sort(sortHelper);

  const taxOptions = (integrationData.tax_types || [])
    .map(({ Code, UID }) => ({
      value: UID,
      label: Code,
    }))
    .sort(sortHelper);

    const categoryOptions = (integrationData.categories || [])
      .map(({ Name, UID }) => ({
        value: UID,
        label: Name,
      }))
      .sort(sortHelper);

  return (
    <>
      {configurationsOpen && (
        <Modal
          isOpen={configurationsOpen}
          submitable
          maxWidth="sm"
          onClose={handleCloseConfiguration}
          modalContent={
            <div className="bg-white px-5 pb-5">
              <div className="text-center mb-15">
                <h2 className="mb-3">{`Configure Account Mappings for MYOB`} </h2>
                <p>Choose which account each line item is mapped to. </p>
              </div>
              <AccountField
                userAccountsOptions={userAccountsOptions}
                handleSubmit={handleAccountChange}
                data={integrationData}
              />
              <Mapping
                handleClose={handleCloseConfiguration}
                handleSubmit={handleSubmitConfig}
                componentLabel="MYOB"
                data={integrationData}
                accountsOptions={accountsOptions}
                taxOptions={taxOptions}
                key={resetKey}
                accountsTitles={MYOBaccountsTitles}
                categoryOptions={categoryOptions}
              />
            </div>
          }
        />
      )}
      {connectedModalOpen && (
        <Modal
          isOpen={connectedModalOpen}
          submitable
          maxWidth="sm"
          onClose={handleCloseConnectedModal}
          modalContent={
            <SuccessModal
              handleClose={handleCloseConnectedModal}
              componentLabel="MYOB"
              handleConfiguration={handleOpenConfiguration}
            />
          }
        />
      )}

      {loading && <Loader title={loaderTitle} isOpen={loading} />}

      <div style={itemStyle} className={`${integrationDisabled ? "bg-secondary" : "bg-white"} p-7 mt-10 mr-5`}>
        <div className="text-center mb-15">
          <img src={MYOBIcon} alt="logo" style={{ height: 78 }} />
        </div>
        <div className="mb-2 flex align-items-center" style={{ display: "flex", flexWrap: "wrap" }}>
          <h4 className="m-0">
            <strong>MYOB</strong>
          </h4>
          {connected && (
            <div
              style={{
                display: "inline-block",
                background: "rgb(113, 191, 68)",
                color: "#fff",
                padding: "1px 5px",
                borderRadius: "0.22rem",
                marginLeft: "auto",
                fontSize: 11,
              }}
            >
              CONNECTED
            </div>
          )}
        </div>
        <div className={`mb-15`}>
          <p>Add you Finance and Accounting Platform for even greater visibility of your operations.</p>
        </div>
        <div>
          {tokenExpired ? (
            <button className={`${buttonStyle} text-primary`} onClick={handleConnect}>
              Reconnect MYOB
            </button>
          ) : connected ? (
            <div className="d-flex justify-content-between align-items-center">
              <ListButton label="Configure MYOB" size="small" onClick={handleOpenConfiguration} />
              <button className={`${buttonStyle} text-danger`} onClick={handleDisconnect}>
                Disconnect
              </button>
            </div>
          ) : (
            <button className={`${buttonStyle} text-primary`} onClick={handleConnect} disabled={integrationDisabled}>
              Connect
            </button>
          )}
        </div>
      </div>
    </>
  );
};
