import React from "react";
import { ListButton } from "../../../ui/components/ListButton";

export const SuccessModal = ({ handleClose, componentLabel, handleConfiguration }) => {
  return (
    <div className="text-center py-10">
      <h2 className="mr-2 mb-10 text-primary">Succesful connection!</h2>
      <ListButton
        label={`Configure your ${componentLabel} Integration`}
        size="large"
        onClick={handleConfiguration}
      />
      <div className="mt-2">
        <span
          onClick={handleClose}
          className="text-muted btn"
        >{`Configure ${componentLabel} Later`}</span>
      </div>
    </div>
  );
};
