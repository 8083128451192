import React, {useMemo} from "react";
import { useField } from "formik";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@material-ui/core";

export function Checkbox({
 label,
 error,
 inputClassName,
 labelPlacement,
 ...other
}) {
  return (
    <FormControlLabel
      control={<MuiCheckbox className={inputClassName} {...other} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
}

export function MappedCheckbox(props) {
  const [field, meta] = useField(props.name);
  const getInputClasses = useMemo(() => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }
    if (meta.touched && !meta.error) {
      return "is-valid";
    }
    return "";
  }, [meta]);

  return (
    <Checkbox
      {...field}
      {...props}
      error={meta.error}
      inputClassName={getInputClasses}
    />
  );
}
