/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Calendar from "@bit/the-glue.frontendcomponents.calendar";
import { ColumnChart } from "../../../ui/components/ColumnChart";
import { StatusTile } from "../../../ui/structures/StatusTile/index";
import { formatDate } from "../../../ui/helpers";
import { ReactComponent as ClientsIcon } from "../../../ui/static/Clients.svg";
import { ReactComponent as SamplesIcon } from "../../../ui/static/Samples.svg";
import { ReactComponent as AwaitingIcon } from "../../../ui/static/Awaiting.svg";
import { ReactComponent as OverdueIcon } from "../../../ui/static/Overdue.svg";
import { CustomerList } from "../../../ui/structures/CustomersList";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getDashboardClients,
  getTenantDashBoardClients,
  getTenantTestsReports,
  getTestsReports,
} from "../Organisations/_api/index";
import {
  getTestRequests,
  getOverdueTestRequests,
  getTenantTestRequests,
  getNationalTestVolumes,
  getNationalDailyTestVolumes,
  getStateDailyTestVolumes,
  getStateTestVolumes,
  getTenantOverdueTestRequests,
  getTestsAmount,
} from "../Tests/_api";
import Skeleton from "@material-ui/lab/Skeleton";
import { uniq } from "lodash";
import "./StyleFixes.css";

const args = {
  type: "minimal",
  horizontal: false,
  categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  color: "#71BF44",
  titleColor: "#263238",
  title: "Daily Test Volumes",
  titlePosition: "center",
  height: "250px",
};

const salesByStateArgs = {
  type: "grouped",
  horizontal: true,
  categories: [],
  color: "#71BF44",
  secondBarColor: "#FECA0A",
  titleColor: "#263238",
  title: "Sales by State YTD (This Year vs Last Year)",
  titlePosition: "left",
};

const stateVolumesArgs = {
  type: "grouped",
  horizontal: true,
  categories: [],
  color: "#FECA0A",
  secondBarColor: "#71BF44",
  thirdBarColor: "#00AEEF",
  forthBarColor: "#df379a",
  fifthBarColor: "#5632a8",
  sixthBarColor: "#eb1313",
  seventhBarColor: "#7d7070",
  titleColor: "#263238",
  title: "Test Volumes by State",
  titlePosition: "left",
};

const tilesMap = [
  {
    title: "Clients",
    icon: <ClientsIcon />,
    path: "/organisations/organisations-list",
  },
  {
    title: "Incoming Samples",
    icon: <SamplesIcon />,
    path: "/tests-list",
    status: "submitted",
  },
  {
    title: "Awaiting Results",
    icon: <AwaitingIcon />,
    path: "/tests-list",
    status: "pending_results",
  },
  {
    title: "Overdue Results",
    icon: <OverdueIcon />,
    path: "/tests-list",
    status: "overdue",
  },
];

export const Dashboard = () => {
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const [topCustomers, setTopCustomers] = useState([]);
  const [samples, setSamples] = useState("");
  const [results, setResults] = useState("");
  const [overdueResults, setOverdueResults] = useState("");
  const [clients, setClients] = useState("");
  const [stateVolumes, setStateVolumes] = useState([]);
  const [dailyVolumes, setDailyVolumes] = useState({});
  const [salesByState, setSalesByState] = useState([]);

  const getDaysOrder = (dailyVolumes = {}) => {
    if (!dailyVolumes) return;
    const lastWeekTestsCount = Object.keys(dailyVolumes).map((key) => {
      const dateParts = key.split("/");
      const formatedDate = new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`);
      return formatedDate.toString().split(" ")[0];
    });
    return lastWeekTestsCount;
  };

  useEffect(() => {
    Promise.all([
      request(() =>
        admins_tenant
          ? getTenantTestRequests(admins_tenant, ["submitted"])
          : getTestRequests(["submitted"])
      ),
      request(() =>
        admins_tenant
          ? getTenantTestRequests(admins_tenant, ["pending_results"])
          : getTestRequests(["pending_results"])
      ),
      request(getTestsAmount, admins_tenant, "", true),
    ]).then(([samples, results, overdueResults]) => {
      setSamples(samples);
      setResults(results);
      setOverdueResults(overdueResults);
    });
  }, []);

  useEffect(() => {
    request(() =>
      admins_tenant ? getTenantDashBoardClients(admins_tenant) : getDashboardClients()
    ).then((clients) => clients && setClients(clients));
  }, []);

  useEffect(() => {
    request(
      admins_tenant ? getTenantTestsReports : getTestsReports,
      "top5_org_sales",
      admins_tenant
    ).then((topCustomers) => topCustomers && setTopCustomers(topCustomers));
  }, []);

  useEffect(() => {
    Promise.all([
      request(admins_tenant ? getStateTestVolumes : getNationalTestVolumes, admins_tenant),
      request(
        admins_tenant ? getStateDailyTestVolumes : getNationalDailyTestVolumes,
        admins_tenant
      ),
      request(
        admins_tenant ? getTenantTestsReports : getTestsReports,
        "state_sales",
        admins_tenant
      ),
    ]).then(([stateVolumes, dailyVolumes, salesByState]) => {
      stateVolumes && setStateVolumes(stateVolumes);
      dailyVolumes && setDailyVolumes(dailyVolumes);
      salesByState && setSalesByState(salesByState);
    });
  }, []);

  const quantityMap = {
    Clients: clients,
    "Awaiting Results": results,
    "Incoming Samples": samples,
    "Overdue Results": overdueResults,
  };

  const getStateVolumesSeries = () => {
    const testNames =
      uniq(
        Object.values(stateVolumes)
          .map((stateObject) => Object.keys(stateObject))
          .reduce((acc, value) => [...acc, ...value], [])
      ) || [];
    return testNames.map((name) => ({
      name,
      data: Object.values(stateVolumes)
        .map((stateObject) => stateObject[name])
        .filter(Boolean),
    }));
  };

  const getSalesByStateTenants = () => {
    const tenants = salesByState.map(({ tenant }) => (tenant || {}).name);
    return tenants;
  };

  const getSalesByStateSeries = () => {
    const lastYearValues = salesByState.map(({ last_year }) => last_year);
    const currentYearValues = salesByState.map(({ this_year }) => this_year);
    return [
      {
        name: "This Year",
        data: currentYearValues,
      },
      {
        name: "Last Year",
        data: lastYearValues,
      },
    ];
  };

  return (
    <div>
      <div className="date">{formatDate(new Date(Date.now()))}</div>
      <div className="d-flex justify-content-between dashboard-wrapper">
        <div className="first-column">
          <div className="d-flex justify-content-between mb-10">
            {tilesMap.map(({ title, icon, path, status }) => (
              <StatusTile
                quantity={quantityMap[title]}
                title={title}
                icon={icon}
                key={title}
                path={path}
                status={status}
              />
            ))}
          </div>
          <div className="bg-white p-5">
            {Object.keys(stateVolumes).length ? (
              <ColumnChart
                options={{ ...stateVolumesArgs, categories: Object.keys(stateVolumes) }}
                series={getStateVolumesSeries()}
                id="state"
                height="302"
              />
            ) : (
              <Skeleton variant="rect" width={"100%"} height={302} />
            )}
          </div>
        </div>
        <div className="second-column">
          <div className="calendar">
            <Calendar />
          </div>
          <div className="bg-white p-5 big-daily">
            {Object.keys(dailyVolumes).length ? (
              <ColumnChart
                options={{ ...args, categories: getDaysOrder(dailyVolumes) }}
                series={[
                  {
                    name: "Daily Test Volumes",
                    data: Object.values(dailyVolumes),
                  },
                ]}
                id="daily"
              />
            ) : (
              <Skeleton variant="rect" width={"100%"} height={250} />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex mt-10 justify-content-between bottom-wrapper">
        <div className="customers">
          <CustomerList data={topCustomers} title="Top 5 Customers" />
        </div>
        <div className="bg-white p-5 small-daily">
          {Object.keys(dailyVolumes).length ? (
            <ColumnChart
              options={args}
              series={[
                {
                  name: "Daily Test Volumes",
                  data: Object.values(dailyVolumes),
                },
              ]}
              id="daily"
            />
          ) : (
            <Skeleton variant="rect" width={"100%"} height={250} />
          )}
        </div>
        <div className="bg-white px-5 pt-5 sales">
          {salesByState.length ? (
            <ColumnChart
              options={{ ...salesByStateArgs, categories: getSalesByStateTenants() }}
              series={getSalesByStateSeries()}
              id="sales"
              height="307"
            />
          ) : (
            <>
              <div style={{ fontSize: "14px" }}>Sales by State YTD (This Year vs Last Year)</div>
              <div
                style={{ height: "307px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <p>
                  <strong>No data found</strong>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
