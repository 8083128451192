import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { priceListSchema } from "../../helpers/schemas";
import { MappedCheckbox } from "../components/Checkbox";

export const ManagePricelists = ({
  handleSubmit,
  onClose,
  data = {},
  priceListModalContent,
  stateOptions,
  currentListStates = [],
}) => {
  const initialValues = {
    name: (data && priceListModalContent === "edit" && data.name) || "",
    states:
      (data && priceListModalContent === "edit" && currentListStates) || [],
    is_master_price_list:
      (data && priceListModalContent === "edit" && data.is_master_price_list) ||
      false,
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={priceListSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <h4 className="mb-10 text-center">
            <strong>Create Price List</strong>
          </h4>
          <div className="d-flex">
            <div className="w-100">
              <MappedInput label="Price List Name" name="name" />
              {!values.is_master_price_list && (
                <MappedSelect
                  label="Applies to"
                  name="states"
                  data-testid="states"
                  options={stateOptions}
                  multiple
                />
              )}
              <MappedCheckbox
                name="is_master_price_list"
                checked={values.is_master_price_list}
                label="Is Master Price List"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-15 mb-5">
            <button onClick={onClose} className="btn btn-white mr-3">
              <strong>Cancel</strong>
            </button>
            <button
              onClick={handleSubmit}
              className=" py-3 px-5 text-uppercase text-primary bg-primary text-white border border-primary rounded"
              disabled={isSubmitting}
              type="submit"
            >
              <strong>
                {priceListModalContent === "new" ? "Create" : "Modify"}
              </strong>
            </button>
          </div>
        </>
      )}
    </Formik>
  );
};
