import { invoiceTypes } from "./actions";

const initialState = {
  invoicesList: [],
};

export function invoicesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case invoiceTypes.SET_DATA:
      return {
        ...state,
        invoicesList: payload,
      };

    case invoiceTypes.REMOVE_DATA:
      return {
        ...state,
        invoicesList: [...state.invoicesList.filter(({ id }) => id !== payload)],
      };

    case invoiceTypes.UPDATE_DATA:
      const index = state.invoicesList.findIndex((item) => (item || {}).id === (payload || {}).id);
      return {
        ...state,
        invoicesList: [
          ...state.invoicesList.slice(0, index),
          payload,
          ...state.invoicesList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
