import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getTag } from "./_redux/selectors";
import { categoriesList } from "./constants";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addTag, getTagById, modifyTag } from "./_api";
import { TagsDetailsSchema } from "../../../../helpers/schemas";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getTag(id));

  const [cardData, setCardData] = useState({});

  const initialValues = {
    name: cardData.name || "",
    type: cardData.type || "",
    description: cardData.description || "",
  };

  useEffect(() => {
    if (id && !data) {
      request(getTagById, id).then((data) => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = { ...values, name: values.name.charAt(0).toUpperCase() + values.name.slice(1) };
    request(id ? modifyTag : addTag, payload, id)
      .then((data) => data && history.push("/settings/tags"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={TagsDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Tag"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="Tag Name" name="name" data-testid="name" />
                <MappedSelect
                  label="Tag Type"
                  options={categoriesList}
                  name="type"
                  data-testid="type"
                />
                <MappedInput
                  label="Tag Description"
                  name="description"
                  multiline
                  rows={6}
                  data-testid="description"
                />
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
