import React from "react";
import { Formik } from "formik";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Chip, makeStyles } from "@material-ui/core";
import { Autocomplete } from "../../components/Autocomplete";

const useStyles = makeStyles({
  root: {
    width: "100%",
    color: "white",
    overflow: "hidden",
  },
});

export const AccountField = ({ handleSubmit, data = {}, userAccountsOptions = [] }) => {
  const classes = useStyles();

  const { user_config } = data;

  const initialValues = {
    user_account_id: (user_config || {}).user_account_id,
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue, values, setSubmitting }) => (
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div style={{ width: "30%" }}>
              <Chip label="Business" color="primary" classes={classes} />
            </div>
            <div style={{ width: "6%" }}>
              <ArrowRightAltIcon fontSize="large" />
            </div>
            <div style={{ width: "64%" }}>
              <Autocomplete
                name="user_account_id"
                placeholder="Select Option"
                setValue={setFieldValue}
                onChange={(e, newValue) => handleSubmit({ user_account_id: newValue.value })}
                defaultValue={values.user_account_id}
                defaultValueField="value"
                options={userAccountsOptions}
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
