import React, { useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { Slider, IconButton } from "@material-ui/core";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedCheckbox } from "../../../../ui/components/Checkbox";
import { getCrop } from "./_redux/selectors";
import { TagsField } from "../../../../ui/structures/TagsForm/TagsField";
import { Modal } from "../../../../ui/components/Modal";
import { TagsAutocomplete } from "../../../../ui/structures/TagsForm/TagsAutocomplete";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getCropTags, getSpecificCropTags } from "../../Settings/Tags/_api";
import {
  addCrop,
  deleteCropTags,
  editCropTags,
  getCropById,
  modifyCrop,
} from "./_api";
import { CropDetailsSchema } from "../../../../helpers/schemas";
import { error, info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import Dropzone from "react-dropzone";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./canvasUtils";
import { uploadFile } from "../../AWS-S3/upload";
import config from "react-global-configuration";
import {
  ZoomOutMap as ZoomOutMapIcon,
  CropRotate as CropRotateIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const data = useSelector(getCrop(id));

  const [cardData, setCardData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [fileData, setFileData] = useState([]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage_current = await getCroppedImg(
        fileData[0],
        croppedAreaPixels,
        rotation
      );

      fetch(croppedImage_current)
        .then((response) => response.blob())
        .then((blob) => {
          let file_current = new File(
            [blob],
            id ? `${cardData.id}_${cardData.name}.png` : "crop.png",
            {
              type: "image/png",
            }
          );
          Object.assign(file_current, {
            preview: URL.createObjectURL(file_current),
          });
          setCroppedImage(file_current);
        });
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [fileData, croppedAreaPixels, rotation]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const initialValues = {
    code: cardData.code || "",
    name: cardData.name || "",
    image_url: cardData.image_url || "",
    botanical_name: cardData.botanical_name || "",
    tags: id ? (cardData.tags || []).map(({ id }) => id) : [],
    is_challenging: cardData?.is_challenging || false,
    mixed_product: cardData?.mixed_product || false,
  };

  useEffect(() => {
    if (id && !data) {
      Promise.all([
        request(getCropById, id),
        request(getSpecificCropTags, id),
      ]).then(([details, tags]) => {
        if (!details) return;
        setCardData({ ...details, tags });
      });
    } else if (id) {
      request(getSpecificCropTags, id).then((tags) => {
        setCardData({ ...data, tags });
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const sortTagsHelper = (a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  };

  useEffect(() => {
    request(getCropTags).then(
      (data) => data && setTags(data.sort(sortTagsHelper))
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fileData.length && showCroppedImage();
    // eslint-disable-next-line
  }, [fileData, croppedAreaPixels, rotation]);

  const history = useHistory();

  const createCrop = (values, setSubmitting) => {
    setLoading(true);
    const cropPayload = { ...values };
    delete cropPayload.tags;
    request(addCrop, cropPayload)
      .then((data) => {
        if (!data) return;
        if (values.tags.length) {
          return request(
            editCropTags,
            data.id,
            values.tags.map((tagID) => ({ id: tagID }))
          ).finally(() => {
            setSubmitting(false);
            setLoading(false);
          });
        } else return data;
      })
      .then((data) => data && history.push("/crops/management"));
  };

  const editTagsRequest = (values) => {
    if (values.tags.length) {
      return request(
        editCropTags,
        id,
        values.tags.map((tagID) => ({ id: tagID }))
      );
    } else if (!values.tags.length && data.tags.length) {
      return request(deleteCropTags, id, data.tags);
    } else return "success";
  };

  const updateCrop = (values, setSubmitting) => {
    setLoading(true);
    const cropPayload = { ...values };
    delete cropPayload.tags;
    Promise.all([
      request(modifyCrop, cropPayload, id),
      values.tags.length && editTagsRequest(values),
    ])
      .then(([cropData, tags]) => {
        if (!cropData || !tags) return;
        info("Details have been updated!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const saveUrlFieldsValues = (value) => {
    request(modifyCrop, { image_url: value }, id);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    id ? updateCrop(values, setSubmitting) : createCrop(values, setSubmitting);
    let file = croppedImage || undefined;
    id && file && uploadFile("crop_images", id, file, saveUrlFieldsValues);
    setFileData([]);
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <>
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <div className="border border-secondary bg-white p-10">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={CropDetailsSchema}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <>
              {modalOpen && (
                <Modal
                  maxWidth="sm"
                  isOpen={modalOpen}
                  submitable
                  onClose={handleModalClose}
                  modalContent={
                    <TagsAutocomplete
                      name="tags"
                      placeholder="Select Tags"
                      setValue={setFieldValue}
                      options={tags.filter(
                        (item) => !values.tags.includes(item.id)
                      )}
                      loading={!tags.length}
                      onClose={handleModalClose}
                      currentValue={values.tags}
                      multiple
                    />
                  }
                />
              )}
              <h3 className="mb-10">
                <strong>{id ? cardData.name : "Add Crop"}</strong>
              </h3>
              <div className="row justify-content-between">
                <div className="col-9">
                  {id && (
                    <div className="mb-5">
                      <MappedInput
                        label="Crop ID"
                        name="code"
                        data-testid="code"
                        disabled
                      />
                    </div>
                  )}
                  <div className="mb-5">
                    <MappedInput
                      label="Common Name"
                      name="name"
                      data-testid="name"
                    />
                  </div>
                  <div className="mb-5">
                    <MappedInput
                      label="Botanical Name"
                      name="botanical_name"
                      data-tesid="botanical_name"
                    />
                  </div>
                  <div className="mb-5">
                    <TagsField
                      label="Tags"
                      tags={values.tags}
                      predefinedTags={tags}
                      handleAdd={handleModalOpen}
                      setValue={setFieldValue}
                    />
                  </div>

                  <MappedCheckbox
                    name="is_challenging"
                    checked={values.is_challenging}
                    label="Is challenging"
                  />

                  <MappedCheckbox
                    name="mixed_product"
                    checked={values.mixed_product}
                    label="Mixed Produce"
                  />
                </div>
                <div className="col-3">
                  {id ? (
                    <div className="w-100 pr-5">
                      <h5 className="d-flex mb-6">
                        <strong>Preview</strong>

                        {values?.image_url ||
                        fileData.length ||
                        croppedImage ? (
                          <IconButton
                            onClick={() => {
                              fileData.length
                                ? setFileData([])
                                : croppedImage
                                ? setCroppedImage(null)
                                : setFieldValue("image_url", "");
                            }}
                            aria-label="delete"
                            style={{
                              margin: "-13px 0 0 auto",
                              color: "#F64E60 ",
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </h5>
                      <Dropzone
                        maxFiles={1}
                        maxSize={5000000}
                        accept={["image/*"]}
                        disabled={fileData.length}
                        onDropRejected={(event) =>
                          event.map(({ errors, file }) =>
                            error(`${file?.name} - ${errors[0]?.message}`)
                          )
                        }
                        onDrop={(acceptedFiles) => {
                          setFileData(
                            acceptedFiles.map((file) =>
                              Object.assign(file, {
                                preview: URL.createObjectURL(file),
                              })
                            )
                          );
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              style={{
                                flex: "1",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "20px 20px 10px 20px",
                                borderWidth: 2,
                                borderRadius: 2,
                                borderColor: "#eeeeee",
                                borderStyle: "dashed",
                                backgroundColor: "#fafafa",
                                color: "#bdbdbd",
                                outline: "none",
                                transition: "border .24s ease-in-out",
                                cursor: "pointer",
                              }}
                            >
                              <input {...getInputProps()} />
                              {fileData.length ? (
                                <>
                                  <div
                                    style={{
                                      borderRadius: 2,
                                      marginBottom: 8,
                                      width: "100%",
                                      maxHeight: 330,
                                      padding: 4,
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "100%",
                                        height: 200,
                                        background: "#333",
                                      }}
                                    >
                                      <Cropper
                                        style={{ background: "#fff" }}
                                        image={fileData[0].preview}
                                        crop={crop}
                                        zoom={zoom}
                                        rotation={rotation}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onRotationChange={setRotation}
                                        onZoomChange={setZoom}
                                      />
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                      <div className="d-flex align-items-center">
                                        <ZoomOutMapIcon
                                          color="primary"
                                          className="mr-5"
                                        />
                                        <Slider
                                          value={zoom}
                                          min={1}
                                          max={3}
                                          step={0.1}
                                          aria-labelledby="Zoom"
                                          onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <CropRotateIcon
                                          color="primary"
                                          className="mr-5"
                                        />
                                        <Slider
                                          value={rotation}
                                          min={0}
                                          max={360}
                                          step={1}
                                          aria-labelledby="Rotation"
                                          onChange={(e, rotation) =>
                                            setRotation(rotation)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {values?.image_url || croppedImage ? (
                                    <>
                                      {imageLoading ? (
                                        <Skeleton
                                          variant="rect"
                                          width={210}
                                          height={210}
                                          style={{ margin: "0 auto 20px" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <picture>
                                        <img
                                          src={
                                            croppedImage
                                              ? croppedImage.preview
                                              : `${config.get("ApiUrl").Rest}/${
                                                  values?.image_url
                                                }`
                                          }
                                          alt={id ? cardData.name : "Add Crop"}
                                          loading={"eager"}
                                          onLoad={() => setImageLoading(false)}
                                          style={{
                                            display: imageLoading
                                              ? "none"
                                              : "block",
                                            width: 210,
                                            height: "auto",
                                            margin: "0 auto 20px",
                                          }}
                                        />
                                      </picture>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <p>
                                    Drag 'n' drop some files here, or click to
                                    select files
                                  </p>
                                </>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mt-10">
                <ButtonGroup
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  textTransofrm="uppercase"
                  size="large"
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
