import config from "react-global-configuration";

export const addSite = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/sites`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getSites = () =>
  fetch(`${config.get("ApiUrl").Rest}/sites`, {
    mode: "cors",
    credentials: "include",
  });

export const getSiteById = (organisation_id, site_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/sites/${site_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifySite = (data, organisation_id, site_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/sites/${site_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archiveSite = (organisation_id, site_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/sites/${site_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
