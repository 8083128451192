import React from "react";
import { Skeleton } from "@material-ui/lab";
import * as Yup from "yup";
import { Formik } from "formik";
import { uniqBy } from "lodash";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { AutocompleteMultiple } from "../../../ui/components/AutocompleteMultiple";
import { sortHelper } from "../../../ui/helpers";

const initialValues = {
  contact_ids: [],
};

export const LinkContacts = ({
  contactsOptions = [],
  linkedContacts = [],
  handleCancel,
  handleSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        contact_ids: Yup.array().required("Required field"),
      })}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, isSubmitting, handleSubmit, ...formik }) => (
        <div className="p-5">
          <h3 className="mb-10 text-center">Link Contacts</h3>
          {contactsOptions.length ? (
            <div className="">
              <AutocompleteMultiple
                name="contact_ids"
                placeholder="Contacts"
                setValue={setFieldValue}
                options={uniqBy(contactsOptions, "label").sort(sortHelper)}
                defaultValueField="value"
                defaultValue={linkedContacts.map(({ id }) => id)}
              />
              {formik.touched.contact_ids && formik.errors.contact_ids ? (
                <div className="text-danger">{formik.errors.contact_ids}</div>
              ) : null}
            </div>
          ) : (
            <div>
              <Skeleton variant="rect" width={"100%"} height={55} />
            </div>
          )}
          <div className="mt-15">
            <ButtonGroup
              handleClose={handleCancel}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              textTransofrm="uppercase"
              size="small"
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
