import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTenantsList } from "../../../Tenants/_redux/actions";

import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import {
  Edit as EditIcon,
  Add as AddIcon,
  Cached as CachedIcon,
} from "@material-ui/icons";
import { makeStyles, TableSortLabel } from "@material-ui/core";
import { sortHeaderStyle } from "../../../../constants";
import { InputFile } from "../../../../../ui/components/InputFile";
import File from "../../../../../ui/structures/File";
import { success } from "../../../../../helpers/toasts";

import { downloadFile } from "../../../AWS-S3/download";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { getTenants } from "../../../Tenants/_api";
import {
  unlinkTests,
  getLaboratoryTests,
  exportLaboratoryTestPrices,
  importLaboratoryTestPrices,
} from "../_api";

import {
  getStatusRowStyle,
  isStatus,
  sleep,
  formatDateWithHours,
} from "../../../../../ui/helpers";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ListHeader } from "../../../../../ui/structures/ListHeader";
import { Modal } from "../../../../../ui/components/Modal";
import { LaboratoryTestPricesModal } from "./LaboratoryTestPricesModal";

import { TestsList } from "./TestsList";
import { isLink, modifyLaboratories, findLatestImportDate } from "./helpers";
import { Loader } from "../../../../../ui/components/Loader";

const useStyles = makeStyles({
  links: {
    display: "flex",
    padding: "15.25px 1.25rem",
    height: 50,
    transition: "0.1s ease-out",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0,0,0,0.1)",
      "& $edit": {
        opacity: 1,
      },
      "& $add": {
        opacity: 0,
      },
    },
  },
  tableIcon: {
    marginLeft: "auto",
  },
  edit: {
    opacity: 0,
    transition: "0.1s ease-out",
  },
  add: {
    position: "absolute",
    opacity: 0.8,
    transition: "0.1s ease-out",
  },
});

const HEADINGS = [
  ["code", "Test ID"],
  ["name", "Test Name"],
  ["availability", "Availability"],
  ["status", "Status"],
];

export const LaboratoryTests = ({ name, laboratoryId }) => {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const classes = useStyles();

  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [testsList, setTestsList] = useState([]);
  const [linkedTests, setLinkedTests] = useState([]);
  const [nonLinkedTests, setNonLinkedTests] = useState([]);
  const [laboratoryTestPriceIsOpen, setLaboratoryTestPriceIsOpen] = useState(
    false
  );
  const [laboratoryTestPriceId, setLaboratoryTestPriceId] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [latestImportDate, setLatestImportDate] = useState("");

  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");

  const tenants =
    useSelector(({ tenants: { tenantsList } }) => tenantsList) || [];

  const handleFocus = () => setFocused(true);

  const handleModalOpen = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
    setSelected({});
  };

  const laboratoryTestPriceModalClose = () =>
    setLaboratoryTestPriceIsOpen(false);

  const importModalClose = () => setImportModalOpen(false);

  const handleExpand = (id) => {
    setLaboratoryTestPriceIsOpen(true);
    setLaboratoryTestPriceId(id);
  };

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const updateFiles = () => "updated files...";

  const saveUrlFieldsValues = async (value) => {
    await sleep(1500);
    success(
      "The laboratory prices import process started, please refresh the list in a few minutes!"
    );

    request(
      importLaboratoryTestPrices,
      laboratoryId,
      value,
      "justOnce",
      "no504"
    )
      .then((data) => {})
      .finally(() => {
        importModalClose();
        setUploadedFiles([]);
      });
  };

  const fetchLinkedTests = () => {
    setLoading(true);
    request(getLaboratoryTests, laboratoryId, field, direction)
      .then((data) => {
        if (!data) return;
        setLatestImportDate(findLatestImportDate(data));
        setLinkedTests(modifyLaboratories(data, tenants));
        setInitialData(modifyLaboratories(data, tenants));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!tenants.length) setLoading(true);
    request(getTenants).then(
      (tenants) => tenants && dispatch(setTenantsList(tenants))
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    tenants.length && fetchLinkedTests();
    // eslint-disable-next-line
  }, [tenants, field, direction]);

  useEffect(() => {
    const arrayOfIds = linkedTests.map((item) => item.id);
    setNonLinkedTests(
      testsList.filter((item) => !arrayOfIds.includes(item.id))
    );
    // eslint-disable-next-line
  }, [linkedTests]);

  const handleSearch = (search, isRemoveKey) => {
    setLinkedTests(
      (isRemoveKey ? initialData : linkedTests).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          HEADINGS.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    );
  };

  const KEY_MAP = {
    "Test ID": linkedTests.map((item) => item.code),
    "Test Name": linkedTests.map((item) => item.name),
    Status: linkedTests.map((item) => item.status),
  };

  const resetSearch = () => {
    fetchLinkedTests();
  };

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton
          label={<CachedIcon style={{ fontSize: 26 }} />}
          onClick={fetchLinkedTests}
          style={{ padding: 1, minWidth: 28 }}
        />
      </div>
      <div className="mr-5">
        <ListButton
          label="Export"
          onClick={() => downloadFile(laboratoryId, exportLaboratoryTestPrices)}
        />
      </div>
      <div className="mr-5">
        <ListButton label="Import" onClick={() => setImportModalOpen(true)} />
      </div>
      <div className="mr-5">
        <ListButton label="Add Test" onClick={handleModalOpen} />
      </div>
      <div className="mr-5">
        <ListButton
          label="Remove Test"
          onClick={handleRemoveTest}
          disabled={unlinkDisabled}
        />
      </div>
    </>
  );

  const unlinkDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const updateTestsList = () => {
    setLoading(true);
    return request(getLaboratoryTests, laboratoryId)
      .then((data) => {
        if (!data) return;
        setLinkedTests(modifyLaboratories(data, tenants));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveTest = () => {
    setLoading(true);
    const payload = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({
        id: item,
      }));
    request(unlinkTests, payload, laboratoryId).then((ids) => {
      if (!ids) {
        setLoading(false);
        return;
      }
      updateTestsList();
      setSelected({});
    });
  };

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      ["code", "name"].includes(key) ? (
        <th
          key={key}
          style={sortHeaderStyle}
          className="text-nowrap grid-header"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      ) : (
        <th key={key} className="grid-header" style={sortHeaderStyle}>
          {header}
        </th>
      )
    );
  }

  function renderLinkedTestsRow(headings, item) {
    return headings.map(([key]) => (
      <td
        key={key}
        className={`align-middle no-line border-0 ${!isLink(key) &&
          "px-5 py-5"} ${isStatus(key) && getStatusRowStyle(item)}`}
      >
        {isLink(key) ? (
          <div className={classes.links} onClick={() => handleExpand(item.id)}>
            {item[key]}{" "}
            <div className={classes.tableIcon}>
              <div className={classes.add}>
                <AddIcon fontSize="small" />
              </div>
              <div className={classes.edit}>
                <EditIcon fontSize="small" />
              </div>
            </div>
          </div>
        ) : (
          item[key]
        )}
      </td>
    ));
  }

  return (
    <>
      {loading && <Loader title="Loading..." isOpen={loading} />}
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleClose}
          modalContent={
            <TestsList
              handleClose={handleClose}
              data={nonLinkedTests}
              laboratoryId={laboratoryId}
              setNonLinkedTests={setNonLinkedTests}
              setLoading={setLoading}
              updateTestsList={updateTestsList}
              setTestsList={setTestsList}
              linkedTests={linkedTests}
            />
          }
        />
      )}
      {importModalOpen && (
        <Modal
          isOpen={importModalOpen}
          onClose={importModalClose}
          maxWidth="md"
          buttonText="CLOSE"
          modalContent={
            <div className="m-5">
              <h4 className="mb-10 text-center">
                <strong>Import Laboratory Prices</strong>
              </h4>
              <div className="justify-content mt-15 mb-5 mx-15">
                <InputFile
                  className="rounded"
                  onDrop={handleUploadFiles}
                  description="You can upload only one file at a time"
                  label="Import"
                >
                  {uploadedFiles.map((file) => (
                    <File
                      key={file.lastModified}
                      senderId={laboratoryId}
                      senderType={"temp_uploads"}
                      file={file}
                      saveUrlFieldsValues={saveUrlFieldsValues}
                      updateFiles={updateFiles}
                    />
                  ))}
                </InputFile>
              </div>
            </div>
          }
        />
      )}
      {laboratoryTestPriceIsOpen && (
        <Modal
          maxWidth="lg"
          isOpen={laboratoryTestPriceIsOpen}
          onClose={laboratoryTestPriceModalClose}
          buttonText="CLOSE"
          modalContent={
            <LaboratoryTestPricesModal
              test_data={linkedTests.find(
                ({ id }) => id === laboratoryTestPriceId
              )}
              laboratory_id={laboratoryId}
              updateTestsList={updateTestsList}
              tenants={tenants}
            />
          }
        />
      )}
      <div className="row justify-content-center bg-white py-15 px-10">
        <div className="col-12">
          <h3 className="mb-15">
            <strong>{name}</strong>
          </h3>
          <div className="mb-10">
            <SearchBar
              onSearch={handleSearch}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter Tests..."
              elevation={2}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
              focused={focused}
              setFocused={setFocused}
            />
          </div>
          <div>
            <ListHeader
              description={`Latest import date: ${latestImportDate &&
                formatDateWithHours(latestImportDate)}`}
              renderButtons={renderButtons}
              handleFocus={handleFocus}
              loading={loading}
            />
            <Datagrid
              data={linkedTests}
              headings={HEADINGS}
              renderRow={renderLinkedTestsRow}
              renderHeaderWithSorting={renderHeaderWithSorting}
              editable
              selected={selected}
              setSelected={setSelected}
              selectable
              link="tests"
            />
          </div>
        </div>
      </div>
    </>
  );
};
