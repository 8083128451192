import React from "react";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { useFormikContext } from "formik";
import { useEffect } from "react";

export const PriceListSelect = ({
  priceListOptions = [],
  id,
  selectedPriceList,
  priceListId,
  setSelectedPriceList,
}) => {
  const { setFieldValue } = useFormikContext();

  const handlePriceListChange = (event, setFieldValue) => {
    setSelectedPriceList(event.target.value);
    setFieldValue("price_list_id", event.target.value);
  };

  useEffect(() => {
    if (id) {
      setSelectedPriceList(priceListId);
      setFieldValue("price_list_id", priceListId);
    }
  // eslint-disable-next-line
  }, []);
  return (
    <MappedSelect
      label="Price List"
      options={priceListOptions}
      name="price_list_id"
      data-testid="price_list_id"
      onChange={(event) => handlePriceListChange(event, setFieldValue)}
      value={selectedPriceList}
      disabled={Boolean(id)}
    />
  );
};
