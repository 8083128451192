export const HEADINGS = [
  ["code", "Site ID"],
  ["name", "Site Name"],
  ["phone", "Site Phone"],
  ["site_email", "Site Email"],
  ["status", "Status"],
];

export const statusOptions = [
  {
    value: "active",
    label: "Active",
  },
];

export const certificationTypes = [
  {
    value: "Freshcare",
    label: "Freshcare",
  },
  {
    value: "BRC",
    label: "BRC",
  },
  {
    value: "SQF",
    label: "SQF",
  },
  {
    value: "GlobalGAP",
    label: "GlobalGAP",
  },
  {
    value: "HACCP",
    label: "HACCP",
  },
];
