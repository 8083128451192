import React from "react";
import { Formik } from "formik";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { statusOptions } from "./constants";
import { statusColourSchema } from "../../../helpers/schemas";
import { ListButton } from "../../../ui/components/ListButton";

export const EditColour = ({ handleSubmit, onClose, status }) => {
  const initialValues = {
    manual_verdict: status || "",
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={statusColourSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div>
          <h4 className="mb-10 text-center">
            <strong>Edit Status Colour</strong>
          </h4>
          <div className="d-flex">
            <div className="w-100">
              <MappedSelect
                name="manual_verdict"
                options={statusOptions}
                label="Colour"
                data-testid="manual_verdict"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-15 mb-5">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                variant="outlined"
                onClick={onClose}
                text="#407A28"
                size="medium"
                boxShadow={false}
              />
            </div>
            <div>
              <ListButton
                label="Submit"
                size="medium"
                onClick={handleSubmit}
                boxShadow={false}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
