export const HEADINGS = [
  ["code", "Tag ID"],
  ["name", "Tag Name"],
  ["category", "Tag Category"],
  ["status", "Status"],
];

export const initialValues = {
  id: "",
  name: "",
  type: "Crop",
  category: "",
  description: "",
};

export const categoriesList = [
  {
    value: "Industry Representative",
    label: "Industry Representative",
  },
  {
    value: "Codex",
    label: "Codex",
  },
  {
    value: "Food Standards Code",
    label: "Food Standards Code",
  },
];

export const typesList = [
  {
    value: "Organisation",
    label: "Organisation",
  },
  {
    value: "Contact",
    label: "Contact",
  },
  {
    value: "Crop",
    label: "Crop",
  },
];

export const searchMap = {
  "Tag ID": "code",
  "Tag Name": "name",
  "Tag Category": "category",
  Status: "status",
};
