import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Skeleton } from "@material-ui/lab";
import { Filter } from "../../../../ui/components/Filter";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getOrganisationSites } from "../../Organisations/_api";

export const CustomerSiteField = ({ loading, setLoading }) => {
  const { request } = useFetch();

  const { values, setFieldValue } = useFormikContext();
  const [siteOptions, setSiteOptions] = useState([]);
  const [resetKey, setResetKey] = useState(Date.now());

  useEffect(() => {
    if (!values.biller_org_id) {
      setSiteOptions([]);
      setFieldValue("biller_site_id", "");
      setResetKey(Date.now());
    } else {
      setLoading(true);
      request(getOrganisationSites, values.biller_org_id)
        .then((data) => {
          if (!data) return;
          setSiteOptions(data);
          const defaultSiteId = (data.find(({ is_default }) => is_default) || {}).id;
          setFieldValue("biller_site_id", defaultSiteId);
          setResetKey(Date.now());
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [values.biller_org_id]);

  if (values.biller_org_id && loading) {
    return <Skeleton variant="rect" width={"100%"} height={50} />;
  }

  return (
    <Filter
      key={resetKey}
      name="name"
      placeholder="Customer Site"
      options={siteOptions}
      setValue={setFieldValue}
      defaultValue={values.biller_site_id}
      radioButtonName="biller_site_id"
    />
  );
};
