/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import UserDropDown from "../../../../ui/structures/UserDropDown";
import md5 from "md5";

export function Brand() {
  const uiService = useHtmlClassService();
  const { user } = useSelector((state) => state.auth);

  const tenantName = (user.tenants.find(({ id }) => user.admins_tenant === id) || {}).legal_name;

  const generateHash = () => {
    return md5(user.email);
  };

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div className={`pt-20 px-5 bg-white ${layoutProps.brandClasses}`} id="kt_brand">
        <div className="symbol symbol-75 mr-5 mb-5">
          <img
            src={`https://www.gravatar.com/avatar/${generateHash()}`}
            alt="avatar"
            className="symbol-label rounded-circle"
          />
        </div>
        <div className="d-flex justify-content-between" style={{ cursor: "pointer" }}>
          <strong>
            <h5>
              <strong>{user.fullname}</strong>
            </h5>
          </strong>
          <UserDropDown />
        </div>
        <div>{tenantName && tenantName}</div>
      </div>
      {/* end::Brand */}
    </>
  );
}
