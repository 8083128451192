import { get } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { isLink, formatDate, isDate, isArray, isPrice, formatPrice } from "../../../../ui/helpers";

export function renderPricesRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {isLink(key) ? (
        <Link className="text-dark" to={`/prices/${item.id}`}>
          {item[key]}
        </Link>
      ) : isArray(key) ? (
        (item[key] || []).join(", ")
      ) : isDate(key) ? (
        item[key] && formatDate(new Date(item[key]))
      ) : isPrice(key) ? (
        formatPrice(item[key])
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const modifyPrices = (data, tenants = []) =>
  data.map((price) => modifyPriceData(price, tenants));

export const modifyPriceData = (price, tenants = []) => {
  return {
    ...price,
    states: tenants.map(({ name }) => name),
    test_code: get(price, "test.code", ""),
    test_name: get(price, "test.name"),
  };
};
