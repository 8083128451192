import React, { useState } from "react";
import Input from "@bit/the-glue.frontendcomponents.input";
import { ListButton } from "./ListButton";

export const ConfirmMultiple = ({
  handleClose,
  handleSubmit,
  id,
  warningMessage,
  confritmTitle = "DELETE",
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  const [inputValue, setInptuValue] = useState("");

  const onClose = () => {
    handleClose();
    setStep(0);
    setInptuValue("");
  };

  const handleChange = (event) => {
    setInptuValue(event.target.value);
  };

  return (
    <>
      {step === 0 && (
        <div className="p-5">
          <div className="text-center">
            <h3 className="text-danger">{warningMessage}</h3>
          </div>
          <div className="mt-10 d-flex justify-content-end">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="large"
                onClick={onClose}
                variant="outlined"
                boxShadow={false}
                text="#407A28"
              />
            </div>
            <div>
              <ListButton
                label={confritmTitle}
                size="large"
                boxShadow={false}
                onClick={() => setStep(1)}
              />
            </div>
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="p-5">
          <div className="text-center">
            <h3>Please type the word {`"${confritmTitle}"`} and press CONFIRM</h3>
          </div>
          <div className="my-10">
            <Input value={inputValue} onChange={handleChange} wrapperClassName="w-100" />
          </div>
          <div className="mt-10 d-flex justify-content-end">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="large"
                onClick={onClose}
                variant="outlined"
                boxShadow={false}
                text="#407A28"
              />
            </div>
            <ListButton
              label="Confirm"
              size="large"
              boxShadow={false}
              onClick={() => handleSubmit(id, setSubmitting)}
              disabled={submitting || inputValue !== confritmTitle}
            />
          </div>
        </div>
      )}
    </>
  );
};
