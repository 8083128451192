import React from "react";
import Button from "@bit/the-glue.frontendcomponents.button";

export const ListButton = ({ label, size = "small", ...other }) => {
  return (
    <Button
      label={label}
      size={size}
      // text="blue"
      // background="#fff"
      background="#71BF44"
      text="#E8F5E1"
      boxShadow
      textTransform="uppercase"
      fontWeight="500"
      fontSize="12px"
      {...other}
    />
  );
};
