import { get } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { getStatusRowStyle, isLink, isStatus } from "../../../ui/helpers";

export function renderOrganisationsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/organisations-list/${item.id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const modifyOrganisations = (data) =>
  data.map((organisation) => modifyOrganisationData(organisation));

export const modifyOrganisationData = (organisation) => {
  return {
    ...organisation,
    abn_verified: get(organisation, "abn_verified", "") ? "Verified" : "Not Verified",
  };
};

export const modifyAbnSearchValue = (search) => {
  if (search.includes(encodeURIComponent("Not Verified"))) {
    return search.replace(encodeURIComponent("Not Verified"), "false");
  }
  if (search.includes(encodeURIComponent("Verified"))) {
    return search.replace(encodeURIComponent("Verified"), "true");
  }
  if (search.includes("&")) {
    return search.replace("&", encodeURIComponent("&"));
  }
  return search;
};
