export const invoiceTypes = {
  SET_DATA: "INVOICES/SET_DATA",
  REMOVE_DATA: "INVOICES/REMOVE_DATA",
  UPDATE_DATA: "INVOICES/UPDATE_DATA",
};

export function setInvoicesList(data) {
  return {
    type: invoiceTypes.SET_DATA,
    payload: data,
  };
}

export function removeInvoice(data) {
  return {
    type: invoiceTypes.REMOVE_DATA,
    payload: data,
  };
}

export function updateInvoicesList(data) {
  return {
    type: invoiceTypes.UPDATE_DATA,
    payload: data,
  };
}
