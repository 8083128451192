import React, { useState, useEffect } from "react";
import { getGroupById } from "../../../helpers/permissionsAPI";
import { useFetch } from "../../../hooks/fetch.hook";
import { PoliciesList } from "./PoliciesList";
import { PolicyDetails } from "./PolicyDetails";

export const Policies = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const [component, setComponent] = useState("list");
  const [cardData, setCardData] = useState({});

  useEffect(() => {
    request(getGroupById, id).then((data) => data && setCardData(data));
    // eslint-disable-next-line
  }, []);

  const { policies, name } = cardData;

  const handleNew = () => {
    setComponent("new");
  };

  const handleAdd = () => {
    setComponent("add");
  };

  const handleCancel = () => {
    setComponent("list");
  };

  const componentsMap = {
    list: (
      <PoliciesList
        data={policies}
        name={name}
        groupId={id}
        setCardData={setCardData}
        handleNew={handleNew}
        handleAdd={handleAdd}
      />
    ),
    new: <PolicyDetails handleCancel={handleCancel} groupId={id} setCardData={setCardData} />,
  };

  return <>{componentsMap[component]}</>;
};
