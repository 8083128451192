import { Formik } from "formik";
import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { shareCertificateSchema } from "../../../../helpers/schemas";
import Button from "@bit/the-glue.frontendcomponents.button";
import { useFetch } from "../../../../hooks/fetch.hook";
import { LinearProgress } from "@material-ui/core";
import { info } from "../../../../helpers/toasts";
import { shareCertificate } from "../_api";

const initialValues = {
  email: "",
};

export const ShareEmail = ({ handleClose, selectedResults = [] }) => {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);

  const shareRequest = (email, id, setSubmitting) => {
    setLoading(true);
    request(shareCertificate, { email }, id)
      .then((data) => {
        if (!data) return;
        info("Certificate has been shared!");
        handleClose()
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleShareCertificate = (values, { setSubmitting }) => {
    const promises = selectedResults.map((id) => shareRequest(values.email, id, setSubmitting));
    Promise.all(promises);
  };

  return (
    <Formik
      onSubmit={handleShareCertificate}
      initialValues={initialValues}
      validationSchema={shareCertificateSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="p-4">
          <h4 className="mb-5">Share Results</h4>
          <p>
            Please provide the email address of the the user that you would like to share your
            results:
          </p>
          <MappedInput label="Email" name="email" />
          <div className="d-flex justify-content-end mt-10">
            <Button
              label="Cancel"
              variant="outlined"
              text="#50B849"
              background="#50B849"
              textTransform="uppercase"
              fontWeight="500"
              boxShadow
              className="mr-5"
              onClick={handleClose}
              disabled={isSubmitting || loading}
            />
            <Button
              label="Share"
              boxShadow
              text="white"
              background="#50B849"
              textTransform="uppercase"
              fontWeight="500"
              onClick={handleSubmit}
              disabled={isSubmitting || loading}
            />
          </div>
          <div className="py-2">{(isSubmitting || loading) && <LinearProgress />}</div>
        </div>
      )}
    </Formik>
  );
};
