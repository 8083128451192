import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { getPriceItem } from "./_redux/selectors";
import {
  addPrice,
  getPriceById,
  getPriceLists,
  getPrices,
  modifyPrice,
} from "./_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { PriceListSchema } from "../../../../helpers/schemas";
import { getTests } from "../../Settings/Tests/_api";
import { formatDateToUTC } from "../../../../ui/helpers";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { PriceDate } from "./PriceDate";
import { DevAlertBar } from "../../../../ui/components/AlertBar";
import { PriceListSelect } from "./PriceListSelect";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const data = useSelector(getPriceItem(id)) || {};

  const history = useHistory();

  const priceList = history.location.priceList;

  const initialValues = {
    test_id: "",
    sales_price: "",
    start_date: null,
    end_date: null,
    price_list_id: priceList ? priceList : "",
  };

  const [cardData, setCardData] = useState({});
  const [tests, setTests] = useState([]);
  const [prices, setPrices] = useState([]);
  const [priceLists, setPriceLists] = useState([]);
  const [restrictedCodes, setRestrictedCodes] = useState([]);
  const [selectedPriceList, setSelectedPriceList] = useState("");
  const [testCodesDisabled, setTestCodesDIsabled] = useState(false);

  const testCodeOptions = tests.map(({ id, code }) => ({
    value: id,
    label: code,
  }));
  const priceListOptions = priceLists.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  useEffect(() => {
    if (!selectedPriceList) return;
    setTestCodesDIsabled(true);
    request(getPrices, selectedPriceList)
      .then((data) => {
        if (!data) return;
        setPrices(data.filter(({ end_date }) => end_date !== null));
        return data.filter(({ end_date }) => end_date === null);
      })
      .then((data) => {
        if (!data) return;
        setRestrictedCodes(data.map(({ test: { code } }) => code));
      })
      .finally(() => setTestCodesDIsabled(false));
    // eslint-disable-next-line
  }, [selectedPriceList]);

  useEffect(() => {
    request(getTests).then(
      (data) =>
        data &&
        setTests(data.filter(({ code }) => !restrictedCodes.includes(code)))
    );
    // eslint-disable-next-line
  }, [restrictedCodes]);

  useEffect(() => {
    if (!priceList) return;
    setSelectedPriceList(priceList);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id && !Object.keys(data).length) {
      request(getPriceById, id).then(
        (data) =>
          data &&
          setCardData({
            ...data,
            start_date: data.start_date ? new Date(data.start_date) : "",
            end_date: data.end_date ? new Date(data.end_date) : "",
          })
      );
    } else {
      setCardData({
        ...data,
        start_date: data.start_date ? new Date(data.start_date) : "",
        end_date: data.end_date ? new Date(data.end_date) : "",
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    request(getPriceLists).then((data) => data && setPriceLists(data));
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    // removeEmpty(values);
    const payload = {
      ...values,
      start_date: formatDateToUTC(values.start_date),
      end_date: values.end_date ? formatDateToUTC(values.end_date, true) : null,
    };
    request(id ? modifyPrice : addPrice, payload, id)
      .then((data) => data && history.push("/settings/prices"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  if (id && !Object.keys(testCodeOptions).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <>
      <DevAlertBar message="Test codes that are contained in prices without selected end date are not displayed" />
      <div className="border border-secondary bg-white p-10">
        <Formik
          onSubmit={handleSubmit}
          initialValues={id ? cardData : initialValues}
          validationSchema={PriceListSchema}
        >
          {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
            <>
              <h3 className="mb-10">
                <strong>{id ? "Edit Price" : "Add Price"}</strong>
              </h3>
              <div className="row">
                <div className="col-8">
                  <PriceListSelect
                    priceListOptions={priceListOptions}
                    id={id}
                    selectedPriceList={selectedPriceList}
                    priceListId={values.price_list_id}
                    setSelectedPriceList={setSelectedPriceList}
                  />
                  <div className="row">
                    <div className="col-6">
                      <Autocomplete
                        name="test_id"
                        placeholder="Select Test Code"
                        setValue={setFieldValue}
                        defaultValue={values.test_id}
                        defaultValueField="value"
                        options={testCodeOptions}
                        data-testid="test_id"
                        disabled={
                          testCodesDisabled ||
                          !Boolean(values.price_list_id) ||
                          Boolean(id)
                        }
                      />
                    </div>
                    <div className="col-6">
                      <MappedInput
                        label="Sell Price (ex GST)"
                        name="sales_price"
                        data-testid="price"
                      />
                    </div>
                  </div>

                  <PriceDate prices={prices} tests={tests} />
                </div>
              </div>
              <div className="mt-10">
                <ButtonGroup
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  textTransofrm="uppercase"
                  size="large"
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
