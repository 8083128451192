const type = "results";

export const setResultsList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      resultsList: payload,
    };
  },
  type,
});