import React from "react";
import { Formik } from "formik";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { defaultStatusOptions, statusOptionsMap } from "./constants";
import { testRequestStatusSchema } from "../../../helpers/schemas";
import { ListButton } from "../../../ui/components/ListButton";

export const EditStatus = ({ handleSubmit, onClose, status }) => {
  const initialValues = {
    status:
      (status &&
        status
          .toLowerCase()
          .split(" ")
          .join("_")) ||
      "",
    date: new Date(Date.now()),
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={testRequestStatusSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="py-10">
          <h4 className="mb-10 text-center">
            <strong>Change Status</strong>
          </h4>
          <div className="d-flex">
            <div className="w-100">
              <MappedDatePicker name="date" label="Date" testId="date" />
              <MappedSelect
                name="status"
                options={statusOptionsMap[status] || defaultStatusOptions}
                label="Status"
                data-testid="status"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-15 mb-5">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                variant="outlined"
                onClick={onClose}
                text="#407A28"
                size="medium"
                boxShadow={false}
              />
            </div>
            <div>
              <ListButton
                label="Submit"
                size="medium"
                onClick={handleSubmit}
                boxShadow={false}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
