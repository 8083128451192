import React from "react";
import { buttonStyle, itemStyle } from "./constants";
import CRSIcon from "./static/crs.png";

export const CreditCervice = ({
  handleConnect,
  handleDisconnect,
  connectedComponent,
  tokenExpired = false,
}) => {
  const integrationDisabled =
    connectedComponent && connectedComponent !== "crs";
  const connected = connectedComponent === "crs";

  const handleCRSConnect = () => {
    handleConnect();
  };

  return (
    <>
      <div
        style={itemStyle}
        className={`${
          integrationDisabled ? "bg-secondary" : "bg-white"
        } p-7 mt-10 mr-5`}
      >
        <div className="text-center mb-15">
          <img src={CRSIcon} alt="logo" style={{ height: 78 }} />
        </div>
        <h4 className="mb-2">
          <strong>CRS NextGen</strong>
        </h4>
        <div className="mb-15">
          Add you Finance and Accounting Platform for even greater visibility of
          your operations.
        </div>
        <div>
          {tokenExpired ? (
            <button
              className={`${buttonStyle} text-primary`}
              onClick={handleCRSConnect}
            >
              Reconnect CRS
            </button>
          ) : connected ? (
            <button
              className={`${buttonStyle} text-danger`}
              onClick={handleDisconnect}
            >
              Disconnect
            </button>
          ) : (
            <button
              className={`${buttonStyle} text-primary`}
              onClick={handleCRSConnect}
              disabled={integrationDisabled}
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </>
  );
};
