import React from "react";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { isLink } from "../../../ui/helpers";

export function renderTenantsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {isLink(key) ? (
        <Link className="text-dark" to={`/tenants/${item.id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}
export function modifyTenantGroups(tenants = []) {
  return tenants.map((item) => ({
    ...item,
    type: item.type
      .split("_")
      .map(capitalize)
      .join(" "),
  }));
}

export function modifyGroupObject(group = {}) {
  return {
    ...group,
    type: group.type
      .split("_")
      .map(capitalize)
      .join(" "),
  };
}
