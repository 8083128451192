import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { DevAlertBar } from "../../../ui/components/AlertBar";
import { CreditCervice } from "./CreditCervice";
import { Xero } from "./Xero";
import { Symbio } from "./Symbio";
import { Myob } from "./Myob";
import { Modal } from "../../../ui/components/Modal";
import { ClientDetails } from "./ClientDetails";
import { callBackUrlMap, getMyobAuthUrl, getXeroAuthUrl } from "./constants";
import { CRSKeyForm } from "./CrsKeyForm";
import { useFetch } from "../../../hooks/fetch.hook";
import { getCurrentIntegration } from "./helpers";
import * as auth from "../Auth/_redux/authRedux";
import {
  createStripeIntegration,
  deleteIntegration,
  getIntegrations,
  getMyobParameters,
  getTenantIntegrations,
  getXeroParameters,
} from "./_api";
import { Stripe } from "./Stripe";
import { info } from "../../../helpers/toasts";

const Integrations = (props) => {
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user);
  const integrations =
    useSelector(
      ({
        auth: {
          user: { integrations },
        },
      }) => integrations
    ) || [];

  const tenantID = user.admins_tenant ? user.admins_tenant : null;

  const xeroIntegration = getCurrentIntegration(integrations, tenantID, "xero") || {};

  const myobIntegration = getCurrentIntegration(integrations, tenantID, "myob") || {};

  const currentStripeIntegration = getCurrentIntegration(integrations, tenantID, "stripe") || {};

  const stripeConnected =
    (currentStripeIntegration.content || {}).details_submitted &&
    (currentStripeIntegration.content || {}).charges_enabled;

  const [connectedComponent, setConnectedComponent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState("");
  const [loaderTitle, setLoaderTitle] = useState("Connecting...");
  const [loading, setLoading] = useState(false);
  const [stripeIntegration, setStripeIntegration] = useState({});

  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    Object.keys(xeroIntegration).length && setConnectedComponent("xero");
    Object.keys(myobIntegration).length && setConnectedComponent("myob");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (integrations.find((integration) => (integration || {}).integration_type === "stripe"))
      return;
    request(tenantID ? getTenantIntegrations : getIntegrations, tenantID).then((data) => {
      if (!data) return;
      const stripeIntegration = getCurrentIntegration(data, tenantID, "stripe") || {};
      Object.keys(stripeIntegration).length && setStripeIntegration(stripeIntegration);
      if (
        (stripeIntegration.content || {}).details_submitted &&
        (stripeIntegration.content || {}).charges_enabled
      ) {
        props.fulfillUser({ ...user, integrations: [...user.integrations, ...data] });
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleCrsKeyFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setConnectedComponent("crs");
    handleModalClose();
  };

  const handleMyobtDetailsSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setConnectedComponent("myob");
    handleModalClose();
  };

  const handleXeroConnect = () => {
    setLoaderTitle("Connecting...");
    setLoading(true);
    request(getXeroParameters)
      .then((data) => {
        if (!data) return;
        localStorage.setItem("integration", "xero");
        window.location.href = getXeroAuthUrl(
          user.id,
          data.client_id,
          callBackUrlMap[window.location.hostname],
          data.scopes
        );
      })
      .finally(() => setLoading(false));
  };

  const handleXeroDisconnect = () => {
    setLoaderTitle("Disconnecting...");
    setLoading(true);
    request(deleteIntegration, xeroIntegration.id)
      .then((data) => {
        if (!data) return;
        props.fulfillUser({
          ...user,
          integrations: user.integrations.filter(({ id }) => id !== xeroIntegration.id),
        });
        setConnectedComponent("");
      })
      .finally(() => setLoading(false));
  };

  const handleCRSConnect = () => {
    setModalComponent("crs");
    setModalOpen(true);
  };

  const handleCRSDisconnect = () => {
    setConnectedComponent("");
  };

  const handleMYOBConnect = () => {
    setLoaderTitle("Connecting...");
    setLoading(true);
    request(getMyobParameters)
      .then((data) => {
        if (!data) return;
        localStorage.setItem("integration", "myob");
        window.location.href = getMyobAuthUrl(
          data.client_id,
          callBackUrlMap[window.location.hostname],
          data.scopes
        );
      })
      .finally(() => setLoading(false));
  };

  const handleMYOBDisconnect = () => {
    setLoaderTitle("Disconnecting...");
    setLoading(true);
    request(deleteIntegration, myobIntegration.id)
      .then((data) => {
        if (!data) return;
        props.fulfillUser({
          ...user,
          integrations: user.integrations.filter(({ id }) => id !== myobIntegration.id),
        });
        setConnectedComponent("");
      })
      .finally(() => setLoading(false));
  };

  const handleStripeConnect = () => {
    setLoaderTitle("Connecting...");
    setLoading(true);
    const payload = {
      integration_type: "stripe",
      tenant_id: tenantID,
    };
    if (!Object.keys(stripeIntegration).length) {
      request(createStripeIntegration, payload)
        .then((data) => {
          if (!data) return;
          window.location.href = data.content.account_link_url;
        })
        .finally(() => setLoading(false));
    } else if (
      Object.keys(stripeIntegration).length &&
      !(stripeIntegration.content || {}).details_submitted &&
      !(stripeIntegration.content || {}).charges_enabled
    ) {
      window.location.href = (stripeIntegration.content || {}).account_link_url;
    } else {
      setLoading(false);
      info("Integration alredy exists");
      return;
    }
  };

  const handleStripeDisconnect = () => {
    setLoaderTitle("Disconnecting...");
    setLoading(true);
    request(deleteIntegration, currentStripeIntegration.id)
      .then((data) => {
        if (!data) return;
        props.fulfillUser({
          ...user,
          integrations: user.integrations.filter(({ id }) => id !== currentStripeIntegration.id),
        });
        setStripeIntegration({});
      })
      .finally(() => setLoading(false));
  };

  const modalContentMap = {
    crs: <CRSKeyForm onClose={handleModalClose} handleSubmit={handleCrsKeyFormSubmit} />,
    myob: <ClientDetails onClose={handleModalClose} handleSubmit={handleMyobtDetailsSubmit} />,
  };

  return (
    <>
      {modalOpen && (
        <Modal
          maxWidth="sm"
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={modalContentMap[modalComponent]}
        />
      )}
      <DevAlertBar message="This Page is Under Development" />
      <div className="bg-white p-10">
        <h4>
          <strong>Integrations</strong>
        </h4>
        <hr />
        <div className="my-10">
          <div className="d-flex flex-wrap">
            <Xero
              handleConnect={handleXeroConnect}
              handleDisconnect={handleXeroDisconnect}
              connectedComponent={connectedComponent}
              setConnectedComponent={setConnectedComponent}
              integrations={integrations}
              fulfillUser={props.fulfillUser}
              user={user}
              xeroLoading={loading}
              loaderTitle={loaderTitle}
              setXeroLoading={setLoading}
              currentIntegration={xeroIntegration}
            />
            <Myob
              handleConnect={handleMYOBConnect}
              handleDisconnect={handleMYOBDisconnect}
              connectedComponent={connectedComponent}
              setConnectedComponent={setConnectedComponent}
              loading={loading}
              setLoading={setLoading}
              user={user}
              integrations={integrations}
              fulfillUser={props.fulfillUser}
              currentIntegration={myobIntegration}
            />
            <Symbio />
            <CreditCervice
              handleConnect={handleCRSConnect}
              handleDisconnect={handleCRSDisconnect}
              connectedComponent={connectedComponent}
            />
            <Stripe
              handleConnect={handleStripeConnect}
              loading={loading}
              setLoading={setLoading}
              connected={stripeConnected}
              handleDisconnect={handleStripeDisconnect}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, auth.actions)(Integrations);
