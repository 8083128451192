import config from "react-global-configuration";
import { modifyInvoiceFieldSearch } from "../helpers";

export const getInvoices = (
  tenant_id,
  page = 0,
  page_size = 10,
  search = "",
  field = "",
  direction = ""
) => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/invoices?&page=${page}&page_size=${page_size}${
      search ? "&" + search : ""
    }${(field && "&order_by=" + modifyInvoiceFieldSearch(field)) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getTenantInvoices = (
  tenant_id,
  page = 0,
  page_size = 10,
  search = "",
  field = "",
  direction = ""
) => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/invoices?&page=${page}&page_size=${page_size}${
      search ? "&" + search : ""
    }${(field && "&order_by=" + modifyInvoiceFieldSearch(field)) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getInvoiceById = (invoice_id) =>
  fetch(`${config.get("ApiUrl").Rest}/invoices/${invoice_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const exportInvoice = (filename, props = []) =>
  fetch(`${config.get("ApiUrl").Rest}/invoices/${props[0]}/download `, {
    mode: "cors",
    credentials: "include",
  });

export const getInvoicesAmount = (tenant_id, search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/invoices?count_only=y${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getInvoicesAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search,
  tenant_id
) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/invoices?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
