import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomerDropdown from "./CustomerDropdown";
import { formatPrice } from "../../helpers"

const loadingItemsState = "12345";

export const CustomerList = ({ title, data = [] }) => {
  return (
    <>
      <div className="bg-white py-5 px-6">
        <div className="mb-10" style={{ fontSize: "14px" }}>
          {title}
        </div>
        {data.length
          ? data.map(({ organisation = {}, total }) => (
              <div className="d-flex align-items-center mb-4" key={organisation.id}>
                <div className="ml-5">
                  <div>
                    <strong>{organisation.legal_name}</strong> ({organisation.trading_name})
                  </div>
                  <div style={{ fontSize: "12px" }}>{formatPrice(total)}</div>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <CustomerDropdown id={organisation.id} />
                </div>
              </div>
            ))
          : loadingItemsState.split("").map((item) => (
              <div className="d-flex align-items-center mb-4" key={item}>
                <div className="ml-5 w-100">
                  <div>
                    <Skeleton variant="text" width="80%" />
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    <Skeleton variant="text" width="30%" />
                  </div>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};
