import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { analytesHeadings } from "../constants";
import { renderAnalytesRow } from "../../Analytes/helpers";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ListHeader } from "../../../../../ui/structures/ListHeader";
import { Modal } from "../../../../../ui/components/Modal";
import { AnalytesList } from "./AnalytesList";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { getAnalytes } from "../../Analytes/_api";
import { getTestAnalytes, unlinkAnalytes } from "../../Tests/_api";
import { modifyAnalytes } from "../helpers";
import { Loader } from "../../../../../ui/components/Loader";
import { info } from "../../../../../helpers/toasts";

export const TestAnalytes = ({ data = [], name, setCardData, testId }) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [analytesList, setAnalytesList] = useState([]);
  const [linkedAnalytes, setLinkedAnalytes] = useState(data);
  const [nonLinkedAnalytes, setNonLinkedAnalytes] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setSelected({});
  };

  const handleFocus = () => setFocused(true);

  useEffect(() => {
    setLoading(true);
    const arrayOfIds = data.map((item) => item.id);
    request(getAnalytes)
      .then((analytes) => {
        if (!analytes) return;
        setNonLinkedAnalytes(
          modifyAnalytes(analytes.filter((item) => !arrayOfIds.includes(item.id)))
        );
        setAnalytesList(modifyAnalytes(analytes));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const arrayOfIds = linkedAnalytes.map((item) => item.id);
    setNonLinkedAnalytes(analytesList.filter((item) => !arrayOfIds.includes(item.id)));
    // eslint-disable-next-line
  }, [linkedAnalytes]);

  const handleSearch = (search, isRemoveKey) => {
    setCardData((state) => ({
      ...state,
      analytes: (isRemoveKey ? linkedAnalytes : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          analytesHeadings.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      }),
    }));
  };

  const KEY_MAP = {
    "Analyte ID": data.map((item) => item.code),
    "Analyte Name": data.map((item) => item.name),
    Status: data.map((item) => item.status),
  };

  const resetSearch = () => {
    setCardData((state) => ({
      ...state,
      analytes: linkedAnalytes,
    }));
  };

  const unlinkDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const updateAnalytesList = (message = "") => {
    setLoading(true);
    return request(getTestAnalytes, testId)
      .then((data) => {
        if (!data) return;
        setCardData((state) => ({
          ...state,
          analytes: data,
        }));
        setLinkedAnalytes(data);
      })
      .finally(() => {
        setLoading(false);
        info(message)
      });
  };

  const handleRemoveAnalyte = () => {
    setLoading(true)
    const payload = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({
        id: item,
      }));
    request(unlinkAnalytes, payload, testId).then((ids) => {
      if (!ids) {
        setLoading(false);
        return;
      }
      updateAnalytesList("Analyte has been removed!");
      setSelected({});
    });
  };

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton label="Add" onClick={handleModalOpen} />
      </div>
      <div className="mr-5">
        <ListButton
          label="Remove Analyte"
          onClick={handleRemoveAnalyte}
          disabled={unlinkDisabled}
        />
      </div>
    </>
  );

  return (
    <>
      {loading && (
        <Loader title="Loading ..." isOpen={loading} disableBackdropClick disableEscapeKeyDown />
      )}
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleClose}
          modalContent={
            <AnalytesList
              handleClose={handleClose}
              data={nonLinkedAnalytes}
              testId={testId}
              setNonLinkedAnalytes={setNonLinkedAnalytes}
              setLoading={setLoading}
              updateAnalytesList={updateAnalytesList}
            />
          }
        />
      )}
      <div className="row justify-content-center bg-white py-15 px-10">
        <div className="col-12">
          <h3 className="mb-15">
            <strong>{name}</strong>
          </h3>
          <div className="mb-10">
            <SearchBar
              onSearch={handleSearch}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter Analytes..."
              elevation={2}
              focused={focused}
              setFocused={setFocused}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
            />
          </div>
          <div>
            <ListHeader title="Analytes" renderButtons={renderButtons} handleFocus={handleFocus} />
            <Datagrid
              data={data}
              headings={analytesHeadings}
              renderRow={renderAnalytesRow}
              selected={selected}
              setSelected={setSelected}
              editable
              selectable
              link="analytes"
            />
          </div>
        </div>
      </div>
    </>
  );
};
