import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getContact } from "./_redux/selectors";
import { ContactCard } from "../../submodules/Contacts/ContactCard";
import { useFetch } from "../../../hooks/fetch.hook";
import { getSimpleOrganisations } from "../Organisations/_api";
import { getContactById, getContactOrganisations } from "./_api";

export function Card({
  history,
  match: {
    params: { id },
  },
}) {
  const { request } = useFetch();

  const data = useSelector(getContact(id));

  const [organisations, setOrganisations] = useState([]);
  const [linkedOrganisations, setLinkedOrganisations] = useState([]);
  const [linkedOrgsLoading, setLinkedOrgsLoading] = useState(false);
  const [cardData, setCardData] = useState({});

  useEffect(() => {
    if (id) {
      setLinkedOrgsLoading(true);
      request(getContactOrganisations, id)
        .then((data) => data && setLinkedOrganisations((data || []).map(({ id }) => id)))
        .finally(() => setLinkedOrgsLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    request(getSimpleOrganisations).then((data) => data && setOrganisations(data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data && id) {
      request(getContactById, id).then((data) => data && setCardData(data));
    } else setCardData(data);
    // eslint-disable-next-line
  }, [id]);

  const handleCancel = () => {
    history.goBack();
  };

  const organiastionOptions = useMemo(
    () =>
      organisations.map((organiastion) => ({
        value: organiastion.id,
        label: organiastion.trading_name,
      })),
    [organisations]
  );

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  if (id && linkedOrgsLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <ContactCard
      handleCancel={handleCancel}
      organiastionOptions={organiastionOptions}
      data={cardData}
      id={id}
      linkedOrganisations={linkedOrganisations}
    />
  );
}
