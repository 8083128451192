import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ListButton } from "../../../ui/components/ListButton";
import { Modal } from "../../../ui/components/Modal";
import { Mapping } from "../../../ui/structures/Mapping";
import { buttonStyle, callBackUrlMap, itemStyle, XeroAccountsTitles } from "./constants";
import XeroIcon from "./static/xero.png";
import { SuccessModal } from "./SuccessModal";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getIntegrationConfig,
  getIntegrationToken,
  setIntegrationConfig,
} from "./_api";
import { getCurrentIntegration } from "./helpers";
import { Loader } from "../../../ui/components/Loader";

export const Xero = ({
  handleConnect,
  handleDisconnect,
  tokenExpired = false,
  connectedComponent,
  setConnectedComponent,
  integrations,
  fulfillUser,
  user = {},
  xeroLoading,
  loaderTitle = "Connecting...",
  setXeroLoading,
  currentIntegration = {},
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const [configurationsOpen, setConfigurationsOpen] = useState(false);
  const [connectedModalOpen, setConnectedModalOpen] = useState(false);
  const [integrationData, setIntegrationData] = useState({});

  const handleCloseConfiguration = () => setConfigurationsOpen(false);
  const handleOpenConfiguration = () => {
    setConnectedModalOpen(false);
    setConfigurationsOpen(true);
  };

  const handleCloseConnectedModal = () => setConnectedModalOpen(false);

  const integrationDisabled =
    connectedComponent && connectedComponent !== "xero";
  const connected = connectedComponent === "xero";

  const handleSubmitConfig = (values, { setSubmitting }) => {
    const payload = {
      user_config: values,
    };
    request(setIntegrationConfig, payload, currentIntegration.id)
      .then(
        (data) =>
          data &&
          setIntegrationData((state) => ({
            ...state,
            user_config: data.user_config,
          }))
      )
      .finally(() => {
        setSubmitting(false);
        handleCloseConfiguration();
      });
  };

  const getUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    return code;
  };

  useEffect(() => {
    const integration = localStorage.getItem("integration");
    if (integration !== "xero") return;
    const tenant_id = user.admins_tenant ? user.admins_tenant : null;
    const code = getUrlParams();
    const xeroConnected = getCurrentIntegration(
      integrations,
      tenant_id,
      "xero"
    );
    if (code && !xeroConnected) {
      const payload = {
        integration_type: "xero",
        code: code,
        callback_url: callBackUrlMap[window.location.hostname],
        tenant_id,
      };
      setXeroLoading(true);
      request(getIntegrationToken, payload)
        .then((data) => {
          if (!data) {
            setXeroLoading(false);
            return;
          }
          fulfillUser({ ...user, integrations: [...user.integrations, data] });
          setXeroLoading(false);
          history.push("/integrations");
          setConnectedComponent("xero");
          setConnectedModalOpen(true);
        })
    } else return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!connected) return;
    setXeroLoading(true);
    request(getIntegrationConfig, currentIntegration.id)
      .then((data) => data && setIntegrationData(data))
      .finally(() => setXeroLoading(false));
    // eslint-disable-next-line
  }, [connectedComponent]);

  const sortHelper = (a, b) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  };

  const accountsOptions = (integrationData.accounts || [])
    .map(({ Code, Name }) => ({
      value: Code,
      label: `${Code || ""} - ${Name}`,
    }))
    .sort(sortHelper);

  const taxOptions = Object.keys(integrationData.tax_types || {})
    .map((key) => ({
      value: key,
      label: integrationData.tax_types[key],
    }))
    .sort(sortHelper);

  return (
    <>
      {configurationsOpen && (
        <Modal
          isOpen={configurationsOpen}
          submitable
          maxWidth="sm"
          onClose={handleCloseConfiguration}
          modalContent={
            <div className="bg-white px-5 pb-5">
              <div className="text-center mb-15">
                <h2 className="mb-3">{`Configure Account Mappings for Xero`} </h2>
                <p>Choose which account each line item is mapped to. </p>
              </div>
              <Mapping
                handleClose={handleCloseConfiguration}
                handleSubmit={handleSubmitConfig}
                componentLabel="Xero"
                accountsOptions={accountsOptions}
                taxOptions={taxOptions}
                data={integrationData}
                accountsTitles={XeroAccountsTitles}
              />
            </div>
          }
        />
      )}
      {connectedModalOpen && (
        <Modal
          isOpen={connectedModalOpen}
          submitable
          maxWidth="sm"
          onClose={handleCloseConnectedModal}
          modalContent={
            <SuccessModal
              handleClose={handleCloseConnectedModal}
              componentLabel="Xero"
              handleConfiguration={handleOpenConfiguration}
            />
          }
        />
      )}
      {xeroLoading && <Loader title={loaderTitle} isOpen={xeroLoading} />}
      <div style={itemStyle} className={`${integrationDisabled ? "bg-secondary" : "bg-white"} p-7 mt-10 mr-5`}>
        <div className="text-center mb-15">
          <img src={XeroIcon} alt="logo" style={{ height: 78 }} />
        </div>
        <h4 className="mb-2">
          <strong>Xero</strong>
        </h4>
        <div className="mb-15">
          Add you Finance and Accounting Platform for even greater visibility of your operations.
        </div>
        <div>
          {tokenExpired ? (
            <button className={`${buttonStyle} text-primary`}>Reconnect Xero</button>
          ) : connected ? (
            <div className="d-flex justify-content-between align-items-center">
              <ListButton label="Configure Xero" size="small" onClick={handleOpenConfiguration} />
              <button className={`${buttonStyle} text-danger`} onClick={handleDisconnect}>
                Disconnect
              </button>
            </div>
          ) : (
            <button className={`${buttonStyle} text-primary`} disabled={integrationDisabled} onClick={handleConnect}>
              Connect
            </button>
          )}
        </div>
      </div>
    </>
  );
};
