import React from "react";
import { ReactComponent as DashboardIcon } from "../../../../../ui/static/Dashboard.svg";
import { ReactComponent as TestsIcon } from "../../../../../ui/static/Tests.svg";
import { ReactComponent as ResultsIcon } from "../../../../../ui/static/Results.svg";
import { ReactComponent as CropsIcon } from "../../../../../ui/static/Crops.svg";
import { ReactComponent as OrganisationsIcon } from "../../../../../ui/static/Organisations.svg";
import { ReactComponent as InvoicesIcon } from "../../../../../ui/static/Invoices.svg";
import { ReactComponent as SettingsIcon } from "../../../../../ui/static/Settings.svg";
import { ReactComponent as IntegrationsIcon } from "../../../../../ui/static/Integrations.svg";
import { ReactComponent as UsersIcon } from "../../../../../ui/static/Users.svg";

export const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu",
};

export const NATIONAL_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.link,
    title: "Tests",
    icon: <TestsIcon />,
    to: "/tests-list",
  },
  {
    type: ITEM_TYPES.link,
    title: "Sample Preparation",
    icon: <TestsIcon />,
    to: "/samples",
  },
  {
    type: ITEM_TYPES.menu,
    title: "Results",
    icon: <ResultsIcon />,
    to: "/results",
    items: [
      {
        type: "link",
        title: "Results List",
        to: "/results-list",
      },
      {
        type: "link",
        title: "Results Review",
        to: "/results-review",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Crops",
    icon: <CropsIcon />,
    to: "/crops",
    items: [
      {
        type: "link",
        title: "Crops Management",
        to: "/crops/management",
      },
      {
        type: "link",
        title: "Tags",
        to: "/crops/tags",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations",
    items: [
      {
        type: "link",
        title: "Organisations List",
        to: "/organisations/organisations-list",
      },
      {
        type: "link",
        title: "Contacts",
        to: "/organisations/contacts-list",
      },
      {
        type: "link",
        title: "Sites",
        to: "/sites",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Invoices",
    icon: <InvoicesIcon />,
    to: "/invoices",
    items: [
      {
        type: "link",
        title: "Test Invoices",
        to: "/invoices/test-invoices",
      },
      {
        type: "link",
        title: "Scheme Invoices",
        to: "/invoices/scheme-invoices",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Settings",
    icon: <SettingsIcon />,
    to: "/settings",
    items: [
      {
        type: "link",
        title: "Tests",
        to: "/settings/tests",
      },
      {
        type: "link",
        title: "Laboratories",
        to: "/settings/laboratories",
      },
      {
        type: "link",
        title: "Tags",
        to: "/settings/tags",
      },
      {
        type: "link",
        title: "Analytes",
        to: "/settings/analytes",
      },
      {
        type: "link",
        title: "Sales Prices",
        to: "/settings/prices",
      },
    ],
  },
  {
    type: ITEM_TYPES.link,
    title: "Integrations",
    icon: <IntegrationsIcon />,
    to: "/integrations",
  },
  {
    type: ITEM_TYPES.link,
    title: "Users",
    icon: <UsersIcon />,
    to: "/users-list",
  },
  {
    type: ITEM_TYPES.link,
    title: "Tenant Administration",
    icon: <SettingsIcon />,
    to: "/tenant-administration",
  },
];

const platformResults = {
  type: ITEM_TYPES.menu,
  title: "Results",
  icon: <ResultsIcon />,
  to: "/results",
  items: [
    {
      type: "link",
      title: "Results List",
      to: "/results-list",
    },
    {
      type: "link",
      title: "Results Review",
      to: "/results-review",
    },
    {
      type: "link",
      title: "Results Review (Platform)",
      to: "/results-platform",
    },
  ],
};

export const PLATFORM_MENU = [
  ...NATIONAL_MENU.slice(0, 3),
  platformResults,
  ...NATIONAL_MENU.slice(4),
];

export const STATE_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.link,
    title: "Tests",
    icon: <TestsIcon />,
    to: "/tests-list",
  },
  {
    type: ITEM_TYPES.link,
    title: "Sample Preparation",
    icon: <TestsIcon />,
    to: "/samples",
  },
  {
    type: ITEM_TYPES.link,
    title: "Results",
    icon: <ResultsIcon />,
    to: "/results-list",
  },
  {
    type: ITEM_TYPES.menu,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations",
    items: [
      {
        type: "link",
        title: "Organisations List",
        to: "/organisations/organisations-list",
      },
      {
        type: "link",
        title: "Contacts",
        to: "/organisations/contacts-list",
      },
      {
        type: "link",
        title: "Sites",
        to: "/sites",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Invoices",
    icon: <InvoicesIcon />,
    to: "/invoices",
    items: [
      {
        type: "link",
        title: "Test Invoices",
        to: "/invoices/test-invoices",
      },
      {
        type: "link",
        title: "Scheme Invoices",
        to: "/invoices/scheme-invoices",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Accounting",
    icon: <InvoicesIcon />,
    to: "/accounting",
    items: [
      {
        type: "link",
        title: "Laboratory Invoice Reconciliation",
        to: "/accounting/reconciliation",
      },
    ],
  },
  {
    type: ITEM_TYPES.link,
    title: "Integrations",
    icon: <IntegrationsIcon />,
    to: "/integrations",
  },
];

export const END_USER_MENU = [
  {
    type: ITEM_TYPES.link,
    title: "Tests",
    icon: <TestsIcon />,
    to: "/tests-list",
  },
  {
    type: ITEM_TYPES.link,
    title: "Results",
    icon: <ResultsIcon />,
    to: "/results-list",
  },
];

export const TENANT_USER_MENU = [
  {
    type: ITEM_TYPES.link,
    title: "Tests",
    icon: <TestsIcon />,
    to: "/tests-list",
  },
  {
    type: ITEM_TYPES.link,
    title: "Sample Preparation",
    icon: <TestsIcon />,
    to: "/samples",
  },
  {
    type: ITEM_TYPES.link,
    title: "Results",
    icon: <ResultsIcon />,
    to: "/results-list",
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations List",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list",
  },
];

export const GUEST_MENU = [
  {
    type: ITEM_TYPES.link,
    title: "Guest",
    icon: <TestsIcon />,
    to: "/guest",
  },
];
