import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { getTenant } from "./_redux/selectors";
import { TenantDetails } from "./TenantDetails";
import { TenantBranding } from "./TenantBranding";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTenantById, getTenantGroups, modifyTenant } from "./_api";
import { TenantGroups } from "./TenantPermissions/TenantGroups";
import { modifyTenantGroups } from "./helpers";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const data = useSelector(getTenant(id));

  const [cardData, setCardData] = useState({});
  const [tenantGroups, setTenantGroups] = useState([]);

  useEffect(() => {
    if (!data) {
      Promise.all([request(getTenantById, id), request(getTenantGroups, id)]).then(
        ([tenant, groups]) => {
          tenant && setCardData(tenant);
          groups && setTenantGroups(modifyTenantGroups(groups));
        }
      );
    } else {
      request(getTenantGroups, id).then(
        (groups) => groups && setTenantGroups(modifyTenantGroups(groups))
      );
      setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    request(modifyTenant, values, id)
      .then((data) => data && history.push("/tenant-administration"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  const tabs = [
    {
      label: "Profile",
      tabPanel: (
        <TenantDetails
          data={cardData}
          id={id}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      ),
    },
    {
      label: "Branding",
      tabPanel: (
        <TenantBranding
          data={cardData}
          setCardData={setCardData}
          id={id}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      ),
    },
    {
      label: "Permissions",
      tabPanel: (
        <TenantGroups
          data={tenantGroups}
          id={id}
          name={cardData.name}
          setTenantGroups={setTenantGroups}
        />
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActive="0" tabs={tabs} align="right" />
    </div>
  );
};
