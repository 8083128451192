import config from "react-global-configuration";
import { modifyTestFieldSearch } from "../helpers";

export const getSalesLogs = (
  page = 0,
  page_size = 10,
  search = "",
  field = "",
  direction = "",
  tenant_id
) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/sales-log?test_request.status=completed&is_invoiced=false&page=${page}&page_size=${page_size}${(field &&
      "&order_by=" + modifyTestFieldSearch(field)) ||
      ""}${(direction && "&order_dir=" + direction) || ""}${
      search ? "&" + search : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getSalesLogsAmount = (search = "", tenant_id) => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/sales-log?test_request.status=completed&is_invoiced=false&count_only=y${
      search ? "&" + search : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const markAsInvoiced = (data, item_id, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/sales-log/${item_id}/mark-invoiced`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const markAsInvoicedbyIds = (tenant_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/sales-log/mark-invoiced`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const getSalesLogsAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search,
  tenant_id
) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/sales-log?test_request.status=completed&is_invoiced=false&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
