import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../../hooks/fetch.hook";
import { ListButton } from "../../ui/components/ListButton";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { getCurrentIntegration } from "../modules/Integrations/helpers";
import { getIntegrations } from "../modules/Integrations/_api";

export const StripeRefreshPage = () => {
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user);

  const tenantID = user.admins_tenant ? user.admins_tenant : null;

  const [stripeIntegration, setStripeIntegration] = useState({});

  useEffect(() => {
    request(getIntegrations).then((data) => {
      if (!data) return;
      const stripeIntegration = getCurrentIntegration(data, tenantID, "stripe");
      stripeIntegration && setStripeIntegration(stripeIntegration);
    });
    // eslint-disable-next-line
  }, []);

  const handleConnect = () => {
    if (!(stripeIntegration.content || {}).account_link_url) return;
    window.location.href = (stripeIntegration.content || {}).account_link_url;
  };
  return (
    <div className="bg-white rounded px-10 pb-20">
      <div className="containter h-100 mt-10">
        <div className="p-5 bg-white">
          <span>
            <img className="img-fluid" src={toAbsoluteUrl("/assets/icons/Logo.svg")} alt="Logo" />
          </span>
        </div>
      </div>
      <hr />
      <h4 className="text-center my-10">Something went wrong, please try again.</h4>
      <div className="text-center">
        <ListButton label="Connect" onClick={handleConnect} size="large" />
      </div>
    </div>
  );
};
