import React from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { HEADINGS } from "./constants";
import { renderPoliciesRow } from "./helpers";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { ListButton } from "../../../ui/components/ListButton";
import { useFetch } from "../../../hooks/fetch.hook";
import { removeGroupPolicies } from "../../../helpers/permissionsAPI";

export const PoliciesList = ({ data, name, groupId, setCardData, handleNew }) => {
  const { request } = useFetch();
  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton label="New" onClick={handleNew} data-testid="new" />
      </div>
    </>
  );

  const handleReject = (policyId) => {
    const payalod = [{ id: policyId }];
    request(removeGroupPolicies, payalod, groupId).then((data) => {
      if (!data) return;
      setCardData((state) => ({
        ...state,
        policies: state.policies.filter(({ id }) => id !== policyId),
      }));
    });
  };

  return (
    <>
      <div className="row justify-content-center bg-white py-15 px-10">
        <div className="col-12">
          <ListHeader title={`${name ? name : ""} Group Policies`} renderButtons={renderButtons} />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderPoliciesRow}
            deletable
            handleDelete={handleReject}
          />
        </div>
      </div>
    </>
  );
};
