const type = "analytes";

export const setAnalytesList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      analytesList: payload,
    };
  },
  type,
});

export const removeAnalyte = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      analytesList: state.analytesList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchiveAnalyte = (payload) => ({
  execute: (state) => {
    const index = state.analytesList.findIndex((item) => item.id === payload.id);
    const myState = [...state.analytesList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      analytesList: [...myState],
    };
  },
  type,
});
