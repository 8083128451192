/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  handleSearchTR,
  modifyTestObject,
  modifyTestsArray,
  renderTestsRow,
} from "./helpers";
import {
  formatDateForBackend,
  getSelectedId,
  objectCheck,
} from "../../../ui/helpers";
import {
  filterDataHelper,
  HEADINGS,
  searchMap,
  SEARCH_HELPER,
} from "./constants";
import { setTestsList, updateTestsList } from "./_redux/actions";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { Modal } from "../../../ui/components/Modal";
import { get, uniq } from "lodash";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getTenantTestRequests,
  getTestRequests,
  getTestRequestsAutocomplete,
  getTestsAmount,
  modifyTestRequest,
} from "./_api";

import { EditStatus } from "./EditStatus";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { TableSortLabel, Checkbox, FormControlLabel } from "@material-ui/core";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { getSimpleOrganisations } from "../Organisations/_api";
import { getCrops, getCropsAutocomplete } from "../Crops/CropsManagement/_api";
import { getTests, getTestsAutocomplete } from "../Settings/Tests/_api";
import { TRDetails } from "../../../ui/structures/TRDetails";
import { info } from "../../../helpers/toasts";
import { AccessControl } from "../../../ui/structures/AccessControl";

export const List = ({ history }) => {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;
  const tenants = user.tenants || [];

  const status = get(history, "location.status", "");

  const setInitialSearch = (status) => {
    if (status === "overdue") return "";
    else if (status) {
      return `status=_like(${status})_`;
    } else return "";
  };

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [overdue, setOverdue] = useState(
    get(history, "location.status", "") === "overdue" && true
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");
  const [simpleOrganisations, setSimpleOrganisations] = useState([]);
  const [testCodes, setTestCodes] = useState([]);
  const [productsList, setProductsList] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState(setInitialSearch(status));
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [reviewedComponentID, setReviewedComponentID] = useState("");

  const handleDetailsModalClose = () => setDetailsModalOpen(false);

  const data = useSelector(({ tests: { testsList } }) => testsList) || [];

  const requestTests = () => {
    if (admins_tenant) {
      return request(
        getTenantTestRequests,
        admins_tenant,
        "",
        page,
        pageSize,
        search,
        field,
        field && direction,
        overdue
      );
    } else
      return request(
        getTestRequests,
        "",
        page,
        pageSize,
        search,
        field,
        field && direction,
        overdue
      );
  };

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteField === "product") {
      return request(getCropsAutocomplete, 80, "name", autocompleteValue);
    } else if (autocompleteField === "test_code") {
      return request(getTestsAutocomplete, 80, "code", autocompleteValue);
    } else
      return request(
        getTestRequestsAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        searchFields,
        overdue,
        admins_tenant
      );
  };

  function fetchTestsAutocomplete(
    autocompleteValue,
    autocompleteField,
    searchFields
  ) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then(
      (data) => {
        if (data && Array.isArray(data) && data.length && objectCheck(data)) {
          setAutocompleteData(uniq(data));
        } else {
          setAutocompleteData(["No option found"]);
        }
        setAutocompleteLoading(false);
      }
    );
  }

  const fetchTestRequests = () => {
    setLoading(true);
    return requestTests()
      .then((data) => {
        if (!data) return;
        setSelected({});
        dispatch(setTestsList(modifyTestsArray(data, tenants)));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTestRequests();
  }, [search, field, direction, page, pageSize, overdue]);

  useEffect(() => {
    request(getTestsAmount, admins_tenant, search, overdue).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search]);

  useEffect(() => {
    Promise.all([
      request(getCrops, "", true),
      request(getTests, "", true),
    ]).then(([crops, tests]) => {
      crops && setProductsList(crops);
      tests && setTestCodes(tests);
    });
  }, []);

  useEffect(() => {
    request(getSimpleOrganisations).then(
      (data) => data && setSimpleOrganisations(data)
    );
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const renderButtons = () => (
    <>
      <div className="d-flex mt-2">
        <FormControlLabel
          control={
            <Checkbox checked={overdue} onChange={() => setOverdue(!overdue)} />
          }
          label="Show Overdue Results"
        />
      </div>
      <AccessControl
        desiredRoll={AVAILABLE_ROLES.END_USER}
        elseContent={
          <>
            <div className="mr-5">
              <ListButton
                label="Change Status"
                variant="outlined"
                disabled={changeStatusDisabled}
                onClick={handleStatusChange}
                text="#407A28"
                boxShadow={false}
                data-testid="status-change"
              />
            </div>
            <div className="mr-5">
              <Link
                to={{
                  pathname: `/add-new-test`,
                }}
                data-testid="new"
              >
                <ListButton label="New" />
              </Link>
            </div>
          </>
        }
      >
        <>
          <div className="mr-5">
            <Link
              to={{
                pathname: `/add-new-test`,
              }}
              data-testid="new"
            >
              <ListButton label="New" />
            </Link>
          </div>
        </>
      </AccessControl>
    </>
  );

  const resetSearch = () => {
    setPage(0);
    setSearch("");
  };

  const changeStatusDisabled = useMemo(() => {
    const selected_ids = Object.keys(selected).filter(
      (key) => selected[key] === true
    );
    return (
      selected_ids.length !== 1 ||
      !["Received", "Submitted"].includes(
        data.filter(({ id }) => selected_ids.includes(id))[0]?.status
      )
    );
  }, [selected]);

  const handleStatusChange = () => {
    setModalOpen(true);
  };

  const statusMap = {
    completed: "result_date",
    received: "receive_date",
    pending_results: "dispatch_date",
    submitted: "submit_date",
  };

  const handleSubmitStatus = (values, { setSubmitting }) => {
    const testId = getSelectedId(selected);
    const payload = { status: values.status };
    payload[statusMap[values.status]] = formatDateForBackend(values.date);

    request(modifyTestRequest, payload, testId)
      .then((data) => {
        if (!data) return;
        handleModalClose();
        dispatch(updateTestsList(modifyTestObject(data, tenants)));
        info("Status has been changed!");
      })
      .finally(() => setSubmitting(false));
  };

  const getSelectedStatus = (
    data.find(({ id }) => id === getSelectedId(selected)) || {}
  ).status;

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="text-nowrap"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const handleExpand = (id) => () => {
    setDetailsModalOpen(true);
    setReviewedComponentID(id);
  };

  return (
    <>
      {detailsModalOpen && (
        <Modal
          maxWidth="lg"
          isOpen={detailsModalOpen}
          onClose={handleDetailsModalClose}
          modalContent={
            <TRDetails
              data={data.find(({ id }) => id === reviewedComponentID)}
              tenants={tenants}
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        {modalOpen && (
          <Modal
            maxWidth="md"
            isOpen={modalOpen}
            submitable
            onClose={handleModalClose}
            modalContent={
              <EditStatus
                onClose={handleModalClose}
                handleSubmit={handleSubmitStatus}
                status={getSelectedStatus}
              />
            }
          />
        )}
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearchTR(
                  data,
                  filterDataHelper,
                  setSearch,
                  setPage,
                  simpleOrganisations,
                  productsList,
                  testCodes
                )
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchTestsAutocomplete}
              placeholder="Search Tests..."
              clearSearch={resetSearch}
              focused={focused}
              setFocused={setFocused}
              searchMap={searchMap}
              searchFields={search}
              defaultValue={
                status && status !== "overdue"
                  ? [{ key: "Status", value: status }]
                  : []
              }
              defaultKey={status && status !== "overdue" ? "Status" : null}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader
              title="Tests"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderTestsRow}
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              selectable
              link="tests"
              headerClassName="nospaces-header"
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              expandable
              handleExpand={handleExpand}
              expandHeader="Details"
              expandIcon={<MoreHorizIcon />}
            />
          </div>
        </div>
      </div>
    </>
  );
};
