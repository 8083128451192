import { capitalize, get } from "lodash";

const fieldNamesMap = {
  bill_to: "test_request.biller_org.trading_name",
  test_organisation: "ttest_request.ester_org.trading_name",
  site: "test_request.test_site.name",
  test_code: "test_request.test.code",
  test_category: "test_request.test.category",
  product: "test_request.crop.name",
};

export const modifyTestFieldSearch = (field) => {
  const modifiedFields = Object.keys(fieldNamesMap);
  if (modifiedFields.includes(field)) {
    return fieldNamesMap[field];
  } else return field;
};

export function modifyTestObject(test = {}, tenants = []) {
  return {
    ...test,
    bill_to: get(test, "test_request.biller_org.trading_name", ""),
    test_organisation: get(test, "test_request.tester_org.trading_name", ""),
    site: get(test, "test_request.test_site.name", ""),
    test_code: get(test, "test_request.test.code", ""),
    test_category: get(test, "test_request.test.category", ""),
    state: (tenants.find((tenant) => tenant.id === get(test, "test_request.tenant_id", "")) || {})
      .physical_state,
    product: get(test, "test_request.crop.name", ""),
    sample_reference: get(test, "test_request.sample_reference", ""),
    submit_date: get(test, "test_request.submit_date", ""),
    receive_date: get(test, "test_request.receive_date", ""),
    result_date: get(test, "test_request.result_date", ""),
    code: get(test, "test_request.code", ""),
    status: get(test, "test_request.status", "")
      .split("_")
      .map(capitalize)
      .join(" "),
  };
}

export function modifyTestsArray(tests = [], tenants = []) {
  return tests.map((item) => modifyTestObject(item, tenants));
}
