const type = "resultsReview";

export const setResultsList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      resultsList: payload,
    };
  },
  type,
});

export const updateResultsList = (payload) => ({
  execute: (state) => {
    const index = state.resultsList.findIndex((item) => item.id === payload.id);
    const myState = [...state.resultsList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      resultsList: [...myState],
    };
  },
  type,
});

export const removeResult = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      resultsList: state.resultsList.filter(({ id }) => id !== payload),
    };
  },
  type,
});
