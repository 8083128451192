import React from "react";
import { Chip, makeStyles, Paper } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { ReactComponent as AddIcon } from "../../static/AddIcon.svg";
import ToolTip from "../../components/Tooltip";

const tagsStyleMap = {
  "Industry Representative": "#033300",
  Codex: "#3d2828",
  "Food Standards Code": "#471061",
  Organisation: "#368f8f",
  Contact: "#368f8f",
};

const useStyles = makeStyles({
  root: {
    background: "rgba(0, 0, 0, 0.04)",
    borderBottom: "1px solid #033300",
    marginBottom: "15px",
    minHeight: "150px",
    paddingLeft: "10px",
  },
  colorPrimary: {
    color: "#E8F5E1",
  },
  deleteIcon: {
    color: "#71BF44",
  },
});

export const TagsField = ({ label, tags = [], predefinedTags = [], handleAdd, setValue }) => {
  const classes = useStyles();

  const handleTagRemove = (tag) => {
    setValue(
      "tags",
      tags.filter((name) => name !== tag)
    );
  };

  return (
    <Paper className={classes.root} square elevation={0}>
      {label && (
        <div className="ml-4 pt-3" style={{ color: "#033300" }}>
          {label}
        </div>
      )}
      <div className="d-flex flex-wrap">
        {tags.map((tag) => {
          const tagObject = predefinedTags.filter(({ id }) => id === tag)[0] || {};
          return (
            <ToolTip
              description={tagObject.description}
              key={tag}
              placement="bottom"
              content={
                <div className="m-3">
                  <Chip
                    label={tagObject.name}
                    color="primary"
                    onDelete={() => handleTagRemove(tag)}
                    avatar={<DoneIcon style={{ color: "#71BF44", background: "transparent" }} />}
                    style={{ background: tagsStyleMap[tagObject.category] || "#368f8f" }}
                    classes={{
                      colorPrimary: classes.colorPrimary,
                      deleteIcon: classes.deleteIcon,
                    }}
                  />
                </div>
              }
            />
          );
        })}
        <div className="cursor-pointer">
          <AddIcon onClick={handleAdd} style={{ color: "#71BF44" }} />
        </div>
      </div>
    </Paper>
  );
};
