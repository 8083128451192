import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
// import { Button } from "@material-ui/core";
import Button from "@bit/the-glue.frontendcomponents.button";

export function InputFile({
  title = "Drop files here or click to upload.",
  description = "You can upload only 10 files or less at a time",
  maxFilesQuantity = 1,
  accept,
  onDrop,
  children,
  wrapperClassName,
  titleStyle,
  button,
  label,
  ...other
}) {
  const [message, setMessage] = useState("");

  const handleDrop = useCallback(
    (acceptedFiles) => {
      onDrop(acceptedFiles);

      if (acceptedFiles.length > maxFilesQuantity - 1) {
        setMessage(description);
      }
    },
    // eslint-disable-next-line
    [onDrop, maxFilesQuantity]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept,
    maxFiles: maxFilesQuantity,
  });

  return (
    <div className={`w-100 cursor-pointer ${wrapperClassName}`}>
      {button ? (
        <>
          <input {...getInputProps()} {...other} />
          <Button
            label={label}
            size={"small"}
            // text="blue"
            // background="#fff"
            background="#71BF44"
            text="#E8F5E1"
            boxShadow
            textTransform="uppercase"
            fontWeight="500"
            fontSize="12px"
            {...getRootProps({
              className: "px-9 mr-3",
            })}
          />
        </>
      ) : (
        <div
          {...getRootProps({
            className:
              "form-control d-flex flex-column align-items-center h-100 bg-white py-5 px-10",
          })}
        >
          <input {...getInputProps()} {...other} />
          <p className="h5" style={titleStyle}>
            {title}
          </p>
          <p className={`font-size-lg text-${message ? "warning" : "muted"}`}>
            {message || description}
          </p>
          <ul>{children}</ul>
        </div>
      )}
    </div>
  );
}

export function MappedInputFile(props) {
  const [field] = useField(props.name);

  return <InputFile {...field} {...props} />;
}
