import React from "react";
import { Formik } from "formik";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { useFetch } from "../../../hooks/fetch.hook";
import { modifyGroupPolicies } from "../../../helpers/permissionsAPI";
import { policyDetailsSchema } from "../../../helpers/schemas";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { actionOptions, effectOptions, initialValues } from "./constants";

export const PolicyDetails = ({ groupId, handleCancel }) => {
  const { request } = useFetch();
  const handleSubmit = (values, { setSubmitting }) => {
    request(modifyGroupPolicies, [values], groupId)
      .then((data) => data && window.location.reload())
      .finally(() => setSubmitting(false));
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={policyDetailsSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="bg-white py-15 px-10">
          <h3 className="mb-5">
            <strong>New Policy</strong>
          </h3>
          <div className="row justify-content-between">
            <div className="col-8">
              <MappedSelect
                label="Action"
                name="action"
                data-testid="action"
                options={actionOptions}
              />
              <MappedSelect
                label="Effect"
                name="effect"
                data-testid="effect"
                options={effectOptions}
              />
              <MappedInput
                label="Policy Pattern"
                name="policy_pattern"
                data-testid="policy_pattern"
              />
            </div>
          </div>

          <div className="mt-10">
            <ButtonGroup
              handleClose={handleCancel}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              textTransofrm="uppercase"
              size="large"
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
