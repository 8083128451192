export const HEADINGS = [
  ["code", "Site ID"],
  ["name", "Site Name"],
  ["organisation_name", "Organisation Trading Name"],
  ["status", "Status"],
];

export const initialValues = {
  code: "",
  name: "",
  botanical_name: "",
  tags: [],
};

export const searchMap = {
  "Site ID": "code",
  "Site Name": "name",
  Status: "status",
};

export const SEARCH_HELPER = [
  ["code", "Site ID"],
  ["name", "Site Name"],
  ["status", "Status"],
];
