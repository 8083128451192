import React, { useState } from "react";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { error } from "../../../../helpers/toasts";

export function NewPassword(props) {
  const { MyTheme, code } = props;
  const [isRequested, setIsRequested] = useState(false);

  const initialValues = {
    email: "",
    code: code || "",
    new_password: "",
  };

  const newPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    code: Yup.number().required(),
    new_password: Yup.string()
      .required("Passwrod is a required field")
      .matches(
        /^.*(?=.{14,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 14 characters, one uppercase, one number and one special case character"
      ),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    Auth.forgotPasswordSubmit(values.email, values.code, values.new_password)
      .then(() => {
        setIsRequested(true);
      })
      .catch((err) => {
        error(err.message);
        setIsRequested(false);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      <Formik
        initialValues={initialValues}
        validationSchema={newPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            {!isRequested && (
              <div
                className="d-flex justify-content-center bg-white p-7 mt-10 flex-column"
                style={{ width: "35%", position: "relative", left: "33%" }}
              >
                <div className="text-center mb-10 mb-lg-20">
                  <h3 className="font-size-h1">Create new password</h3>
                </div>
                <div className="mb-5">
                  <MappedInput
                    style={{ padding: ".5rem" }}
                    inputWrapperStyle={{ border: "none" }}
                    name="email"
                    placeholder="Email Address"
                  />
                </div>
                <div className="mb-5">
                  <MappedInput
                    style={{ padding: ".5rem" }}
                    inputWrapperStyle={{ border: "none" }}
                    name="code"
                    placeholder="Code"
                    type="password"
                  />
                </div>
                <div>
                  <MappedInput
                    style={{ padding: ".5rem" }}
                    inputWrapperStyle={{ border: "none" }}
                    name="new_password"
                    placeholder="New Password"
                    type="password"
                  />
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button onClick={handleSubmit} style={MyTheme.button} disabled={isSubmitting}>
                    Submit
                  </button>
                  <div className="w-100">
                    <Link to="/auth">
                      <button
                        style={{
                          ...MyTheme.button,
                          backgroundColor: "#E1F0FF",
                          color: "#71BF44",
                        }}
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
}
