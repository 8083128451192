import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { OrganisationListPage } from "./pages/OrganisationListPage";
import { OrganisationCardPage } from "./pages/OrganisationCardPage";
import { OrganisationDetails } from "./modules/Organisations/OrganisationDetails";
import { UserSettingsPage } from "./pages/UserSettingsPage";
import { TestsListPage } from "./pages/TestsListPage";
import { TenantsListPage } from "./pages/TenantsListPage";
import { TenantCardPage } from "./pages/TenantCardPage";
import { TenantDetailsPage } from "./pages/TenantDetailsPage";
import { UsersListPage } from "./pages/UsersListPage";
import { UserCardPage } from "./pages/UserCardPage";
import { UserDetails } from "./modules/Users/UserDetails";
import { LaboratoriesListPage } from "./pages/LaboratoriesListPage";
import { LaboratoryCardPage } from "./pages/LaboratoryCardPage";
import { TagsListPage } from "./pages/TagsListPage";
import { TagCardPage } from "./pages/TagCardPage";
import { AnalytesListPage } from "./pages/AnalytesListPage";
import { AnalytesCardPage } from "./pages/AnalyteCardPage";
import { SettingsTestsListPage } from "./pages/SettingsTestsListPage";
import { SettingsTestCardPage } from "./pages/SettingsTestCardPage";
import { TestDetails } from "./modules/Settings/Tests/TestDetails";
import { CropTagCardPage } from "./pages/CropTagCardPage";
import { CropTagsListPage } from "./pages/CropTagsListPage";
import { CropsListPage } from "./pages/CropsListPage";
import { CropCardPage } from "./pages/CropCardPage";
import { ResultsListPage } from "./pages/ResultsListPage";
import { PlatformResultsReview } from "./pages/PlatformResultsReview";
import { AddNewTestPage } from "./pages/AddNewTestPage";
import { SamplesListPage } from "./pages/SamplesListPage";
import { ContactsListPage } from "./pages/ContactsListPage";
import { ContactsCardPage } from "./pages/ContactsCardPage";
import { IntegrationsPage } from "./pages/IntegrationsPage";
import { InvoiceReconciliationPage } from "./pages/InvoiceReconciliationPage";
import { PricesListPage } from "./pages/PriceListPage";
import { PriceCardPage } from "./pages/PriceCardPage";
import { NewSitePage } from "./pages/NewSitePage";
import { AddLaboratoryPage } from "./pages/AddLaboratoryPage";
import { BackupPage } from "./pages/BackupPage";
import GuestPage from "./pages/GuestPage";
import { IsEndUser, IsGuest, IsTenantUser } from "../helpers/IsEndUser";
import { PoliciesListPage } from "./pages/PoliciesListPage";
import { InvoicesListPage } from "./pages/InvoicesListPage";
import { InvoiceCardPage } from "./pages/InvoiceCardPage";
import { StripeCancelPage } from "./pages/StripeCancelPage";
import { StripeSuccessPage } from "./pages/StripeSuccessPage";
import { StripeRefreshPage } from "./pages/StripeRefreshPage";
import { SitesListPage } from "./pages/SitesListPage";
import { LaboratoryDetails } from "./modules/Settings/Laboratories/LaboratoryDetails";
import { ResultsReview } from "./pages/ResultsReviewPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect
            exact
            from="/"
            to={`${
              IsEndUser() || IsTenantUser()
                ? "/tests-list"
                : IsGuest()
                ? "/guest"
                : "/dashboard"
            }`}
          />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />

        <ContentRoute path="/user/edit" component={UserSettingsPage} />

        <ContentRoute
          path="/organisations/organisations-list"
          component={OrganisationListPage}
        />
        <Route
          path="/organisations-list/:id"
          component={OrganisationCardPage}
        />
        <ContentRoute
          path="/add-organisation"
          component={OrganisationDetails}
        />

        <ContentRoute path="/add-laboratory" component={LaboratoryDetails} />

        <ContentRoute
          path="/organisations/contacts-list"
          component={ContactsListPage}
        />
        <Route path="/contacts/:id" component={ContactsCardPage} />
        <ContentRoute path="/add-contact" component={ContactsCardPage} />

        <ContentRoute path="/tests-list" component={TestsListPage} />
        <ContentRoute path="/add-new-test" component={AddNewTestPage} />

        <ContentRoute
          path="/tenant-administration"
          component={TenantsListPage}
        />
        <Route path="/tenants/:id" component={TenantCardPage} />
        <ContentRoute path="/add-tenant" component={TenantDetailsPage} />

        <ContentRoute path="/users-list" component={UsersListPage} />
        <Route path="/users/:id" component={UserCardPage} />
        <ContentRoute path="/add-user" component={UserDetails} />

        <ContentRoute
          path="/settings/laboratories"
          component={LaboratoriesListPage}
        />
        <Route path="/laboratories/:id" component={LaboratoryCardPage} />
        <ContentRoute path="/add-laboratory" component={AddLaboratoryPage} />

        <ContentRoute path="/settings/tags" component={TagsListPage} />
        <Route path="/tags/:id" component={TagCardPage} />
        <ContentRoute path="/add-tag" component={TagCardPage} />

        <ContentRoute path="/settings/analytes" component={AnalytesListPage} />
        <Route path="/analytes/:id" component={AnalytesCardPage} />
        <ContentRoute path="/add-analyte" component={AnalytesCardPage} />

        <ContentRoute
          path="/settings/tests"
          component={SettingsTestsListPage}
        />
        <Route path="/tests/:id" component={SettingsTestCardPage} />
        <ContentRoute path="/add-test" component={TestDetails} />

        <ContentRoute path="/settings/prices" component={PricesListPage} />
        <Route path="/prices/:id" component={PriceCardPage} />
        <ContentRoute path="/add-price" component={PriceCardPage} />

        <ContentRoute path="/crops/tags" component={CropTagsListPage} />
        <Route path="/crop-tags/:id" component={CropTagCardPage} />
        <ContentRoute path="/add-crop-tag" component={CropTagCardPage} />

        <ContentRoute path="/crops/management" component={CropsListPage} />
        <Route path="/crops/:id" component={CropCardPage} />
        <ContentRoute path="/add-crop" component={CropCardPage} />

        <ContentRoute path="/results-list" component={ResultsListPage} />

        <ContentRoute
          path="/results-platform"
          component={PlatformResultsReview}
        />
        <ContentRoute path="/results-review" component={ResultsReview} />

        <ContentRoute path="/samples" component={SamplesListPage} />

        <ContentRoute path="/integrations" component={IntegrationsPage} />

        <ContentRoute
          path="/accounting/reconciliation"
          component={InvoiceReconciliationPage}
        />

        <ContentRoute path="/sites" component={SitesListPage} />
        <ContentRoute path="/add-site" component={NewSitePage} />

        <ContentRoute path="/error-page" component={BackupPage} />

        <ContentRoute path="/guest" component={GuestPage} />

        <Route path="/groups/:id" component={PoliciesListPage} />

        <ContentRoute
          path="/invoices/test-invoices"
          component={InvoicesListPage}
        />
        <Route path="/test-invoices/:id" component={InvoiceCardPage} />

        <ContentRoute path="/payment_success" component={StripeSuccessPage} />
        <ContentRoute path="/stripe_refresh" component={StripeRefreshPage} />
        <ContentRoute path="/stripe_cancel" component={StripeCancelPage} />

        <Redirect to="/error-page" />
      </Switch>
    </Suspense>
  );
}
