export const HEADINGS = [
  ["id", "User ID"],
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["phone_number", "Mobile"],
  ["email", "Email Address"],
  ["status", "Status"],
];

export const SITE_SEARCH_HELPER = [
  ["name", "Site Name"],
  ["phone", "Phone Number"],
  ["site_email", "Email Address"],
  ["status", "Status"],
];

export const siteSearchMap = {
  "Site Name": "name",
  "Email Address": "site_email",
  "Phone Number": "phone",
  "Status": "status",
};

export const sitesHeadings = [
  ["code", "Site ID"],
  ["name", "Site Name"],
  ["phone", "Phone Number"],
  ["site_email", "Email Address"],
  ["status", "Status"],
];

export const DetailsInitialValues = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
};

export const searchMap = {
  "Name": "first_name",
};
