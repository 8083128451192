import React, { useState } from "react";
import { Formik } from "formik";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { assignGroupToUser, getUserGroups, removeGroupFromUser } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { info } from "../../../helpers/toasts";
import { MappedRadio } from "../../../ui/components/Radio";
import { Loader } from "../../../ui/components/Loader";

export const UserPermissions = ({
  name,
  tenants = [],
  userGroups = [],
  groupsList = [],
  id,
  setUserGroups,
}) => {
  const adminGroup = groupsList.find(({ type }) => type === "global_admin");
  const bulkEntryGroup = groupsList.find(({ name }) => name === "Bulk Entry");
  const endUserGroup = groupsList.find(({ name }) => name === "End User");
  const guestGrop = groupsList.find(({ name }) => name === "Guest");

  const userGroupsIds = userGroups.map(({ id }) => id);

  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const permissionsList = [
    adminGroup,
    bulkEntryGroup,
    endUserGroup,
    guestGrop,
    ...tenants
      .map(({ groups }) => groups)
      .reduce((acc, value) => [...acc, ...value], [])
      .filter(({ type }) => type === "tenant_admin" || type === "tenant_user"),
  ];

  const globalPermissions = [
    adminGroup,
    endUserGroup,
    guestGrop,
    { name: "None", id: "" },
  ];

  const tenantPermissions = [
    ...tenants
      .map(({ groups }) => groups)
      .reduce((acc, value) => [...acc, ...value], [])
      .filter(({ type }) => type === "tenant_admin" || type === "tenant_user"),
    { name: "None", id: "" },
  ];

  const bulkPermissions = [
    { ...bulkEntryGroup, name: "Allowed" },
    { name: "Not Allowed", id: "" },
  ];

  const initialValues = {
    permission:
      (
        globalPermissions.find((permission) =>
          userGroupsIds.includes((permission || {}).id)
        ) ||
        tenantPermissions.find((permission) =>
          userGroupsIds.includes((permission || {}).id)
        ) ||
        {}
      ).id || "",
    // tenant_permission:
    //   (
    //     tenantPermissions.find((permission) =>
    //       userGroupsIds.includes((permission || {}).id)
    //     ) || {}
    //   ).id || "",
    bulk_permission:
      (
        userGroups.find(
          (group) => (group || {}).id === (bulkEntryGroup || {}).id
        ) || {}
      ).id || "",
  };

  const handleReset = (resetForm) => () => {
    resetForm({ values: initialValues });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (values.global_permission && values.tenant_permission) {
      info("User can't have Global and State permissions at the same time");
      setSubmitting(false);
    } else {
      setLoading(true);
      const groupsIDs = Object.values(values).filter(Boolean);
      const groupsToAdd = groupsIDs.map((id) => ({ id }));
      const groupsToRemove = permissionsList
        .filter((group) => !groupsIDs.includes((group || {}).id))
        .map((group) => ({ id: (group || {}).id }));

      let promisePayload = [
        request(assignGroupToUser, groupsToAdd, id),
        request(removeGroupFromUser, groupsToRemove, id),
      ];

      Promise.all(promisePayload)
        .then(() => {
          request(getUserGroups, id)
            .then((data) => {
              if (!data) return;
              info("User permissions have been updated!");
              setUserGroups(data);
            })
            .finally(() => setLoading(false));
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, values, resetForm }) => (
        <>
          {loading && <Loader title="Loading..." isOpen={loading} />}
          <div className="border border-secondary bg-white py-15 px-10">
            <div>
              <h3 className="mb-15">
                <strong>{name}</strong>
              </h3>
              <Tabs
                defaultActive="0"
                tabs={[
                  {
                    label: "Global",
                    tabPanel: (
                      <div className="border 1px solid #E5E5E5 pl-5 pt-10">
                        <MappedRadio
                          options={globalPermissions}
                          name="permission"
                          labelName="name"
                        />
                      </div>
                    ),
                  },
                  {
                    label: "Tenants",
                    tabPanel: (
                      <div className="border 1px solid #E5E5E5 pl-5 pt-10">
                        <MappedRadio
                          options={tenantPermissions}
                          name="permission"
                          labelName="name"
                        />
                      </div>
                    ),
                  },
                  {
                    label: "Bulk Entry",
                    tabPanel: (
                      <div className="border 1px solid #E5E5E5 pl-5 pt-10">
                        <MappedRadio
                          options={bulkPermissions}
                          name="bulk_permission"
                          labelName="name"
                        />
                      </div>
                    ),
                  },
                ]}
                background="#E8F5E1"
                text="#599D36"
                selectedColor="#407A28"
              />
            </div>
            <div className="mt-30 d-flex align-items-center justify-content-end">
              <div className="mr-3">
                <ListButton
                  label="Reset"
                  size="large"
                  onClick={handleReset(resetForm)}
                  variant="outlined"
                  text="#407A28"
                  boxShadow={false}
                />
              </div>
              <div className="mr-3">
                <ListButton
                  label="Submit"
                  size="large"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  boxShadow={false}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
