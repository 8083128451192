import config from "react-global-configuration";
import { modifySiteFieldSearch } from "../helpers";

export const getSites = (
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/sites?${search && "&" + search}${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + modifySiteFieldSearch(field)) ||
      ""}${(direction && "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getSitesAmount = (search = "", archived = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/sites?count_only=y&${
      search ? "&" + search : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getSitesListAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/sites?&page_size=${page_size}${(archived &&
      "&status=all") ||
      ""}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
