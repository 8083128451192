import config from "react-global-configuration";

export const addTestRequest = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getSubmissionReceipt = (fileName = "", invoice_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/test_requests/submission-receipt?invoice_id=${invoice_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
