const type = "invoice-reconciliation";

export const setData = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      testsList: payload,
    };
  },
  type,
});

export const removeTest = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      testsList: state.testsList.filter(({ id }) => id !== payload),
    };
  },
  type,
});