const type = "settings/tests";

export const setTestsList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      settingsTestsList: payload,
    };
  },
  type,
});

export const removeTest = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      settingsTestsList: state.settingsTestsList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchiveTest = (payload) => ({
  execute: (state) => {
    const index = state.settingsTestsList.findIndex((item) => item.id === payload.id);
    const myState = [...state.settingsTestsList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      settingsTestsList: [...myState],
    };
  },
  type,
});
