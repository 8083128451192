import React, { useState } from "react";
import { Formik } from "formik";
import { Filter } from "../../../ui/components/Filter";
import { Modal } from "../../../ui/components/Modal";
import { ListButton } from "../../../ui/components/ListButton";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  blockMinHeight,
  buttonClassName,
  nexStepButtonStyle,
  productFiltersMap,
} from "./constants";
import { filterResults, getKeys } from "./helpers";
import { MappedRadio } from "../../../ui/components/Radio";
import { productLookupSchema } from "../../../helpers/schemas";
import config from "react-global-configuration";

export const ProductLookup = ({
  data = [],
  handleNextStep,
  handlePrevStep,
  product,
}) => {
  const { crop_id, name } = product;

  const [imageLoading, setImageLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const inititalValues = {
    crop_id,
    name,
  };

  const getSelectedResult = () => {
    const result = data.find(({ id }) => id === crop_id);
    return result && [result];
  };

  const getInitialFilterValue = () => {
    const selectedOption = data.find(({ id }) => crop_id === id);
    if (selectedOption) {
      return [{ name: selectedOption.name }];
    }
  };

  const [searchResults, setSearchResults] = useState(getSelectedResult() || []);
  const [filterValue, setFilterValue] = useState(getInitialFilterValue() || []);

  const handleFilter = (filterValue) => {
    const keys = getKeys(filterValue);

    setSearchResults(
      !keys.length ? [] : filterResults(data, keys, filterValue)
    );
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "product");
  };

  return (
    <>
      <Formik
        initialValues={inititalValues}
        onSubmit={handleSubmit}
        validationSchema={productLookupSchema}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal
              isOpen={modalOpen}
              submitable
              onClose={handleModalClose}
              maxWidth="sm"
              modalContent={
                <div className="pb-5">
                  <div className="text-center">
                    <h3>
                      You have selected a product that is often confused with
                      other similar crops. Please check your sample matches the
                      photo below before proceeding.
                    </h3>
                    <div>
                      {searchResults[0]?.image_url ? (
                        <>
                          <picture>
                            <img
                              className="rounded shadow-sm"
                              src={`${config.get("ApiUrl").Rest}/${
                                searchResults[0]?.image_url
                              }`}
                              alt={
                                searchResults[0]
                                  ? searchResults[0].name
                                  : "Crop"
                              }
                              style={{
                                display: "block",
                                width: 200,
                                maxWidth: 400,
                                height: "auto",
                                maxHeight: 200,
                                margin: "20px auto 0",
                              }}
                            />
                          </picture>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mt-10 d-flex justify-content-center mr-4">
                    <div className="mr-3">
                      <ListButton
                        label="Cancel"
                        size="small"
                        onClick={handleModalClose}
                        data-testid="cancel"
                        variant="outlined"
                        text="#407A28"
                        boxShadow={false}
                      />
                    </div>
                    <div>
                      <ListButton
                        label="Approve"
                        size="small"
                        onClick={() => handleSubmit(values)}
                        data-testid="submit"
                      />
                    </div>
                  </div>
                </div>
              }
            />
            <div className="row">
              <div className="col-6">
                <div className="bg-white p-10" style={blockMinHeight}>
                  <h4 className="mb-10">
                    <strong>Select Product</strong>
                  </h4>
                  {productFiltersMap.map(({ name, placeholder }) => (
                    <Filter
                      key={name}
                      name={name}
                      placeholder={placeholder}
                      options={data}
                      handleFilter={handleFilter}
                      filterValue={filterValue}
                      setFilterValue={setFilterValue}
                      setValue={setFieldValue}
                      defaultValue={values.crop_id}
                      radioButtonName="crop_id"
                    />
                  ))}
                </div>
              </div>
              <div className="col-6">
                <div className="bg-white p-10" style={blockMinHeight}>
                  <h4 className="mb-10">
                    <strong>Search Results</strong>
                  </h4>
                  <div style={{ minHeight: "50px" }}>
                    <MappedRadio options={searchResults} name="crop_id" />

                    {searchResults[0]?.image_url ? (
                      <>
                        {searchResults[0]?.is_challenging ? (
                          <div
                            className="rounded shadow-sm"
                            style={{
                              background: "#F2C94C",
                              padding: "20px 20px 10px",
                              maxWidth: 400,
                              margin: "0 auto",
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginBottom: 5,
                                fontWeight: "600",
                              }}
                            >
                              CAUTION
                            </p>
                            <p>
                              You have selected a product that is often confused
                              with other similar crops. Please check your sample
                              matches the photo below before proceeding.
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {imageLoading ? (
                          <Skeleton
                            variant="rect"
                            width={200}
                            height={200}
                            style={{
                              margin: "20px auto 0",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <picture>
                          <img
                            className="rounded shadow-sm"
                            src={`${config.get("ApiUrl").Rest}/${
                              searchResults[0]?.image_url
                            }`}
                            alt={
                              searchResults[0] ? searchResults[0].name : "Crop"
                            }
                            onLoad={() => setImageLoading(false)}
                            style={{
                              display: imageLoading ? "none" : "block",
                              width: 200,
                              maxWidth: 400,
                              height: "auto",
                              maxHeight: 200,
                              margin: "20px auto 0",
                            }}
                          />
                        </picture>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-15">
              <div className="col-6 text-center">
                <button className={buttonClassName} onClick={handlePrevStep}>
                  Back
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  className={nexStepButtonStyle}
                  onClick={
                    searchResults[0]?.is_challenging
                      ? handleModalOpen
                      : handleSubmit
                  }
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
