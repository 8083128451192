export const HEADINGS = [
  ["code", "Analyte ID"],
  ["name", "Analyte Name"],
  ["level_of_reporting", "Limit of Reporting"],
  ["type", "Type"],
  ["group", "Group"],
  ["status", "Status"],
];

export const searchMap = {
  "Analyte ID": "code",
  "Analyte Name": "name",
  Type: "type",
  Group: "group",
};

export const initialValues = {
  name: "",
  reporting_value: "",
  reporting_unit: "",
  type: "",
  group: "",
  common_product_name: "",
};

export const typesList = [
  {
    value: "Chemical",
    label: "Chemical",
  },
  {
    value: "Micro",
    label: "Micro",
  },
  {
    value: "Water",
    label: "Water",
  },
  {
    value: "Heavy Metal",
    label: "Heavy Metal",
  },
  {
    value: "Miscellaneous",
    label: "Miscellaneous",
  },
];

export const groupsList = [
  {
    value: "Organophosphates",
    label: "Organophosphates",
  },
  {
    value: "Herbicides",
    label: "Herbicides",
  },
  {
    value: "Carbamates",
    label: "Carbamates",
  },
  {
    value: "Others",
    label: "Others",
  },
  {
    value: "Insecticides",
    label: "Insecticides",
  },
  {
    value: "Miscellaneous",
    label: "Miscellaneous",
  },
  {
    value: "Organochlorines",
    label: "Organochlorines",
  },
  {
    value: "Chemistry",
    label: "Chemistry",
  },
  {
    value: "Heavy Metals",
    label: "Heavy Metals",
  },
  {
    value: "Microbiological",
    label: "Microbiological",
  },
  {
    value: "Synthetic Pyrethroids",
    label: "Synthetic Pyrethroids",
  },
  {
    value: "Fungicides",
    label: "Fungicides",
  },
  {
    value: "Acaricides",
    label: "Acaricides",
  },
  {
    value: "Dithiocarbamates",
    label: "Dithiocarbamates",
  },
  {
    value: "Phenols",
    label: "Phenols",
  },
];

export const unitsList = [
  {
    value: "mg/kg",
    label: "mg/kg",
  },
  {
    value: "mg/L",
    label: "mg/L",
  },
  {
    value: "/25g",
    label: "/25g",
  },
  {
    value: "/100ml",
    label: "/100ml",
  },
  {
    value: "/swab",
    label: "/swab",
  },
  {
    value: "MPN/g",
    label: "MPN/g",
  },
  {
    value: "MPN/swab",
    label: "MPN/swab",
  },
  {
    value: "μg/kg",
    label: "μg/kg",
  },
  {
    value: "CFU/g",
    label: "CFU/g",
  },

  {
    value: "CFU/swab",
    label: "CFU/swab",
  },
  {
    value: "CFU/100ml",
    label: "CFU/100ml",
  },
  {
    value: "%",
    label: "%",
  },
  {
    value: "/g",
    label: "/g",
  },
  {
    value: "CFU/plate",
    label: "CFU/plate",
  },
  {
    value: "g/100g",
    label: "g/100g",
  },
  {
    value: "%w/w",
    label: "%w/w",
  },
];
