import React from "react";
import { getStatusRowStyle, isStatus } from "../../../ui/helpers";

export function renderSitesRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5  ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {item[key]}
    </td>
  ));
}
