export const HEADINGS = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["position_title", "Position"],
  ["phone", "Phone"],
  ["mobile", "Mobile"],
  ["email", "Email"],
  ["status", "Status"],
];

export const searchMap = {
  "First Name": "first_name",
  "Last Name": "last_name",
  "Email": "email",
  Phone: "phone",
  Mobile: "mobile",
};
